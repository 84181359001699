import NewsService from "@/services/api/v1/NewsService"

const service = new NewsService()

export const state = {
  news: [],
  currentNews: {
    name: "",
    messages: [],
  },
  paginationOptions: {
    pageSize: 20,
    totalCount: 0,
    currentPage: 1,
  },
  listMeta: {},
}

export const getters = {
  news: state => state.news,
  currentNews: state => state.currentNews,
  paginationOptions: state => state.paginationOptions,
  listMeta: state => state.listMeta,
}

export const mutations = {
  SET_NEWS(state, payload) {
    state.news = payload
  },
  SET_CURRENT_NEWS(state, payload) {
    state.currentNews = payload
  },
  CLEAR_NEWS(state) {
    state.news = []
    state.paginationOptions.currentPage = 1
  },
  APPEND_NEWS(state, payload) {
    state.news.splice(0, 0, payload)
  },

  SET_CURRENT_PAGE(state, payload) {
    state.paginationOptions.currentPage = payload
  },
  SET_TOTAL_COUNT(state, payload) {
    state.paginationOptions.totalCount = payload
  },
  SET_META(state, payload) {
    state.listMeta = payload
    console.log(payload)
  },
}

export const actions = {
  async getNewsList({ commit, getters }, cabinet = 0) {
    const paginationOptions = getters.paginationOptions

    const response = await service.getNewsList(
      paginationOptions.currentPage,
      paginationOptions.pageSize,
      cabinet
    )

    commit("SET_NEWS", response.data.data)
    commit("SET_TOTAL_COUNT", response.data.meta.total)
    commit("SET_META", response.data.meta)
  },
  async getNews({ commit }, id) {
    const response = await service.getNews(id)
    commit("SET_CURRENT_NEWS", response.data.news)
  },
  async createNews({ commit }, chat) {
    const resp = await service.createNews(chat)
    commit("APPEND_NEWS", resp.data.news)
    return resp.data.news
  },
  async updateCurrentNews({ commit }, { id, news }) {
    const resp = await service.updateNews(id, news)

    commit("SET_CURRENT_NEWS", resp.data.news)
  },
}
