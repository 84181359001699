import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"

export default class CreateClientService {
  async createClient(data) {
    return axios.post(`${API_PREFIX}/client`, data)
  }
  async bindClient(createForm) {
    return axios.post(`${API_PREFIX}/bindclienttodealer`, createForm)
  }

  async setFinanceAccountAmount(payloads) {
    return axios.post(
      `${API_PREFIX}/client/${payloads.clientId}/account-amount`,
      { amount: payloads.amount }
    )
  }
}
