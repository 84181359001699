export default [
  {
    key: 1,
    code: "N201-P001",
    name: "Наличие",
    date: 1,
    generalAmountLines: 2785,
    busyAmountLines: 2785,
    uploadDate: "02.07.2020",
    uploadMethod: "Сервер",
    uploadPercent: 100,
    localArticlesAmount: 23,
    generalArticlesAmount: 2762,
    priceStatus: "Рабочий",
  },
  {
    key: 2,
    code: "N201-P002",
    name: "Распродажа",
    date: 1,
    generalAmountLines: 320,
    busyAmountLines: 320,
    uploadDate: "01.07.2020",
    uploadMethod: "Вручную",
    uploadPercent: 100,
    localArticlesAmount: 0,
    generalArticlesAmount: 320,
    priceStatus: "Рабочий",
  },
  {
    key: 3,
    code: "N201-P004",
    name: "Склад - Hyundai",
    date: 2,
    generalAmountLines: 4145,
    busyAmountLines: 4120,
    uploadDate: "10.06.2020",
    uploadMethod: "Эл. адрес",
    uploadPercent: 99.4,
    localArticlesAmount: 4120,
    generalArticlesAmount: 0,
    priceStatus: "Рабочий",
  },
  {
    key: 4,
    code: "N201-P018",
    name: "Джапаргс",
    date: 3,
    generalAmountLines: 12530,
    busyAmountLines: 12356,
    uploadDate: "28.06.2020",
    uploadMethod: "Эл. адрес",
    uploadPercent: 98.61,
    localArticlesAmount: 12302,
    generalArticlesAmount: 54,
    priceStatus: "Рабочий",
  },
  {
    key: 5,
    code: "N201-P012",
    name: "Склад - Skoda",
    date: 2,
    generalAmountLines: 457,
    busyAmountLines: 457,
    uploadDate: "02.07.2020",
    uploadMethod: "Сервер",
    uploadPercent: 100,
    localArticlesAmount: 457,
    generalArticlesAmount: 0,
    priceStatus: "Рабочий",
  },
  {
    key: 6,
    code: "N201-P023",
    name: "Склад - Mercedes (пр.Ленина)",
    date: 3,
    generalAmountLines: 3610,
    busyAmountLines: 3440,
    uploadDate: "01.07.2020",
    uploadMethod: "Эл. адрес",
    uploadPercent: 95.29,
    localArticlesAmount: 3440,
    generalArticlesAmount: 0,
    priceStatus: "Рабочий",
  },
  {
    key: 7,
    code: "N201-P012",
    name: "Склад - Mercedes (Энтузиастов)",
    date: 2,
    generalAmountLines: 3204,
    busyAmountLines: 1853,
    uploadDate: "27.06.2020",
    uploadMethod: "Эл. адрес",
    uploadPercent: 57.83,
    localArticlesAmount: 1853,
    generalArticlesAmount: 0,
    priceStatus: "Рабочий",
  },
]
