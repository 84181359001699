import { DummyApiService } from "@/services"
const service = new DummyApiService()

export const state = {
  list: [],
}

export const mutations = {
  SET_LIST: (state, payload) => (state.list = payload),
}

export const actions = {
  fetchBrands: async ({ commit }) => {
    const list = await service.getBrands()
    commit("SET_LIST", list)
  },
}

export const getters = {
  list: state => state.list,
}
