export default [
  {
    key: 1,
    dealerName: "АВТОГРАД ПРЕСТИЖ",
    statuslessSumAmount: "11170",
    statuslessSumPresence: "4580",
    statuslessSumSroch: "1560",
    statuslessSumSklad: "2900",
    statuslessSumOrder: "0",
    statuslessSumOtherDealers: "2130",
    statuslessSumOther: "0",
  },
  {
    key: 2,
    dealerName: "БОВИД АВТО",
    statuslessSumAmount: "0",
    statuslessSumPresence: "0",
    statuslessSumSroch: "0",
    statuslessSumSklad: "0",
    statuslessSumOrder: "0",
    statuslessSumOtherDealers: "0",
    statuslessSumOther: "0",
  },
  {
    key: 3,
    dealerName: "АВТОГРАД ПЛЮС",
    statuslessSumAmount: "46611",
    statuslessSumPresence: "11761",
    statuslessSumSroch: "19700",
    statuslessSumSklad: "0",
    statuslessSumOrder: "2300",
    statuslessSumOtherDealers: "12850",
    statuslessSumOther: "0",
  },
  {
    key: 4,
    dealerName: "ПЛАНЕТА АВТО",
    statuslessSumAmount: "12840",
    statuslessSumPresence: "0",
    statuslessSumSroch: "0",
    statuslessSumSklad: "0",
    statuslessSumOrder: "12840",
    statuslessSumOtherDealers: "0",
    statuslessSumOther: "0",
  },
  {
    key: 5,
    dealerName: "АРКОНТ",
    statuslessSumAmount: "19735",
    statuslessSumPresence: "16205",
    statuslessSumSroch: "0",
    statuslessSumSklad: "2750",
    statuslessSumOrder: "0",
    statuslessSumOtherDealers: "0",
    statuslessSumOther: "780",
  },
]
