export default [
  {
    id: 1,
    key: 1,
    name: "LAND ROVER",
  },
  {
    id: 2,
    key: 2,
    name: "JAGUAR",
  },
]
