export default [
  {
    id: 1,
    key: 1,
    brand: "LAND ROVER",
    fileName: "LR Russia Price File 10.2020.mdb",
    uploadedAt: "2020-09-30 12:20:03",
    isLatest: true,
  },
  {
    id: 2,
    key: 2,
    brand: "JAGUAR",
    fileName: "Jaguar Russia Price File 10.2020.mdb",
    uploadedAt: "2020-09-30 12:21:12",
    isLatest: true,
  },
  {
    id: 3,
    key: 3,
    brand: "LAND ROVER",
    fileName: "LR Russia Price File 09.2020.mdb",
    uploadedAt: "2020-08-31 17:30:35",
    isLatest: false,
  },
  {
    id: 4,
    key: 4,
    brand: "JAGUAR",
    fileName: "Jaguar Russia Price File 09.2020.mdb",
    uploadedAt: "2020-08-31 16:15:00",
    isLatest: false,
  },
  {
    id: 5,
    key: 5,
    brand: "LAND ROVER",
    fileName: "LR Russia Price File 09.2020.mdb",
    uploadedAt: "2020-08-30   10:41:42",
    isLatest: false,
  },
  {
    id: 6,
    key: 6,
    brand: "JAGUAR",
    fileName: "Jaguar Russia Price File 09.2020.mdb",
    uploadedAt: "2020-08-30 10:22:16",
    isLatest: false,
  },
  {
    id: 7,
    key: 7,
    brand: "JAGUAR",
    fileName: "Jaguar Russia Price File 08.2020.mdb",
    uploadedAt: "2020-07-31 15:47:10",
    isLatest: false,
  },
  {
    id: 8,
    key: 8,
    brand: "LAND ROVER",
    fileName: "Наличие ЦС LR.xlsxLR Russia Price File 08.2020.mdb",
    uploadedAt: "2020-07-31 15:45:21",
    isLatest: false,
  },
]
