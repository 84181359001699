export default [
  {
    key: 1,
    dealerName: "АВТОГРАД ПРЕСТИЖ",
    approvedSumAmount: "528320",
    approvedSumPresence: "265480",
    approvedSumSroch: "232580",
    approvedSumSklad: "19660",
    approvedSumOrder: "0",
    approvedSumOtherDealers: "10600",
    approvedSumOther: "0",
  },
  {
    key: 2,
    dealerName: "БОВИД АВТО",
    approvedSumAmount: "295499",
    approvedSumPresence: "101504",
    approvedSumSroch: "34900",
    approvedSumSklad: "72900",
    approvedSumOrder: "48755",
    approvedSumOtherDealers: "24200",
    approvedSumOther: "13240",
  },
  {
    key: 3,
    dealerName: "АВТОГРАД ПЛЮС",
    approvedSumAmount: "798692",
    approvedSumPresence: "372112",
    approvedSumSroch: "232890",
    approvedSumSklad: "114690",
    approvedSumOrder: "33500",
    approvedSumOtherDealers: "0",
    approvedSumOther: "45500",
  },
  {
    key: 4,
    dealerName: "ПЛАНЕТА АВТО",
    approvedSumAmount: "207764",
    approvedSumPresence: "107894",
    approvedSumSroch: "63400",
    approvedSumSklad: "36470",
    approvedSumOrder: "0",
    approvedSumOtherDealers: "0",
    approvedSumOther: "0",
  },
  {
    key: 5,
    dealerName: "АРКОНТ",
    approvedSumAmount: "216470",
    approvedSumPresence: "85791",
    approvedSumSroch: "87504",
    approvedSumSklad: "550",
    approvedSumOrder: "6105",
    approvedSumOtherDealers: "2100",
    approvedSumOther: "34420",
  },
]
