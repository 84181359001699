import PromotionService from "@/services/api/v1/PromotionService"
const service = new PromotionService()

export const state = {
  loading: false,
  currentPromotion: null,
  promotions: [],
  statuses: {
    1: {
      type: "danger",
      name: "Выключить",
    },
    0: {
      type: "primary",
      name: "Включить",
    },
  },
}

export const mutations = {
  SET_PROMOTIONS(state, payload) {
    state.promotions = payload
  },
  SET_CURRENT_PROMOTION(state, payload) {
    state.currentPromotion = payload
    if (state.promotions) {
      const a2 = [...state.promotions]
      let p = a2.findIndex(el => el.id == state.currentPromotion.id)
      if (p >= 0) {
        a2[p] = state.currentPromotion
        state.promotions = a2
      }
    }
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
}

export const actions = {
  async fetchPromotions({ commit }) {
    commit("SET_LOADING", true)
    try {
      const promotions = await service.getPromotions()
      commit("SET_PROMOTIONS", promotions)
      commit("SET_LOADING", false)
    } catch (error) {
      commit("SET_LOADING", false)
      throw error
    }
  },
  async fetchPromotion({ commit }, id) {
    const promotion = await service.fetchPromotion(id)
    commit("SET_CURRENT_PROMOTION", promotion)
  },
  async createPromotion({ commit }, data) {
    const promotion = await service.createPromotion(data)
    commit("SET_CURRENT_PROMOTION", promotion)
  },
  async updatePromotion({ commit }, { id, data }) {
    commit("SET_LOADING", true)
    try {
      const promotion = await service.updatePromotion(id, data)
      commit("SET_CURRENT_PROMOTION", promotion)
      commit("SET_LOADING", false)
    } catch (error) {
      commit("SET_LOADING", false)
      throw error
    }
  },
  async deletePromotion({ commit, dispatch }, id) {
    commit("SET_LOADING", true)
    try {
      await service.deletePromotion(id)
      dispatch("fetchPromotions")
      commit("SET_LOADING", false)
    } catch (error) {
      commit("SET_LOADING", false)
      throw error
    }
  },
  async toggleActive({ dispatch }, id) {
    let p = state.promotions.find(el => el.id == id)
    if (p) {
      return dispatch("updatePromotion", {
        id,
        data: { active: p.active ? 0 : 1 },
      })
    }
  },
}

export const getters = {
  promotions: state => state.promotions,
  statuses: state => state.statuses,
  currentPromotion: state => state.currentPromotion,
  loading: state => state.loading,
}
