/**
 * DEALER_OPERATOR_LOYALTY
 */

export default (() => {
  return {
    DEALER_OPERATOR_LOYALTY: {
      defaultSelectedId: ["8"],
      items: [
        {
          id: "8",
          icon: "exclamation-circle",
          name: "Производители",
          path: "/dealers",
        },
        {
          id: "Promotions",
          name: "Документы",
          icon: "bar-chart",
          path: "/promotions",
        },
        {
          id: "Reports",
          name: "Отчеты",
          icon: "bar-chart",
          path: "/reports",
        },
        {
          id: "Prices",
          name: "Прайс-Листы",
          icon: "container",
          path: "/prices",
        },
        {
          id: "News",
          name: "Новости",
          icon: "exclamation-circle",
          path: "/news",
        },
      ],
    },
  }
})()
