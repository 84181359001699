export default [
  {
    key: 1,
    dealerName: "АВТОГРАД ПРЕСТИЖ",
    declainedLinesAmount: "8",
    declainedLinesPresence: "2",
    declainedLinesSroch: "1",
    declainedLinesSklad: "0",
    declainedLinesOrder: "2",
    declainedLinesOtherDealers: "1",
    declainedLinesOther: "2",
  },
  {
    key: 2,
    dealerName: "БОВИД АВТО",
    declainedLinesAmount: "24",
    declainedLinesPresence: "16",
    declainedLinesSroch: "2",
    declainedLinesSklad: "3",
    declainedLinesOrder: "0",
    declainedLinesOtherDealers: "1",
    declainedLinesOther: "2",
  },
  {
    key: 3,
    dealerName: "АВТОГРАД ПЛЮС",
    declainedLinesAmount: "26",
    declainedLinesPresence: "11",
    declainedLinesSroch: "1",
    declainedLinesSklad: "12",
    declainedLinesOrder: "0",
    declainedLinesOtherDealers: "0",
    declainedLinesOther: "2",
  },
  {
    key: 4,
    dealerName: "ПЛАНЕТА АВТО",
    declainedLinesAmount: "9",
    declainedLinesPresence: "3",
    declainedLinesSroch: "3",
    declainedLinesSklad: "3",
    declainedLinesOrder: "0",
    declainedLinesOtherDealers: "0",
    declainedLinesOther: "0",
  },
  {
    key: 5,
    dealerName: "АРКОНТ",
    declainedLinesAmount: "10",
    declainedLinesPresence: "2",
    declainedLinesSroch: "8",
    declainedLinesSklad: "0",
    declainedLinesOrder: "0",
    declainedLinesOtherDealers: "0",
    declainedLinesOther: "0",
  },
]
