<template>
  <a-layout-header class="header">
    <div class="logo">
      <router-link to="/">
        <span>CoDEX</span>
      </router-link>
    </div>

    <div class="right-menu">
      <div v-if="isOwner" class="status-owner">Статус: Поставщик</div>
      <div class="diller-info" v-if="isBuyer">
        <!-- <div class="main">
          <div class="element title">Ваш дилер:</div>
          <div class="element name">ТЕМП-АВТО</div>
          <div class="element person">Андрей Сергеев</div>
        </div>
        <img class="avatar" src="../assets/banner/avatar.jpg" alt="" />
        <div class="info">
          <div class="element phone">+7(925)123-4567</div>
          <a class="element email" href="mailto:opt@temp-auto.ru">
            opt@temp-auto.ru
          </a>
          <div class="element time">с 9-00 до 18-00</div>
        </div> -->
        <a-popover placement="bottom">
          <template slot="content">
            <div class="main">
              <div class="info">
                <div class="element phone">+7(925)123-4567</div>
                <a class="element email" href="mailto:opt@temp-auto.ru">
                  opt@temp-auto.ru
                </a>
                <div class="element time">с 9-00 до 18-00</div>
              </div>
            </div>
          </template>
          <template slot="title">
            <div class="main">
              <div class="element title">Ваш производитель:</div>
              <div class="element name">ТЕМП-АВТО</div>
              <div class="element person">Андрей Сергеев</div>
            </div>
          </template>
          <a-avatar shape="square" size="large" icon="user" />
        </a-popover>
      </div>
      <div class="diller-info" v-if="isDealer">
        <a-popover placement="bottom">
          <template slot="title">
            <div class="main">
              <div class="element name">ТЕМП-АВТО</div>
              <div class="element person">Андрей Сергеев</div>
            </div>
          </template>
          <a-avatar shape="square" size="large" icon="user" />
        </a-popover>
      </div>
      <a-menu
        theme="light"
        mode="horizontal"
        :default-selected-keys="['1']"
        class="menu"
      >
        <a-menu-item @click="onLogout" key="1">Выйти</a-menu-item>
      </a-menu>
    </div>
  </a-layout-header>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  data: () => ({}),
  computed: mapGetters({
    isBuyer: "auth/isBuyer",
    isDealer: "auth/isDealer",
    isOwner: "auth/isOwner",
    role: "auth/role",
  }),
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    onLogout() {
      this.logout()
      document.head.querySelectorAll("#quiz-widget").forEach(script => {script.parentNode.removeChild(script)})
      document.getElementById("quiz-start").style.display = "none"
      this.$router.push("/login")
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #fff;
  height: 80px;
  line-height: 0;
  padding: 0;

  .logo {
    text-align: center;
    margin: 5px 10px;
    height: 50px;
    width: 120px;
    border-radius: 5px;
    background: rgba(0, 21, 41, 0.8);
    & span {
      display: block;
      color: white;
      font-size: 16pt;
      font-weight: bold;
      text-align: center;
      margin-top: 1.6rem;
    }
  }

  .banners {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .banner {
    padding: 5px 0;
    margin: 0 10px;
    height: 80px;
  }
  .chrysler {
    max-width: 200px;
    max-height: 50px;
  }

  .diller-info {
    display: flex;
    justify-content: center;
    align-items: center;

    color: black;

    line-height: normal;

    .main {
      display: flex;
      flex-direction: column;

      margin: 0 15px;

      .element {
        padding-top: 2px;
      }

      .title,
      .name {
        font-weight: 900;
      }

      .title {
        font-size: 15px;
      }

      .name {
        font-size: 18px;
      }
    }

    .avatar {
      width: 58px;
      height: 58px;

      margin: 0 5px;
    }

    .info {
      margin-left: 15px;

      font-weight: 500;

      .element {
        display: block;
        padding-top: 2px;
      }
    }
  }

  .right-menu {
    display: flex;

    .diller-info {
      margin-right: 20px;
    }

    .menu {
      line-height: 64px;
    }
    .status-owner {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      margin-right: 15px;
      font-weight: 900;
      font-size: 18px;
      color: #1890ff;
    }
  }
}
</style>
