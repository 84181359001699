import DealerService from "@/services/api/v1/DealerService"
const services = new DealerService()

import CreateDealerService from "@/services/api/v1/CreateDealerService"
const createService = new CreateDealerService()

export const state = {
  dealers: [],
  dealerUsers: [],
  manufacturerDealers: [],
  currentDealer: null,
  columns: [
    {
      title: "Производитель",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "ФИО",
      dataIndex: "site",
      key: "site",
    },
  ],
  columnsDealerUsers: [
    {
      title: "RS код",
      dataIndex: "name",
      key: "name",
      scopedSlots: { customRender: "name" },
    },
    {
      title: "Почта",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Роль",
      dataIndex: "roles",
      key: "roles",
    },
    {
      title: "Действия",
      dataIndex: "operation",
      scopedSlots: { customRender: "operation" },
      className: "column-action",
      width: 110,
    },
  ],
  createForm: {
    name: "",
    paymentGroup: {
      id: "",
      name: "",
      ordering: "",
    },
    userId: "",
    headName: "",
    headPosition: "",
    legalInfo: {
      name: "",
      paymentAccount: "",
      bankId: "",
      bankName: "",
      correspondedAccount: "",
      underlyingDocument: "",
      inn: "",
      kpp: "",
      ogrn: "",
      okpo: "",
      address: "",
      physicalAddress: "",
      phone: "",
      site: "",
    },
    contacts: [
      {
        type: "Взаиморасчеты",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Отправка прайс-листов",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Руководитель",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Заказы",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Возвраты",
        name: "",
        phone: "",
        email: "",
      },
      /*{
        type: "Обновление паролей",
        name: "",
        phone: "",
        email: "",
      },*/
    ],
  },
}

export const mutations = {
  SET_MANUFACTURER_DEALERS(state, payload) {
    state.manufacturerDealers = payload
  },
  SET_MANUFACTURERS(state, payload) {
    state.dealers = payload
  },
  SET_CURRENT_MANUFACTURER(state, payload) {
    state.currentDealer = payload
  },
  SET_CREATE_FORM(state, payload) {
    state.createForm = payload
  },
  SET_CREATE_FORM_FIELD(state, payload) {
    state.createForm[payload.name] = payload.value
  },
  SET_CREATE_FORM_CONTACT_FIELD(state, payload) {
    state.createForm.contacts[payload.index][payload.field] = payload.value
  },
  SET_CREATE_MANUFACTURER_FORM_ERROR(state, payload) {
    state.createFormError = payload
  },
  CLEAR_CREATE_MANUFACTURER_FORM_ERROR(state) {
    state.createFormError = null
  },
  SET_MANUFACTURER_USERS(state, payload) {
    state.dealerUsers = payload
  },
  DEL_MANUFACTURER_USER_ITEM(state, payload) {
    state.dealerUsers = state.dealerUsers.filter(el => el.id !== payload)
  },
}

export const actions = {
  async fetchManufacturerDealers({ commit }, manufacturer_id) {
    const dealers = await services.getDealerBuyers(manufacturer_id)
    const response = dealers.data.items
    commit("SET_MANUFACTURER_DEALERS", response)
  },
  async fetchDealers({ commit }) {
    const dealers = await services.getDealers()
    const responce = dealers.data.items
    commit("SET_MANUFACTURER_DEALERS", responce)
  },
  async fetchDealerById({ commit }, payload) {
    const dealer = await services.getSingleDealer(parseInt(payload))
    const responce = await dealer.data
    commit("SET_CURRENT_MANUFACTURER", responce)
  },
  async createDealer({ commit, getters }, userResp) {
    const dealerFormData = getters.createForm
    const contacts = dealerFormData.contacts.filter(item => {
      if (item && (item.name || item.phone || item.email)) {
        return item
      }
    })
    dealerFormData.userId = userResp.data.id
    dealerFormData.contacts = contacts
    return new Promise((resolve, reject) => {
      commit("CLEAR_CREATE_MANUFACTURER_FORM_ERROR")
      createService
        .createDealer(dealerFormData)
        .then(resp => resolve(resp))
        .catch(error => {
          reject(error.response)
        })
    })
  },
  async fetchDealerUsers({ commit }) {
    console.log("fetchDealerUsers")
    const dealerUsers = await services.getDealerUsers()
    const response = dealerUsers.data.data
    commit("SET_MANUFACTURER_USERS", response)
  },
  async deleteDealerUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("DEL_MANUFACTURER_USER_ITEM", id)
      services
        .delSingleDealerUsers(id)
        .then(() => resolve())
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  },
}

export const getters = {
  dealers: state => state.dealers,
  currentDealer: state => state.currentDealer,
  columns: state => state.columns,
  columnsProvider: state => state.columnsProvider,
  columnsDealerUsers: state => state.columnsDealerUsers,
  createForm: state => state.createForm,
  data: state => state.createForm.contacts,
  dealerUsers: state => state.dealerUsers,
  manufacturerDealers: state => state.manufacturerDealers,
}
