export default [
  {
    img: require("./../../../../assets/promotions/1.png"),
    actionTo: " 31.05.2021",
    status: "ON",
    link: "https://www.tesla.ru/ownership/special-programs/kaleidoscope.html#/",
  },
  {
    img: require("./../../../../assets/promotions/2.png"),
    actionTo: "31.03.2021",
    status: "OFF",
    link: "https://www.tesla.ru/ownership/special-programs/kaleidoscope.html#/",
  },
  {
    img: require("./../../../../assets/promotions/3.png"),
    actionTo: "31.03.2021",
    status: "ON",
    link: "https://www.tesla.ru/ownership/special-programs/kaleidoscope.html#/",
  },
  {
    img: require("./../../../../assets/promotions/4.png"),
    actionTo: "30.06.2021",
    status: "ON",
    link: "https://www.tesla.ru/ownership/special-programs/kaleidoscope.html#/",
  },
]
