export default [
  {
    key: 1,
    name: "ВСЕГО по дилеру",
    connectedAt: "",
    orders_sum: 334,
    orders_lines: 334,
    decline_sum: 107458,
    decline_lines: 30,
    approved_sum: 798692,
    approved_lines: 528,
    in_work_sum: 46611,
    in_work_lines: 16,
    departed_sum: 1025478,
    departed_lines: 224,
    approveKef: 0.84,
  },
  {
    key: 2,
    name: "ИТОГО (ТОП-10)",
    connectedAt: "",
    orders_sum: 940021,
    orders_lines: 307,
    decline_sum: 103143,
    decline_lines: 26,
    approved_sum: 790267,
    approved_lines: 265,
    in_work_sum: 46611,
    in_work_lines: 16,
    departed_sum: 1005760,
    departed_lines: 203,
    approveKef: 0.84,
  },
  {
    key: 3,
    name: "AVALON AVTO Воронеж",
    connectedAt: "12.05.2020",
    orders_sum: 274503,
    orders_lines: 86,
    decline_sum: 34750,
    decline_lines: 6,
    approved_sum: 221096,
    approved_lines: 73,
    in_work_sum: 18657,
    in_work_lines: 7,
    departed_sum: 356805,
    departed_lines: 62,
    approveKef: 0.81,
  },
  {
    key: 4,
    name: "Autopower",
    connectedAt: "01.03.2020",
    orders_sum: 149850,
    orders_lines: 48,
    decline_sum: 29501,
    decline_lines: 3,
    approved_sum: 108849,
    approved_lines: 43,
    in_work_sum: 11500,
    in_work_lines: 2,
    departed_sum: 269510,
    departed_lines: 32,
    approveKef: 0.73,
  },
  {
    key: 5,
    name: "Avalon Avto на Куколкина",
    connectedAt: "24.07.2020",
    orders_sum: 145700,
    orders_lines: 41,
    decline_sum: 7475,
    decline_lines: 2,
    approved_sum: 132722,
    approved_lines: 37,
    in_work_sum: 5503,
    in_work_lines: 2,
    departed_sum: 98420,
    departed_lines: 29,
    approveKef: 0.91,
  },
  {
    key: 6,
    name: "ВсеАвто",
    connectedAt: "01.08.2020",
    orders_sum: 139401,
    orders_lines: 56,
    decline_sum: 11210,
    decline_lines: 4,
    approved_sum: 121560,
    approved_lines: 49,
    in_work_sum: 6631,
    in_work_lines: 3,
    departed_sum: 39100,
    departed_lines: 28,
    approveKef: 0.87,
  },
  {
    key: 7,
    name: "ООО ТОТА",
    connectedAt: "01.02.2020",
    orders_sum: 87050,
    orders_lines: 27,
    decline_sum: 7705,
    decline_lines: 1,
    approved_sum: 79345,
    approved_lines: 26,
    in_work_sum: 0,
    in_work_lines: 0,
    departed_sum: 45870,
    departed_lines: 17,
    approveKef: 0.91,
  },
  {
    key: 8,
    name: "ИП Латыпов",
    connectedAt: "06.08.2020",
    orders_sum: 59704,
    orders_lines: 19,
    decline_sum: 215,
    decline_lines: 3,
    approved_sum: 55169,
    approved_lines: 14,
    in_work_sum: 4320,
    in_work_lines: 2,
    departed_sum: 33500,
    departed_lines: 12,
    approveKef: 0.92,
  },
  {
    key: 9,
    name: "АНГЛИЯ СЕРВИС",
    connectedAt: "03.03.2020",
    orders_sum: 33271,
    orders_lines: 12,
    decline_sum: 1880,
    decline_lines: 2,
    approved_sum: 31391,
    approved_lines: 10,
    in_work_sum: 0,
    in_work_lines: 0,
    departed_sum: 25860,
    departed_lines: 8,
    approveKef: 0.94,
  },
  {
    key: 10,
    name: "Сервис реек",
    connectedAt: "21.07.2020",
    orders_sum: 21502,
    orders_lines: 11,
    decline_sum: 703,
    decline_lines: 1,
    approved_sum: 20799,
    approved_lines: 10,
    in_work_sum: 0,
    in_work_lines: 0,
    departed_sum: 88590,
    departed_lines: 10,
    approveKef: 0.97,
  },
  {
    key: 11,
    name: "ДИЛЕР-ПАРТС",
    connectedAt: "25.04.2020",
    orders_sum: 16740,
    orders_lines: 4,
    decline_sum: 8504,
    decline_lines: 3,
    approved_sum: 8236,
    approved_lines: 1,
    in_work_sum: 0,
    in_work_lines: 0,
    departed_sum: 35805,
    departed_lines: 4,
    approveKef: 0.49,
  },
  {
    key: 12,
    name: "БРИТПАРТ",
    connectedAt: "01.09.2020",
    orders_sum: 12300,
    orders_lines: 3,
    decline_sum: 1200,
    decline_lines: 1,
    approved_sum: 11100,
    approved_lines: 2,
    in_work_sum: 0,
    in_work_lines: 0,
    departed_sum: 12300,
    departed_lines: 1,
    approveKef: "0.90",
  },
]
