<template>
  <a-layout id="app">
    <Slider />
    <a-layout :style="{ marginLeft: '250px' }">
      <Header v-show="false" />

      <a-layout-content class="container loading">
        <h1 v-show="title" class="page-title">{{ title }}</h1>
        <Breadcrumb />
        <div class="content">
          <a-spin
            v-if="isPageLoading"
            tip="Загрузка..."
            size="large"
            style="top: 50%; left: 50%; position: absolute"
          />
          <router-view />
        </div>
      </a-layout-content>
      <a-layout-footer>
        <Footer />
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import Header from "../modules/Header"
import Footer from "../modules/Footer"
import Slider from "../modules/Slider"
import Breadcrumb from "../components/shared/breadcrumb/Breadcrumb"
import { mapGetters } from "vuex"
export default {
  components: {
    Header,
    Footer,
    Slider,
    Breadcrumb,
  },
  computed: {
    ...mapGetters({
      isPageLoading: "auth/isPageLoading",
    }),
    title() {
      return this.$route.meta.title || ""
    },
  },
}
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
  background: rgba(255, 255, 255, 0.2);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  min-height: 100vh;
}

.container {
  margin: 0 16px;
}

.content {
  padding: 24px 5px !important;
  background: #eff2f5 !important;
  min-height: 600px !important;
}

.page-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.3;
  margin: 15px 4px -10px;
}

.content-container {
  border-radius: 6px;
  background-color: #fff;
  padding: 25px;
  width: 100%;
  &:not(:first-child) {
    margin-top: 25px;
  }
}
</style>
