import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"

export default class ClientService {
  async search(paymentGroup, name, inn, page = 1, per_page = 10) {
    const options = {}

    if (paymentGroup) {
      options.paymentGroup = paymentGroup
    }
    if (name) {
      options["code"] = name
    }
    if (inn) {
      options["inn"] = inn
    }

    options["page"] = page
    options["per_page"] = per_page

    return axios.get(
      `${API_PREFIX}/client?${new URLSearchParams(options).toString()}`
    )
  }
  async searchglobal(paymentGroup, name, inn, code) {
    const options = {}

    if (paymentGroup) {
      options.paymentGroup = paymentGroup
    }
    if (name) {
      options["legalInfo.name"] = name
    }
    if (inn) {
      options["legalInfo.inn"] = inn
    }
    if (code) {
      options["code"] = code
    }

    return axios.get(
      `${API_PREFIX}/clientsearch?${new URLSearchParams(options).toString()}`
    )
  }
  async getSingleClient(id) {
    return axios.get(`${API_PREFIX}/client/${id}`)
  }
  async bindClient(userId, clientId) {
    return axios.put(`${API_PREFIX}/client`, {
      userId,
      clientId,
    })
  }
  async delSingleClient(id) {
    return axios.delete(`${API_PREFIX}/client/${id}`)
  }
  async updateClient(createForm) {
    return axios.patch(`${API_PREFIX}/client/${createForm.id}`, {
      city_id: createForm.cityId,
      code: createForm.code,
      name: createForm.name,
      paymentGroupId: createForm.paymentGroupId,
      head_name: createForm.headName,
      head_position: createForm.headPosition,
      phone: createForm.phone,
      email: createForm.email,
      legalInfo: {
        name: createForm.legalInfoName,
        paymentAccount: createForm.legalInfoPaymentAccount,
        bankId: createForm.legalInfoBankId,
        bankName: createForm.legalInfoBankName,
        correspondedAccount: createForm.legalInfoCorrespondedAccount,
        underlyingDocument: createForm.legalInfoUnderlyingDocument,
        inn: createForm.legalInfoInn,
        kpp: createForm.legalInfoKpp,
        ogrn: createForm.legalInfoOgrn,
        okpo: createForm.legalInfoOkpo,
        address: createForm.legalInfoAddress,
        physicalAddress: createForm.legalInfoPhysicalAddress,
      },
      delivery_period: createForm.deliveryPeriod,
      mass_restrict: createForm.massRestrict,
      //contacts: createForm.contacts,
      //paymentGroup: createForm.paymentGroup,
    })
  }
  async createClientUser(userInfo) {
    return axios.post(`${API_PREFIX}/client/user`, userInfo.user)
  }
  async geClientUsers() {
    return axios.get(`${API_PREFIX}/client/users?page=1&per_page=50`)
  }
  async delSingleClientUsers(id) {
    return axios.delete(`${API_PREFIX}/client/users/${id}`)
  }
  async getCities() {
    return axios.get(`${API_PREFIX}/cities`)
  }

  async getCompaniesList() {
    return axios.get(`${API_PREFIX}/info/companies`)
  }
  async getDealersList() {
    return axios.get(`${API_PREFIX}/info/dealers`)
  }
  async getClientsList() {
    return axios.get(`${API_PREFIX}/info/clients`)
  }
  async getBrandsList() {
    return axios.get(`${API_PREFIX}/info/brands`)
  }
}
