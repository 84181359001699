import axios from "axios"

import errorHandler from "@/utils/errorHandler"
import { API_PREFIX } from "@/services/api/v1/const"

export default class PricesService {
  async getAvailablePrices() {
    try {
      return await axios.get(
        `${API_PREFIX}/available-catalogs?page=1&per_page=10`,
        {
          params: {},
        }
      )
    } catch (error) {
      errorHandler(error)
    }
  }

  async getPrices({ type = "local" }) {
    try {
      return await axios.get(`${API_PREFIX}/catalog?type=type`, {
        params: {
          type,
        },
      })
    } catch (error) {
      errorHandler(error)
    }
  }
  async getCommissions() {
    try {
      return await axios.get(`${API_PREFIX}/commission`)
    } catch (error) {
      errorHandler(error)
    }
  }

  async setCommissions(commissions) {
    try {
      return await axios.put(`${API_PREFIX}/commission`, commissions)
    } catch (error) {
      errorHandler(error)
    }
  }

  async uploadPrice(formData) {
    const headers = { "Content-Type": "multipart/form-data" }
    try {
      return await axios.post(`${API_PREFIX}/import-catalog-file`, formData, {
        headers,
      })
    } catch (error) {
      errorHandler(error)
    }
  }
  async updatePrice(price, id) {
    return axios.patch(`${API_PREFIX}/catalog/${id}`, {
      name: price.name,
      code: price.code,
      type: price.type,
      deliveryPeriod: Number(price.deliveryPeriod),
      sharing: price.sharing,
      priceType: price.priceType,
      status: price.status,
      id: price.id,
      createdAt: price.createdAt,
    })
  }
  async deletePrice(id) {
    return await axios.delete(`${API_PREFIX}/catalog/${id}`)
  }
}
