export default [
  {
    id: 1,
    key: 1,
    brand: "LAND ROVER",
    fileName: "Наличие ЦС LR.xlsx",
    uploadedAt: "2020-12-14 17:20:09",
    uploadMethod: "Сервер",
    isLatest: true,
  },
  {
    id: 2,
    key: 2,
    brand: "JAGUAR",
    fileName: "Наличие ЦС Jaguar.xlsx",
    uploadedAt: "2020-12-14 17:21:35",
    uploadMethod: "Сервер",
    isLatest: true,
  },
  {
    id: 3,
    key: 3,
    brand: "LAND ROVER",
    fileName: "Наличие ЦС LR.xlsx",
    uploadedAt: "2020-12-14 09:41:17",
    uploadMethod: "Сервер",
    isLatest: false,
  },
  {
    id: 4,
    key: 4,
    brand: "LAND ROVER",
    fileName: "Наличие ЦС Jaguar.xlsx",
    uploadedAt: "2020-12-14 09:40:53",
    uploadMethod: "Сервер",
    isLatest: false,
  },
  {
    id: 5,
    key: 5,
    brand: "LAND ROVER",
    fileName: "Актуальные остатки LR.xlsx",
    uploadedAt: "2020-12-13 18:02:10",
    uploadMethod: "Сайт-ручная загрузка",
    isLatest: false,
  },
  {
    id: 6,
    key: 6,
    brand: "JAGUAR",
    fileName: "Актуальные остатки Jaguar.xlsx",
    uploadedAt: "2020-12-13 17:59:55",
    uploadMethod: "Сайт-ручная загрузка",
    isLatest: false,
  },
  {
    id: 7,
    key: 7,
    brand: "JAGUAR",
    fileName: "Наличие ЦС Jaguar.xlsx",
    uploadedAt: "2020-12-13 09:39:04",
    uploadMethod: "Сервер",
    isLatest: false,
  },
  {
    id: 8,
    key: 8,
    brand: "LAND ROVER",
    fileName: "Наличие ЦС LR.xlsx",
    uploadedAt: "2020-12-13 09:38:41",
    uploadMethod: "Сервер",
    isLatest: false,
  },
  {
    id: 9,
    key: 9,
    brand: "LAND ROVER",
    fileName: "Наличие ЦС LR.xlsx",
    uploadedAt: "2020-12-12 18:45:17",
    uploadMethod: "Сервер",
    isLatest: false,
  },
]
