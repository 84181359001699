import LoyaltyLevelsService from "@/services/api/v1/LoyaltyLevelsService"
const service = new LoyaltyLevelsService()
const editItem = { level: null, key: null, value: null }

export const state = {
  loyaltyLevels: [],
  loyaltyLevel: [],
  errorMsg: null,
  loading: false,
  editModalVisible: false,
  editItem: { ...editItem },
}
export const mutations = {
  SET_LOYALTY_LEVELS_LIST: (state, payload) => (state.loyaltyLevels = payload),
  SET_SINGLE_LOYALTY_LEVEL: (state, payload) => (state.loyaltyLevel = payload),
  SET_ERROR_MSG(state, payload) {
    state.errorMsg = payload
  },
  SET_LOADING: (state, payload) => (state.loading = payload),
  SET_EDIT_MODAL_VISIBLE: (state, payload) =>
    (state.editModalVisible = payload),
  SET_EDIT_ITEM: (state, payload) => (state.editItem = payload),
  RESET_EDIT_ITEM: state => (state.editItem = { ...editItem }),
}
export const getters = {
  loyaltyLevels: state => state.loyaltyLevels,
  loyaltyLevel: state => state.loyaltyLevel,
  loading: state => state.loading,
  editModalVisible: state => state.editModalVisible,
  editItem: state => state.editItem,
}

export const actions = {
  getLoyaltyLevels({ commit }) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      service
        .getLoyaltyLevels()
        .then(response => {
          commit("SET_LOYALTY_LEVELS_LIST", response.data.data)
        })
        .catch(error => {
          if (error.response)
            commit("SET_ERROR_MSG", error.response.data.message)
          reject(error.response)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },

  getSingleLoyaltyLevel({ commit }, payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      service
        .getSingleLoyaltyLevel(payload)
        .then(response => {
          commit("SET_SINGLE_LOYALTY_LEVEL", response.data.data)
        })
        .catch(error => {
          if (error.response)
            commit("SET_ERROR_MSG", error.response.data.message)
          reject(error.response)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },

  updateSingleLoyaltyLevel({ commit, dispatch }, payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      service
        .updateSingleLoyaltyLevel(payload)
        // eslint-disable-next-line no-unused-vars
        .then(response => {})
        .catch(error => {
          if (error.response)
            commit("SET_ERROR_MSG", error.response.data.message)
          reject(error.response)
        })
        .finally(() => {
          commit("SET_EDIT_MODAL_VISIBLE", false)
          commit("SET_LOADING", false)
          dispatch("getLoyaltyLevels")
        })
    })
  },
}
