import { message } from "ant-design-vue"

const errorHandler = error => {
  let meessageText = "Ошибка на сервере"
  const errorResponse = error.response?.data

  if (errorResponse) {
    if (errorResponse.violations && errorResponse.violations.length) {
      meessageText = errorResponse.violations.reduce(
        (sum, current) => `${sum} ${current.path} ${current.message}`,
        ""
      )
    }
  } else {
    if (error.status === 403) {
      meessageText = "Доступ закрыт"
    }
  }

  message.error(meessageText)
}

export default errorHandler
