export default [
  {
    key: 1,
    dealerName: "АВТОГРАД ПРЕСТИЖ",
    declainedLinesAmount: "21359",
    declainedLinesPresence: "391",
    declainedLinesSroch: "1270",
    declainedLinesSklad: "0",
    declainedLinesOrder: "10900",
    declainedLinesOtherDealers: "1598",
    declainedLinesOther: "7200",
  },
  {
    key: 2,
    dealerName: "БОВИД АВТО",
    declainedLinesAmount: "22641",
    declainedLinesPresence: "3191",
    declainedLinesSroch: "709",
    declainedLinesSklad: "14072",
    declainedLinesOrder: "0",
    declainedLinesOtherDealers: "1701",
    declainedLinesOther: "2968",
  },
  {
    key: 3,
    dealerName: "АВТОГРАД ПЛЮС",
    declainedLinesAmount: "107458",
    declainedLinesPresence: "55057",
    declainedLinesSroch: "19841",
    declainedLinesSklad: "21090",
    declainedLinesOrder: "0",
    declainedLinesOtherDealers: "0",
    declainedLinesOther: "11470",
  },
  {
    key: 4,
    dealerName: "ПЛАНЕТА АВТО",
    declainedLinesAmount: "11741",
    declainedLinesPresence: "4986",
    declainedLinesSroch: "2415",
    declainedLinesSklad: "4340",
    declainedLinesOrder: "0",
    declainedLinesOtherDealers: "0",
    declainedLinesOther: "0",
  },
  {
    key: 5,
    dealerName: "АРКОНТ",
    declainedLinesAmount: "3572",
    declainedLinesPresence: "1564",
    declainedLinesSroch: "2008",
    declainedLinesSklad: "0",
    declainedLinesOrder: "0",
    declainedLinesOtherDealers: "0",
    declainedLinesOther: "0",
  },
]
