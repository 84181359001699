import axios from "axios"

import errorHandler from "@/utils/errorHandler"
import { API_PREFIX } from "@/services/api/v1/const"

export async function getPaymentGroups() {
  try {
    return await axios.get(`${API_PREFIX}/payment-groups?page=1&per_page=20`)
  } catch (error) {
    errorHandler(error)
  }
}

export async function getPaymentGroupsLatest() {
  try {
    return await axios.get(`${API_PREFIX}/payment-groups/latest`)
  } catch (error) {
    errorHandler(error)
  }
}

export async function getPaymentGroupsMatrix() {
  try {
    return await axios.get(`${API_PREFIX}/payment-groups/matrix`)
  } catch (error) {
    errorHandler(error)
  }
}

export async function createPaymentGroup(payload) {
  try {
    return await axios.post(`${API_PREFIX}/payment-groups`, payload.data)
  } catch (error) {
    errorHandler(error)
  }
}

export async function updatePaymentGroup(payload) {
  try {
    return await axios.put(
      `${API_PREFIX}/payment-groups/${payload.payment_group_id}`,
      payload.data
    )
  } catch (error) {
    errorHandler(error)
  }
}

export async function delPaymentGroup(payload) {
  try {
    return await axios.delete(
      `${API_PREFIX}/payment-groups/${payload.payment_group_id}`
    )
  } catch (error) {
    errorHandler(error)
  }
}
