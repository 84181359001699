export default [
  {
    key: 1,
    brand: "LAND ROVER",
    fileName: "Экспорт LR.xlsx",
    downloadedAt: "2020-12-14 09:41:17",
    uploadMethod: "Автоматическая",
  },
  {
    key: 2,
    brand: "JAGUAR",
    fileName: "Экспорт Jaguar.xlsx",
    downloadedAt: "2020-12-14 09:40:53",
    uploadMethod: "Автоматическая",
  },
  {
    key: 3,
    brand: "LAND ROVER",
    fileName: "Экспорт LR.xlsx",
    downloadedAt: "2020-12-13 18:02:10",
    uploadMethod: "Сайт-ручная загрузка",
  },
  {
    key: 4,
    brand: "JAGUAR",
    fileName: "Экспорт Jaguar.xlsx",
    downloadedAt: "2020-12-13 17:59:55",
    uploadMethod: "Сайт-ручная загрузка",
  },
  {
    key: 5,
    brand: "JAGUAR",
    fileName: "Экспорт Jaguar.xlsx",
    downloadedAt: "2020-12-13 09:39:04",
    uploadMethod: "Автоматическая",
  },
  {
    key: 6,
    brand: "LAND ROVER",
    fileName: "Экспорт LR.xlsx",
    downloadedAt: "2020-12-13 09:38:41",
    uploadMethod: "Автоматическая",
  },
  {
    key: 7,
    brand: "LAND ROVER",
    fileName: "Экспорт LR.xlsx",
    downloadedAt: "2020-12-12 18:45:17",
    uploadMethod: "Автоматическая",
  },
]
