import ClientService from "@/services/api/v1/ClientService"
import CreateClientService from "@/services/api/v1/CreateClientService"

const service = new ClientService()

const createService = new CreateClientService()

export const state = {
  columns: [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   width: 50,
    // },
    {
      title: "Код дилера",
      key: "code",
      dataIndex: "code",
      width: 110,
    },
    {
      title: "Наименование дилера",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Город",
      dataIndex: "city_name",
      key: "city_name",
      width: 180,
    },
    {
      title: "Адрес автосалона",
      dataIndex: "physical_address",
      key: "physical_address",
      width: 250,
    },
    {
      title: "ИНН",
      dataIndex: "inn",
      key: "inn",
      width: 120,
    },
    // {
    //   title: "Группа опт",
    //   dataIndex: "paymentGroup.name",
    //   key: "optGroup",
    //   width: 110,
    // },
    // {
    //   title: "Прайс-лист наличие",
    //   dataIndex: "priceListPresence",
    //   key: "priceListPresence",
    //   width: 110,
    // },
    // {
    //   title: "Прайс-лист ЦС срочный",
    //   dataIndex: "priceListImmediate",
    //   key: "priceListImmediate",
    //   width: 130,
    // },
    // {
    //   title: "Прайс-лист ЦС складской",
    //   dataIndex: "priceListWarehouse",
    //   key: "priceListWarehouse",
    //   width: 130,
    // },
    // {
    //   title: "Прайс-лист Неликвид",
    //   dataIndex: "priceListNotLiquid",
    //   key: "priceListNotLiquid",
    //   width: 110,
    // },
    // {
    //   title: `Эл. адрес "Заказы"`,
    //   dataIndex: "mail",
    //   key: "mail",
    //   width: 150,
    // },
    {
      title: "Архив",
      dataIndex: "operation",
      scopedSlots: { customRender: "operation" },
      className: "column-action",
      width: 80,
    },
  ],
  columnsClientUsers: [
    {
      title: "Имя пользователя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Почта",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Роль",
      dataIndex: "roles",
      key: "roles",
    },
    {
      title: "Действия",
      dataIndex: "operation",
      scopedSlots: { customRender: "operation" },
      className: "column-action",
      width: 110,
    },
  ],
  clients: [],
  clientUsers: [],
  cities: [],
  companiesList: [],
  dealersList: [],
  clientsList: [],
  brandsList: [],
  paymentGroup: null,
  clientTypeId: 1,
  filters: {
    name: null,
    paymentGroup: null,
    inn: null,
    code: null,
  },
  // set: = value
  // clear: = null
  createFormError: null,
  contactNames: [
    "Взаиморасчеты",
    "Отправка прайс-листов",
    "Руководитель",
    "Заказы",
    "Возвраты",
    //"Обновление паролей",
  ],
  createFormOld: {
    name: "",
    paymentGroup: {
      id: "",
      name: "",
      ordering: "",
    },
    userId: "",
    user: "",
    headName: "",
    headPosition: "",
    legalInfo: {
      name: "",
      paymentAccount: "",
      bankId: "",
      bankName: "",
      correspondedAccount: "",
      underlyingDocument: "",
      inn: "",
      kpp: "",
      ogrn: "",
      okpo: "",
      address: "",
      physicalAddress: "",
    },
    contacts: [
      {
        type: "Взаиморасчеты",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Отправка прайс-листов",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Руководитель",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Заказы",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Возвраты",
        name: "",
        phone: "",
        email: "",
      },
      /*{
        type: "Обновление паролей",
        name: "",
        phone: "",
        email: "",
      },*/
    ],
    financeAccount: {
      amount: 0,
    },
    paymentGroupId: null,
  },
  createForm: {},
  financeAccount: {},
  paginationOptions: {
    pageSize: 10,
    totalCount: 0,
    currentPage: 1,
  },
  clientTypes: {
    1: "Юридическое лицо",
    2: "Физическое лицо",
  },
  // Физическое лицо
  formLegalEntity: function () {
    return {
      city_id: 1,
      client_type_id: 2,
      paymentGroupId: 25,
      first_name: "",
      last_name: "",
      middle_name: "",
      birth_date: "",
      phone: "",
      email: "",
      legalInfo: {
        inn: "",
      },
      user: {
        name: "",
        email: "",
        password: "",
      },
      delivery_period: 10,
      mass_restrict: "",
    }
  },
  // Юридическое лицо
  formIndividualsEntity: function () {
    return {
      client_type_id: 1,
      name: "",
      paymentGroupId: 0,
      headName: "",
      headPosition: "",
      legalInfo: {
        name: "",
        paymentAccount: "",
        bankId: "",
        bankName: "",
        correspondedAccount: "",
        underlyingDocument: "",
        inn: "",
        kpp: "",
        ogrn: "",
        okpo: "",
        address: "",
        physicalAddress: "",
      },
      contacts: [
        {
          type: "",
          name: "",
          phone: "",
          email: "",
        },
        {
          type: "",
          name: "",
          phone: "",
          email: "",
        },
      ],
      user: {
        name: "",
        email: "",
        password: "",
      },
      financeAccount: {
        amount: 0.0,
      },
      delivery_period: 10,
    }
  },
}
export const mutations = {
  SET_CLIENTS(state, payload) {
    state.clients = payload.map(item => {
      item["client_type_str"] = state.clientTypes[item.client_type_id]
      return item
    })
  },
  CLEAR_FILTERS(state) {
    state.filters.paymentGroup = null
    state.filters.name = null
    state.filters.inn = null
    state.filters.code = null
  },
  SET_FILTER_NAME(state, payload) {
    state.filters.name = payload
  },
  SET_FILTER_INN(state, payload) {
    state.filters.inn = payload
  },
  SET_FILTER_CODE(state, payload) {
    state.filters.code = payload
  },
  SET_FILTER_PAYMENT_GROUP(state, payload) {
    state.filters.paymentGroup = payload
  },
  SET_CREATE_CLIENT_FORM_ERROR(state, payload) {
    state.createFormError = payload
  },
  CLEAR_CREATE_CLIENT_FORM_ERROR(state) {
    state.createFormError = null
  },
  SET_CREATE_FORM_CONTACT_FIELD(state, payload) {
    state.createForm.contacts[payload.index][payload.field] = payload.value
  },
  SET_CREATE_FORM_FIELD(state, payload) {
    state.createForm[payload.name] = payload.value
  },
  SET_CREATE_FORM(state, payload) {
    state.createForm = payload
  },
  ADD_PHYSICAL_ADDRESS(state) {
    state.createForm.legalInfoPhysicalAddress.push("")
  },
  SET_PHYSICAL_ADDRESS(state, payload) {
    state.createForm.legalInfoPhysicalAddress[payload.index] = payload.value
  },
  SET_PAYMENT_GROUPS(state, payload) {
    state.paymentGroup = payload
  },
  SET_FINANCE_ACCOUNT(state, payload) {
    state.financeAccount = payload
  },
  DEL_CLIENT_ITEM(state, payload) {
    state.clients = state.clients.filter(el => el.id !== payload)
  },
  SET_CLIENT_USERS(state, payload) {
    state.clientUsers = payload
  },
  SET_CITIES(state, payload) {
    state.cities = payload
  },
  SET_CLIENT_TYPE(state, payload) {
    state.clientTypeId = payload
  },
  SET_CURRENT_PAGE(state, payload) {
    state.paginationOptions.currentPage = payload
  },
  SET_CLIENTS_TOTAL_COUNT(state, payload) {
    state.paginationOptions.totalCount = payload
  },
  DEL_CLIENT_USER_ITEM(state, payload) {
    state.clientUsers = state.clientUsers.filter(el => el.id !== payload)
  },
  SET_COMPANIES_LIST(state, payload) {
    state.companiesList = payload
  },
  SET_DEALERS_LIST(state, payload) {
    state.dealersList = payload
  },
  SET_CLIENTS_LIST(state, payload) {
    state.clientsList = payload
  },
  SET_BRANDS_LIST(state, payload) {
    state.brandsList = payload
  },
}

export const actions = {
  async fetchClients({ commit, getters }) {
    const paginationOptions = getters.paginationOptions
    const filters = getters.filters

    const response = await service.search(
      filters.paymentGroup,
      filters.name,
      filters.inn,
      paginationOptions.currentPage,
      paginationOptions.pageSize
    )

    commit("SET_CLIENTS", response.data.data)
    commit("SET_CLIENTS_TOTAL_COUNT", response.data.meta.total)
  },
  async searchClients({ commit, getters }) {
    const filters = getters.filters
    const response = await service.search(
      filters.paymentGroup,
      filters.name,
      filters.inn
    )

    commit("SET_CLIENTS", response.data.data)
    commit("SET_CLIENTS_TOTAL_COUNT", response.data.meta.total)
  },
  async createClient({ commit, getters }) {
    const createForm = getters.createForm
    // clientFormData.contacts = clientFormData.contacts.filter(item => {
    //   if (item && (item.name || item.phone || item.email)) {
    //     return item
    //   }
    // })

    // let createForm = Object.assign(userResp, clientFormData)
    let postData = {}
    switch (getters.clientTypeId) {
      case 1:
        postData = {
          client_type_id: 1,
          city_id: createForm.cityId,
          code: createForm.code,
          name: createForm.name,
          paymentGroupId: createForm.paymentGroup.id,
          head_name: createForm.headName,
          head_position: createForm.headPosition,
          phone: createForm.phone,
          email: createForm.email,
          legalInfo: {
            name: createForm.legalInfoName,
            paymentAccount: createForm.legalInfoPaymentAccount,
            bankId: createForm.legalInfoBankId,
            bankName: createForm.legalInfoBankName,
            correspondedAccount: createForm.legalInfoCorrespondedAccount,
            underlyingDocument: createForm.legalInfoUnderlyingDocument,
            inn: createForm.legalInfoInn,
            kpp: createForm.legalInfoKpp,
            ogrn: createForm.legalInfoOgrn,
            okpo: createForm.legalInfoOkpo,
            address: createForm.legalInfoAddress,
            physicalAddress: createForm.legalInfoPhysicalAddress,
          },
          delivery_period: createForm.deliveryPeriod,
          mass_restrict: createForm.massRestrict,
          // user: createForm.user,
          // financeAccount: {
          //   amount: createForm.financeAccount.amount,
          // },
        }
        break
      case 2:
        postData = {
          client_type_id: 2,
          city_id: createForm.cityId,
          paymentGroupId: createForm.paymentGroup.id,
          first_name: createForm.firstName,
          last_name: createForm.lastName,
          middle_name: createForm.middleName,
          birth_date: createForm.birthDate,
          phone: createForm.phone,
          legalInfo: {
            inn: createForm.legalInfoInn,
          },
          user: createForm.user,
          delivery_period: createForm.deliveryPeriod,
        }
        break
    }

    console.log(postData)
    return new Promise((resolve, reject) => {
      commit("CLEAR_CREATE_CLIENT_FORM_ERROR")
      createService
        .createClient(postData)
        .then(resp => resolve(resp))
        .catch(error => {
          reject(error.response)
        })
    })
  },
  async bindExistingClientToDealer(s, clientId) {
    await createService.bindClient({ id: clientId }).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        throw new Error(error.response.data.message)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message)
      }
      console.log(error.config)
    })
  },
  async updateClient({ commit, getters }) {
    const clientFormData = JSON.parse(JSON.stringify(getters.createForm))
    // clientFormData.contacts = clientFormData.contacts.filter(item => {
    //   if (item.name || item.phone || item.email) {
    //     return item
    //   }
    // })
    //console.log(clientFormData)

    return new Promise((resolve, reject) => {
      commit("CLEAR_CREATE_CLIENT_FORM_ERROR")
      service
        .updateClient(clientFormData)
        .then(() => resolve())
        .catch(error => {
          console.log(error)
          // if (error.response) { commit("SET_CREATE_CLIENT_FORM_ERROR", error.response.data.message); }
          reject()
        })
    })
  },
  async deleteClient({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_CREATE_CLIENT_FORM_ERROR")
      commit("DEL_CLIENT_ITEM", id)
      service
        .delSingleClient(id)
        .then(() => resolve())
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  },
  async bindClientWithUser({ getters }, userId) {
    const { name, headName, headPosition } = getters.createForm
    const neededClient = await getters.clients.filter(el => {
      if (
        el.name === name &&
        el.headName === headName &&
        el.headPosition === headPosition
      ) {
        return el
      }
    })
    const [el] = neededClient
    return new Promise((resolve, reject) => {
      service
        .bindClient(userId, el.id)
        .then(() => resolve())
        .catch(e => {
          console.log(e)
          reject()
        })
    })
  },
  // eslint-disable-next-line no-unused-vars
  async setFinanceAccountAmount({ commit, getters }, amount) {
    const clientId = getters.createForm.id
    return new Promise((resolve, reject) => {
      createService
        .setFinanceAccountAmount({
          clientId: clientId,
          amount: amount,
        })
        .then(resp => resolve(resp))
        .catch(error => {
          reject(error.response)
        })
    })
  },
  async getClient({ commit }, clientid) {
    const response = await service.getSingleClient(clientid)

    if (!response.data.client_type_id) return false

    let formObj = {}

    // Юр. лицо
    if (response.data.client_type_id === 1) {
      formObj = {
        id: response.data.id,
        status: response.data.status,
        createdAt: response.data.createdAt,
        cityId: response.data.city_id,
        code: response.data.code,
        name: response.data.name,
        paymentGroupId: response.data.paymentGroup.id,
        headName: response.data.headName,
        headPosition: response.data.headPosition,
        phone: response.data.phone,
        email: response.data.email,
        legalInfoName: response.data.legalInfo.name,
        legalInfoPaymentAccount: response.data.legalInfo.paymentAccount,
        legalInfoBankId: response.data.legalInfo.bankId,
        legalInfoBankName: response.data.legalInfo.bankName,
        legalInfoCorrespondedAccount:
          response.data.legalInfo.correspondedAccount,
        legalInfoUnderlyingDocument: response.data.legalInfo.underlyingDocument,
        legalInfoInn: response.data.legalInfo.inn,
        legalInfoKpp: response.data.legalInfo.kpp,
        legalInfoOgrn: response.data.legalInfo.ogrn,
        legalInfoOkpo: response.data.legalInfo.okpo,
        legalInfoAddress: response.data.legalInfo.address,
        legalInfoPhysicalAddress: response.data.legalInfo.physicalAddress,
        deliveryPeriod: response.data.delivery_period,
        massRestrict: response.data.mass_restrict,
        //paymentGroup: response.data.paymentGroup,
        //user: {},
        //contacts: response.data.contacts,
      }
    }

    // Физ. лицо
    if (response.data.client_type_id === 2) {
      formObj = {
        cityId: response.data.city_id,
        name: response.data.name,
        headName: response.data.headName,
        headPosition: response.data.headName,
        paymentGroup: response.data.paymentGroup,
        user: {},
        id: response.data.id,
        status: response.data.status,
        createdAt: response.data.createdAt,
        paymentGroupId: response.data.paymentGroup.id,
        deliveryPeriod: response.data.delivery_period,
        legalInfoInn: response.data.legalInfo.inn,
        //contacts: response.data.contacts,
        //legalInfoName: response.data.legalInfo.name,
        //legalInfoPaymentAccount: response.data.legalInfo.paymentAccount,
        //legalInfoBankId: response.data.legalInfo.bankId,
        //legalInfoBankName: response.data.legalInfo.bankName,
        //legalInfoCorrespondedAccount: response.data.legalInfo.correspondedAccount,
        //legalInfoUnderlyingDocument: response.data.legalInfo.underlyingDocument,
        //legalInfoKpp: response.data.legalInfo.kpp,
        //legalInfoOgrn: response.data.legalInfo.ogrn,
        //legalInfoOkpo: response.data.legalInfo.okpo,
        //legalInfoAddress: response.data.legalInfo.address,
        //legalInfoPhysicalAddress: response.data.legalInfo.physicalAddress,
      }
    }

    commit("SET_CREATE_FORM", formObj)
    commit("SET_CLIENT_TYPE", response.data.client_type_id)
    commit("SET_FINANCE_ACCOUNT", response.data.financeAccount)
  },
  async createClientUser(_, userInfo) {
    return new Promise((resolve, reject) => {
      service
        .createClientUser(userInfo)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  async fetchClientUsers({ commit }) {
    const clientUsers = await service.geClientUsers()
    const responce = clientUsers.data.data
    console.log(responce)
    commit("SET_CLIENT_USERS", responce)
  },
  async deleteClientUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("DEL_CLIENT_USER_ITEM", id)
      service
        .delSingleClientUsers(id)
        .then(() => resolve())
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  },
  async fetchCities({ commit }) {
    const cities = await service.getCities()
    const responce = cities.data.data
    commit("SET_CITIES", responce)
  },
  async fetchCompaniesList({ commit }) {
    const resp = await service.getCompaniesList()
    commit("SET_COMPANIES_LIST", resp.data.data)
  },
  async fetchDealersList({ commit }) {
    const resp = await service.getDealersList()
    commit("SET_DEALERS_LIST", resp.data.data)
  },
  async fetchClientsList({ commit }) {
    const resp = await service.getClientsList()
    commit("SET_CLIENTS_LIST", resp.data.data)
  },
  async fetchBrandsList({ commit }) {
    const resp = await service.getBrandsList()
    commit("SET_BRANDS_LIST", resp.data.data)
  },
}
export const getters = {
  columns(state) {
    return state.columns
  },
  columnsClientUsers(state) {
    return state.columnsClientUsers
  },
  clients(state) {
    return state.clients
  },
  filters(state) {
    return state.filters
  },
  createForm(state) {
    return state.createForm
  },
  data(state) {
    return state.createForm.contacts
  },
  contactNames(state) {
    return state.contactNames
  },
  paymentGroup(state) {
    return state.createForm.paymentGroup
  },
  clientUsers: state => state.clientUsers,
  cities: state => state.cities,
  financeAccount: state => state.financeAccount,

  formLegalEntity: state => state.formLegalEntity,
  formIndividualsEntity: state => state.formIndividualsEntity,
  clientTypeId: state => state.clientTypeId,

  paginationOptions(state) {
    return state.paginationOptions
  },

  companiesList: stat => stat.companiesList,
  dealersList: stat => stat.dealersList,
  clientsList: stat => stat.clientsList,
  brandsList: stat => stat.brandsList,
}
