import OrderService from "@/services/api/v1/OrderService"
import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"
import { message } from "ant-design-vue"
const service = new OrderService()

const orderClientEmpty = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
}

const orderDeliveryEmpty = {
  type: "delivery",
  postcode: "",
  country: "Россия",
  region: "",
  city: "",
  street: "",
  building: "",
  apartment: "",
  address: "",
}

const statusMethods = {
  2: { m: "setStatusInWork" },
  3: { m: "setStatusConfirmed" },
  4: { m: "setStatusCompleted" },
  5: { m: "setStatusPartiallyShip" },
  6: { m: "setStatusConsideration" },
  7: { m: "setStatusRefundConfirmed" },
  8: { m: "setStatusCorrection" },
  9: { m: "setStatusCanceledSupplier", needComment: true },
  10: { m: "setStatusCorrectionCompleted" },
  11: { m: "setStatusCanceledDealer", needComment: true },
  12: { m: "setStatusRefundCanceled" },
  13: { m: "setStatusEdited" },
  14: { m: "setStatusCanceledManufacturer" },
  15: { m: "setStatusUnderConsideration" },
  16: { m: "setStatusInStock" },
  17: { m: "setStatusInTransit" },
  18: { m: "setStatusShippedSupplier" },
  20: { m: "setStatusRefundCompleted" },
}

const orderPaymentEmpty = {
  type: "cash",
}

export const state = {
  orders: [],
  orderStatuses: [],
  currentStatusNumber: null,
  currentOrderStatuses: [],
  correctionId: null,
  orderCorrections: [],
  paginationOptions: {
    pageSize: 10,
    totalCount: 0,
    currentPage: 1,
  },
  order: {
    recipient: {
      ...orderClientEmpty,
    },
    delivery: {
      ...orderDeliveryEmpty,
    },
    payment: {
      ...orderPaymentEmpty,
    },
    basket: {},
  },
  currentOrder: null,
  deliveryTypes: [
    {
      name: "Доставка",
      key: "delivery",
    },
    {
      name: "Самовывоз",
      key: "shop",
    },
  ],
  paymentTypes: [
    {
      name: "Наличными курьеру",
      key: "cash",
    },
    {
      name: "Оплата по карте",
      key: "card",
    },
    {
      name: "Оплата по счету",
      key: "bill",
    },
  ],
  refundItems: [],
  correctionModalVisible: false,
  statusMethods: { ...statusMethods },
}

export const mutations = {
  SET_ORDER_FIELD(state, { index, key, value }) {
    state.orders[index][key] = value
  },
  SET_LIST(state, payload) {
    state.orders = payload
  },
  SET_CURRENT_ORDER(state, payload) {
    state.currentOrder = payload
  },
  SET_ORDER_STATUSES(state, payload) {
    state.orderStatuses = payload.map(function (item) {
      item["number"] = payload.indexOf(item) + 1
      return item
    })
  },
  SET_CURRENT_ORDER_STATUSES(state, payload) {
    state.currentOrderStatuses = payload
  },
  CLEAR_LIST(state) {
    state.orders = []
    state.paginationOptions.currentPage = 1
  },
  SET_CURRENT_PAGE(state, payload) {
    state.paginationOptions.currentPage = payload
  },
  SET_TOTAL_COUNT(state, payload) {
    state.paginationOptions.totalCount = payload
  },
  SET_ORDER_CLIENT_FIELD(state, { key, value }) {
    state.order.recipient[key] = value
  },
  SET_ORDER_DELIVERY_FIELD(state, { key, value }) {
    console.log(key, value)
    let delivery = { ...state.order.delivery }
    delivery[key] = value
    state.order = { ...state.order, delivery: delivery }
  },
  SET_ORDER_PAYMENT_FIELD(state, { key, value }) {
    state.order.payment[key] = value
  },
  SET_REFUND_ITEMS(state, payload) {
    state.refundItems = payload
  },
  SET_CORRECTION_MODAL_VISIBLE(state, payload) {
    state.correctionModalVisible = payload
  },
  SET_CORRECTION_ID(state, payload) {
    state.correctionId = payload
  },
  SET_ORDER_CORRECTIONS(state, payload) {
    state.orderCorrections = payload
  },
  SET_ORDER_BASKET(state, payload) {
    state.order.basket = payload
  },
  CLEAR_ORDER(state) {
    state.order.recipient = {
      ...orderClientEmpty,
    }
    state.order.delivery = {
      ...orderDeliveryEmpty,
    }
    state.order.payment = {
      ...orderPaymentEmpty,
    }
  },
}

export const actions = {
  async getOrders({ commit, getters }, savePage = false) {
    const paginationOptions = getters.paginationOptions

    let currentPage = paginationOptions.currentPage
    if (!savePage) {
      commit("SET_CURRENT_PAGE", 1)
      currentPage = 1
    }

    const response = await service.getOrders(currentPage, 5000)

    commit("SET_TOTAL_COUNT", response.data.data.length)
    commit("SET_LIST", response.data.data)
  },
  async getOrder({ commit }, uuid) {
    const response = await service.getOrder(uuid)
    commit("SET_CURRENT_ORDER", response.data.order)
    return response.data.order
  },
  async createOrder({ getters }) {
    return await service.createOrder({
      ...getters.order,
    })
  },

  async getOrderComments({ commit }, orderUuid) {
    const response = await service.getOrderComments(orderUuid)
    commit("SET_REFUND_ITEMS", response.data.data)
  },

  async saveOrderComment(_, { orderUuid, formData }) {
    await service.saveOrderComment(orderUuid, formData)
  },

  async getCurrentOrderStatuses({ commit }, orderId) {
    const response = await service.getCurrentOrderStatuses(orderId)
    commit("SET_CURRENT_ORDER_STATUSES", response.data.data)
  },

  async getOrderStatuses({ commit }) {
    const response = await service.getOrderStatuses()
    commit("SET_ORDER_STATUSES", response.data.data)
  },

  setStatusInWork(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(`${API_PREFIX}/order/${uuid}/to/in-work`, payload, {
        headers,
      })
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusConfirmed(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(`${API_PREFIX}/order/${uuid}/to/confirmed`, payload, {
        headers,
      })
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusCompleted(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(`${API_PREFIX}/order/${uuid}/to/completed`, payload, {
        headers,
      })
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusPartiallyShip(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(`${API_PREFIX}/order/${uuid}/to/partially-ship`, payload, {
        headers,
      })
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusConsideration(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(
        `${API_PREFIX}/order/${uuid}/to/consideration`,
        payload,
        { headers }
      )
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusRefundConfirmed(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(
        `${API_PREFIX}/order/${uuid}/to/refund-confirmed`,
        payload,
        { headers }
      )
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusCorrection(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(`${API_PREFIX}/order/${uuid}/to/correction`, payload, {
        headers,
      })
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusCanceledSupplier(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(
        `${API_PREFIX}/order/${uuid}/to/canceled-supplier`,
        payload,
        {
          headers,
        }
      )
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusCorrectionCompleted(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(
        `${API_PREFIX}/order/${uuid}/to/correction-completed`,
        payload,
        { headers }
      )
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusCanceledDealer(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(
        `${API_PREFIX}/order/${uuid}/to/canceled-dealer`,
        payload,
        {
          headers,
        }
      )
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusRefundCanceled(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(
        `${API_PREFIX}/order/${uuid}/to/refund-canceled`,
        payload,
        { headers }
      )
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusEdited(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(`${API_PREFIX}/order/${uuid}/to/edited`, payload, {
        headers,
      })
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusCanceledManufacturer(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(
        `${API_PREFIX}/order/${uuid}/to/canceled-manufacturer`,
        payload,
        {
          headers,
        }
      )
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusUnderConsideration(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(
        `${API_PREFIX}/order/${uuid}/to/under-consideration`,
        payload,
        {
          headers,
        }
      )
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusInStock(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(`${API_PREFIX}/order/${uuid}/to/in-stock`, payload, {
        headers,
      })
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusInTransit(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(`${API_PREFIX}/order/${uuid}/to/in-transit`, payload, {
        headers,
      })
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusShippedSupplier(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(
        `${API_PREFIX}/order/${uuid}/to/shipped-supplier`,
        payload,
        {
          headers,
        }
      )
    } catch (error) {
      message.error(error.message)
    }
  },

  setStatusRefundCompleted(_, payload) {
    const headers = { "Content-Type": "multipart/form-data" }
    let uuid = payload.get("orderUuid")
    try {
      return axios.post(
        `${API_PREFIX}/order/${uuid}/to/refund-completed`,
        payload,
        {
          headers,
        }
      )
    } catch (error) {
      message.error(error.message)
    }
  },

  storeCorrection(_, payload) {
    return axios.post(`${API_PREFIX}/order/${payload.uuid}/correction`, payload)
  },

  setOrderPaid(_, payload) {
    return axios.post(`${API_PREFIX}/order/${payload.uuid}/payed`, payload)
  },

  async getOrderCorrections({ commit }, orderUuid) {
    const response = await axios.get(
      `${API_PREFIX}/order/${orderUuid}/corrections`
    )
    commit("SET_ORDER_CORRECTIONS", response.data.data)
  },

  async verifyBasket({ getters }, payload) {
    const orderUuid = getters.currentOrder.uuid
    return await service.verifyBasket({
      orderUuid: orderUuid,
      products: payload,
    })
  },
}

export const getters = {
  orders: state => state.orders,
  orderStatuses: state => state.orderStatuses,
  currentOrderStatuses: state => state.currentOrderStatuses,
  paginationOptions: state => state.paginationOptions,
  order: state => state.order,
  currentOrder: state => state.currentOrder,
  deliveryTypes: state => state.deliveryTypes,
  paymentTypes: state => state.paymentTypes,
  statusButtonsMatrix: state => state.statusButtonsMatrix,
  refundItems: state => state.refundItems,
  correctionModalVisible: state => state.correctionModalVisible,
  correctionId: state => state.correctionId,
  orderCorrections: state => state.orderCorrections,
  statusMethods: state => state.statusMethods,
}
