import ChatService from "@/services/api/v1/ChatService"

const service = new ChatService()

export const state = {
  chats: [],
  currentChat: {
    name: "",
    messages: [],
  },
  paginationOptions: {
    pageSize: 10,
    totalCount: 0,
    currentPage: 1,
  },
}

export const getters = {
  chats: state => state.chats,
  currentChat: state => state.currentChat,
  paginationOptions: state => state.paginationOptions,
}

export const mutations = {
  SET_CHATS(state, payload) {
    state.chats = payload
  },
  SET_CURRENT_CHAT(state, payload) {
    state.currentChat = payload
  },
  CLEAR_CHATS(state) {
    state.chats = []
    state.paginationOptions.currentPage = 1
  },
  APPEND_CHAT(state, payload) {
    state.chats.splice(0, 0, payload)
  },
  APPEND_MESSAGE(state, payload) {
    state.currentChat.messages
      .filter(x => x.user_id !== payload.user_id)
      .forEach(msg => {
        msg.is_read = 1
      })
    state.currentChat.messages.push(payload)
  },
  SET_CURRENT_PAGE(state, payload) {
    state.paginationOptions.currentPage = payload
  },
  SET_TOTAL_COUNT(state, payload) {
    state.paginationOptions.totalCount = payload
  },
}

export const actions = {
  async getChats({ commit, getters }) {
    const paginationOptions = getters.paginationOptions

    const response = await service.getChats(
      paginationOptions.currentPage,
      paginationOptions.pageSize
    )

    commit("SET_CHATS", response.data.data)
    commit("SET_TOTAL_COUNT", response.data.meta.total)
  },
  async getChat({ commit }, id) {
    const response = await service.getChat(id)
    const chat = response.data.chat
    chat.messages = response.data.messages
    commit("SET_CURRENT_CHAT", chat)
  },
  async createChat({ commit }, chat) {
    const resp = await service.createChat(chat)
    commit("APPEND_CHAT", resp.data.chat)
  },
  async createMessage({ commit, getters }, chatMessage) {
    const response = await service.createMessage(
      getters.currentChat.id,
      chatMessage
    )
    const message = response.data.chat_message
    commit("APPEND_MESSAGE", message)
  },
}
