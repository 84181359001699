import axios from "axios"

// import { DummyApiService } from "@/services";
import UserService from "@/services/api/v1/UserService"
const service = new UserService()
export const permissions = {
  CAN_ADD_SUPPLIER_USER: "CAN_ADD_SUPPLIER_USER", // Добавление пользователей Поставщика
  CAN_ADD_MANUFACTURER: "CAN_ADD_MANUFACTURER", // Добавления дилеров
  CAN_ADD_MANUFACTURER_USER: "CAN_ADD_MANUFACTURER_USER", // Добавление пользователей Производителя
  CAN_ADD_DEALER: "CAN_ADD_DEALER", //Добавление Дилера
  CAN_ADD_DEALER_USER: "CAN_ADD_DEALER_USER", // Добавление пользователей Дилера
  CAN_WORKS_WITH_CONTRACTORS: "CAN_WORKS_WITH_CONTRACTORS", // Работа с контрагентами
  CAN_SEARCH_SPARE_PART: "CAN_SEARCH_SPARE_PART", // Поиск ЗЧ
  CAN_ORDER_SPARE_PART: "CAN_ORDER_SPARE_PART", // Заказ ЗЧ
  CAN_WORK_WITH_PRICES: "CAN_WORK_WITH_PRICES", // Прайсы
  CAN_WORK_WITH_PROMOTIONS: "CAN_WORK_WITH_PROMOTIONS", // Акции
  CAN_WORK_WITH_REPORTS: "CAN_WORK_WITH_REPORTS", // Отчеты
  CAN_WORK_WITH_LOYALTY: "CAN_WORK_WITH_LOYALTY", // Лояльность
  CAN_WORK_WITH_CREDIT_LIMIT: "CAN_WORK_WITH_CREDIT_LIMIT", // кредитный лимит
  CAN_ADD_ORDERS_UNDER_DC: "CAN_ADD_ORDERS_UNDER_DC",
}
export const roles = {
  ADMIN: "ADMIN", // Администратор Портала
  SUPPLIER_ADMIN: "SUPPLIER_ADMIN", // Администратор Поставщика
  SUPPLIER_OPERATOR: "SUPPLIER_OPERATOR", // Оператор Поставщика
  SUPPLIER_ANALYST: "SUPPLIER_ANALYST", // Аналитик Поставщика
  SUPPLIER_ORDER_OPERATOR: "SUPPLIER_ORDER_OPERATOR", // Оператор заказов Поставщика
  MANUFACTURER_ADMIN: "MANUFACTURER_ADMIN", // Администратор Производителя
  MANUFACTURER_OPERATOR: "MANUFACTURER_OPERATOR", // Оператор Производителя
  MANUFACTURER_ANALYST: "MANUFACTURER_ANALYST", // Аналитик Производителя
  MANUFACTURER_ORDER_OPERATOR: "MANUFACTURER_ORDER_OPERATOR", // Оператор заказов Производителя
  DEALER_ADMIN: "DEALER_ADMIN", // Администратор Дилера
  DEALER_OPERATOR: "DEALER_OPERATOR", // Оператор Дилера
  DEALER_ANALYST: "DEALER_ANALYST", // Аналитик Дилера
  DEALER_ORDER_OPERATOR: "DEALER_ORDER_OPERATOR", // Оператор заказов
  DEALER_OPERATOR_LOYALTY: "DEALER_OPERATOR_LOYALTY", // Оператор программы лояльности
}
export const dealerRoles = [
  roles.DEALER_ADMIN,
  roles.DEALER_ANALYST,
  roles.DEALER_OPERATOR,
  roles.DEALER_OPERATOR_LOYALTY,
  roles.DEALER_ORDER_OPERATOR,
]
export const manufacturerRoles = [
  roles.MANUFACTURER_ADMIN,
  roles.MANUFACTURER_OPERATOR,
  roles.MANUFACTURER_ORDER_OPERATOR,
  roles.MANUFACTURER_ANALYST,
]
export const supplierRoles = [
  roles.SUPPLIER_ADMIN,
  roles.SUPPLIER_ANALYST,
  roles.SUPPLIER_ORDER_OPERATOR,
  roles.SUPPLIER_OPERATOR,
]
export const state = {
  isPageLoading: false,
  authError: null,
  token: localStorage.getItem("token") ?? null,
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  rolesList: {
    ADMIN: "Администратор Портала",
    SUPPLIER_ADMIN: "Администратор Поставщика",
    SUPPLIER_OPERATOR: "Оператор Поставщика",
    SUPPLIER_ANALYST: "Аналитик Поставщика",
    SUPPLIER_ORDER_OPERATOR: "Оператор заказов Поставщика",
    MANUFACTURER_ADMIN: "Администратор Производителя",
    MANUFACTURER_OPERATOR: "Пользователь Производителя",
    MANUFACTURER_ANALYST: "Аналитик Производителя",
    MANUFACTURER_ORDER_OPERATOR: "Оператор заказов Производителя",
    DEALER_ADMIN: "Администратор Дилера",
    DEALER_OPERATOR: "Оператор Дилера",
    DEALER_ANALYST: "Аналитик Дилера",
    DEALER_ORDER_OPERATOR: "Оператор заказов Дилера",
    DEALER_OPERATOR_LOYALTY: "Оператор программы лояльности Дилера",
  },
  rolesNames: {
    ADMIN: "ADMIN",
    SUPPLIER_ADMIN: "SUPPLIER_ADMIN",
    SUPPLIER_OPERATOR: "SUPPLIER_OPERATOR",
    SUPPLIER_ANALYST: "SUPPLIER_ANALYST",
    SUPPLIER_ORDER_OPERATOR: "SUPPLIER_ORDER_OPERATOR",
    MANUFACTURER_ADMIN: "MANUFACTURER_ADMIN",
    MANUFACTURER_OPERATOR: "MANUFACTURER_OPERATOR",
    MANUFACTURER_ANALYST: "MANUFACTURER_ANALYST",
    MANUFACTURER_ORDER_OPERATOR: "MANUFACTURER_ORDER_OPERATOR",
    DEALER_ADMIN: "DEALER_ADMIN",
    DEALER_OPERATOR: "DEALER_OPERATOR",
    DEALER_ANALYST: "DEALER_ANALYST",
    DEALER_ORDER_OPERATOR: "DEALER_ORDER_OPERATOR",
    DEALER_OPERATOR_LOYALTY: "DEALER_OPERATOR_LOYALTY",
  },
  // Домашняя страница роли
  rolesHomeRoute: {
    ADMIN: "/",
    SUPPLIER_ADMIN: "/clients",
    SUPPLIER_OPERATOR: "/promotions",
    SUPPLIER_ANALYST: "/reports",
    SUPPLIER_ORDER_OPERATOR: "/clients",
    MANUFACTURER_ADMIN: "/orders",
    MANUFACTURER_OPERATOR: "/promotions",
    MANUFACTURER_ANALYST: "/reports",
    MANUFACTURER_ORDER_OPERATOR: "/orders",
    DEALER_ADMIN: "/",
    DEALER_OPERATOR: "/promotions",
    DEALER_ANALYST: "/reports",
    DEALER_ORDER_OPERATOR: "/promotions",
    DEALER_OPERATOR_LOYALTY: "/dealers",
  },
}

export const mutations = {
  SET_PAGE_LOADING(state, payload) {
    state.isPageLoading = payload
  },
  SET_AUTH_TOKEN(state, payload) {
    state.token = payload
  },
  SET_USER_INFO(state, payload) {
    state.user = payload
  },
  CLEAR_USER_INFO(state) {
    state.user = null
  },
  SET_AUTH_ERROR(state, payload) {
    state.authError = payload
  },
  CLEAR_AUTH_ERROR(state) {
    state.authError = null
  },
}

export const actions = {
  login({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_AUTH_ERROR")

      service
        .auth(payload.login, payload.password)
        .then(response => {
          const token = response.data.access_token
          commit("SET_AUTH_TOKEN", token)
          localStorage.setItem("token", token)
          axios.defaults.headers.common["Authorization"] = getters.bearerToken
          dispatch("getUser").then(() => resolve(token))
        })
        .catch(error => {
          console.log(error)
          if (error.response) {
            commit("SET_AUTH_ERROR", error.response.data.message)
          }
          reject(error.response)
        })
    })
  },
  passwordRecovery(_, payload) {
    return new Promise((resolve, reject) => {
      service
        .passwordRecovery(payload.email)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error.response)
        })
    })
  },
  getUser({ commit }) {
    return new Promise((resolve, reject) => {
      service
        .getCurrentUser()
        .then(response => {
          const user = response.data.data
          commit("SET_USER_INFO", user)
          localStorage.setItem("user", JSON.stringify(user))
          resolve(user)
        })
        .catch(error => {
          console.log(error)
          if (error.response) {
            commit("SET_AUTH_ERROR", error.response.data.message)
          }
          reject(error.response)
        })
    })
  },
  logout({ commit }) {
    commit("CLEAR_USER_INFO")
    axios.defaults.headers.common["Authorization"] = null
    localStorage.removeItem("user")
    localStorage.removeItem("token")
  },
  async createUser(_, userInfo) {
    return service.setNewUser(userInfo)
  },
  async createDealerUser(_, userInfo) {
    return new Promise((resolve, reject) => {
      service
        .setNewDealerUser(userInfo)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
}

export const getters = {
  dcClientCodes: state =>
    state.user?.dc_clients ? state.user?.dc_clients : [],
  isPageLoading: state => state.isPageLoading,
  authError: state => state.authError,
  isLoggedIn: state => state.token,
  rolesNames: state => state.rolesNames,
  bearerToken: state => (state.token ? `Bearer ${state.token}` : ""),
  user: state => state.user,
  userRole: state => (state.user && state.token ? state.user.roles[0] : null),
  userDealerId: state =>
    state.user && state.token ? state.user.roles[0] : null,
  roles: state => (state.user && state.token ? state.user.roles : []),
  rolesNamesList: state =>
    state.user && state.token
      ? state.user.roles.map(role => state.rolesNames[role])
      : [],
  isBuyerForDealers: state =>
    state.user?.permissions
      ? state.user?.permissions?.includes(permissions.CAN_ADD_ORDERS_UNDER_DC)
      : false,
  isBuyer: state =>
    state.user?.roles
      ? state.user?.roles?.some(el => dealerRoles.includes(el))
      : false,
  isDealer: state =>
    state.user?.roles
      ? state.user?.roles?.some(el => manufacturerRoles.includes(el))
      : false,
  isManufacturer: state =>
    state.user?.roles
      ? state.user?.roles?.some(el => manufacturerRoles.includes(el))
      : false,
  isManufacturerAdmin: state =>
    state.user?.roles
      ? state.user?.roles?.some(el => el === roles.MANUFACTURER_ADMIN)
      : false,
  isManufacturerOperator: state =>
    state.user?.roles
      ? state.user?.roles?.some(el => el === roles.MANUFACTURER_OPERATOR)
      : false,
  isOwner: state =>
    state.user?.roles
      ? state.user?.roles?.some(el => supplierRoles.includes(el))
      : false,
  isSupplier: state =>
    state.user?.roles
      ? state.user?.roles?.some(el => supplierRoles.includes(el))
      : false,
  isAdmin: state =>
    state.user?.roles ? state.user?.roles?.includes(roles.ADMIN) : false,
  role: state =>
    state.user?.roles ? state.rolesList[state.user.roles[0]] : null,
  rolesHomeRoute: state => state.rolesHomeRoute,
  rolesListForCreateUser: state =>
    Object.entries(state.rolesList)
      .map(r => ({ id: r[0], name: r[1] }))
      .filter(x =>
        [
          "SUPPLIER_ADMIN",
          "SUPPLIER_OPERATOR",
          "MANUFACTURER_ADMIN",
          "MANUFACTURER_OPERATOR",
          "DEALER_ADMIN",
          "DEALER_OPERATOR",
        ].includes(x.id)
      ),
}

export function hasPermisson(permission) {
  return (
    state.user &&
    state.user.permissons &&
    state.user.permissons.find(permission)
  )
}
export function hasRole(role) {
  return state.user && state.user.roles && state.user.roles.includes(role)
}
