import { DummyApiService } from "@/services"
const service = new DummyApiService()

export const state = {
  filters: {
    current: {
      isLineShow: "yes",
      isSumShow: "no",
    },
    temp: {
      isLineShow: "yes",
      isSumShow: "no",
    },
    default: {
      isLineShow: "yes",
      isSumShow: "no",
    },
  },
  line: {
    columns: [
      {
        title: "",
        dataIndex: "lines",
        key: "lines",
      },
      {
        title: "Кол-во подключенных ЮЛ",
        key: "connectedUlAmount",
        dataIndex: "connectedUlAmount",
      },
      {
        title: "Кол-во активных ЮЛ (были заказы за период)",
        dataIndex: "activeUlAmount",
        key: "activeUlAmount",
      },
      {
        title: "Количество заказанных линий, всего",
        dataIndex: "orderedLinesAmount",
        key: "orderedLinesAmount",
      },
      {
        title: "Количество отказанных линий, всего",
        dataIndex: "declinedLinesAmount",
        key: "declinedLinesAmount",
      },
      {
        title: "Количество подтвержденных линий, всего",
        dataIndex: "approvedLinesAmount",
        key: "approvedLinesAmount",
      },
      {
        title: "Количество линий без статуса, всего",
        dataIndex: "statuslessLinesAmount",
        key: "statuslessLinesAmount",
      },
      {
        title: "Коэффициент отказов",
        dataIndex: "declineKef",
        key: "declineKef",
      },
      {
        title: "Коэффициент подтверждений",
        dataIndex: "approveKef",
        key: "approveKef",
      },
      {
        title: "Количество отгруженных линий",
        dataIndex: "departedLinesAmount",
        key: "departedLinesAmount",
      },
      {
        title: "Процент заказов из наличия от общего",
        dataIndex: "nalOrderPercent",
        key: "nalOrderPercent",
      },
      {
        title: "Процент заказов ЦС срочный от общего",
        dataIndex: "srochCSOrderPercent",
        key: "srochCSOrderPercent",
      },
      {
        title: "Процент заказов ЦС складской от общего",
        dataIndex: "skladCSOrderPercent",
        key: "skladCSOrderPercent",
      },
      {
        title: "Процент заказов ЦС под заказ от общего",
        dataIndex: "orderedCSOrderPercent",
        key: "orderedCSOrderPercent",
      },
      {
        title: "Процент заказов прочие склады от общего",
        dataIndex: "otherOrderPercent",
        key: "otherOrderPercent",
      },
    ],
    data: [],
  },
  sum: {
    columns: [
      {
        title: "СУММЫ",
        dataIndex: "lines",
        key: "lines",
      },
      {
        title: "Количество подключенных ЮЛ",
        key: "connectedUlAmount",
        dataIndex: "connectedUlAmount",
      },
      {
        title: "Количество активных ЮЛ (Были заказы за период)",
        dataIndex: "activeUlAmount",
        key: "activeUlAmount",
      },
      {
        title: "Сумма заказов, всего",
        dataIndex: "ordersSum",
        key: "ordersAmount",
      },
      {
        title: "Сумма отказанных позиций, всего",
        dataIndex: "declinedSum",
        key: "declinedSum",
      },
      {
        title: "Сумма подтвержд. позиций, всего",
        dataIndex: "approvedSum",
        key: "approvedSum",
      },
      {
        title: "Сумма позиций без статуса, всего",
        dataIndex: "statuslessSum",
        key: "statuslessSum",
      },
      {
        title: "Коэффициент отказов",
        dataIndex: "declineKef",
        key: "declineKef",
      },
      {
        title: "Коэффициент подтверждений",
        dataIndex: "approveKef",
        key: "approveKef",
      },
      {
        title: "Сумма отгруженных позиций",
        dataIndex: "departedSum",
        key: "departedSum",
      },
      {
        title: "Процент заказов из наличия от общего",
        dataIndex: "nalOrderPercent",
        key: "nalOrderPercent",
      },
      {
        title: "Процент заказов ЦС срочный от общего",
        dataIndex: "srochCSOrderPercent",
        key: "srochCSOrderPercent",
      },
      {
        title: "Процент заказов ЦС складской от общего",
        dataIndex: "skladCSOrderPercent",
        key: "skladCSOrderPercent",
      },
      {
        title: "Процент заказов ЦС под заказ от общего",
        dataIndex: "orderedCSOrderPercent",
        key: "orderedCSOrderPercent",
      },
      {
        title: "Процент заказов прочие склады от общего",
        dataIndex: "otherOrderPercent",
        key: "otherOrderPercent",
      },
    ],
    data: [],
  },
}

export const mutations = {
  FILTER: state =>
    (state.filters.current = {
      ...state.filters.temp,
    }),
  CLEAR_FILTERS: state => {
    state.filters.current = {
      ...state.filters.default,
    }
    state.filters.temp = {
      ...state.filters.default,
    }
  },
  UPDATE_FILTER_IS_LINE_SHOW: (state, payload) =>
    (state.filters.temp.isLineShow = payload),
  UPDATE_FILTER_IS_SUM_SHOW: (state, payload) =>
    (state.filters.temp.isSumShow = payload),
  SET_LINE_DATA: (state, payload) => (state.line.data = payload),
  SET_SUM_DATA: (state, payload) => (state.sum.data = payload),
}

export const actions = {
  fetchLineData: async ({ commit }) => {
    const list = await service.getReportDynamicLinesData()
    commit("SET_LINE_DATA", list)
  },
  fetchSumData: async ({ commit }) => {
    const list = await service.getReportDynamicSumData()
    commit("SET_SUM_DATA", list)
  },
}

export const getters = {
  isLineShow: state => state.filters.current.isLineShow === "yes",
  isTempLineShow: state => state.filters.temp.isLineShow === "yes",
  isSumShow: state => state.filters.current.isSumShow === "yes",
  isTempSumShow: state => state.filters.temp.isSumShow === "yes",

  lineColumns: state => state.line.columns,
  lineData: state => state.line.data,

  sumColumns: state => state.sum.columns,
  sumData: state => state.sum.data,
}
