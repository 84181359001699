import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"

export default class PromotionService {
  async createPromotion(data) {
    return (await axios.post(`${API_PREFIX}/promotions`, data)).data
  }
  async updatePromotion(id, data) {
    return (
      await axios.post(`${API_PREFIX}/promotions/${id}?_method=patch`, data)
    ).data
  }
  async deletePromotion(id) {
    return axios.delete(`${API_PREFIX}/promotions/${id}`)
  }
  async fetchPromotion(id) {
    return (await axios.get(`${API_PREFIX}/promotions/${id}`)).data.data
  }
  async getPromotions() {
    return (await axios.get(`${API_PREFIX}/promotions`)).data.data
  }
}
