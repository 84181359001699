export default [
  {
    key: 1,
    dealer: "АВТОГРАД ПРЕСТИЖ",
    connectedUlAmount: "14",
    activeUlAmount: "8",
    orderedLinesAmount: "560849",
    declinedLinesAmount: "21359",
    approvedLinesAmount: "528320",
    statuslessLinesAmount: "11170",
    declineKef: "0,04",
    approveKef: "0,94",
    departedLinesAmount: "678017",
    nalOrderPercent: "0,48",
    srochCSOrderPercent: "0,42",
    skladCSOrderPercent: "0,04",
    orderedCSOrderPercent: "0,02",
    otherOrderPercent: "0,04",
    address: "Республики 280, Тюмень, Ханты-Мансийский, 625014",
  },
  {
    key: 2,
    dealer: "БОВИД АВТО",
    connectedUlAmount: "25",
    activeUlAmount: "18",
    orderedLinesAmount: "318140",
    declinedLinesAmount: "22641",
    approvedLinesAmount: "295499",
    statuslessLinesAmount: "0",
    declineKef: "0,07",
    approveKef: "0,93",
    departedLinesAmount: "567800",
    nalOrderPercent: "0,33",
    srochCSOrderPercent: "0,11",
    skladCSOrderPercent: "0,27",
    orderedCSOrderPercent: "0,15",
    otherOrderPercent: "0,13",
    address: "Ленина проспект 26А стр.2, Челябинск, Челябинская, 454007",
  },
  {
    key: 3,
    dealer: "АВТОГРАД ПЛЮС",
    connectedUlAmount: "31",
    activeUlAmount: "21",
    orderedLinesAmount: "952761",
    declinedLinesAmount: "107458",
    approvedLinesAmount: "798692",
    statuslessLinesAmount: "46611",
    declineKef: "0,11",
    approveKef: "0,84",
    departedLinesAmount: "1025478",
    nalOrderPercent: "0,46",
    srochCSOrderPercent: "0,29",
    skladCSOrderPercent: "0,14",
    orderedCSOrderPercent: "0,04",
    otherOrderPercent: "0,07",
    address: "Ленинский проспект, 156, Воронеж, Воронежская обл., 394042",
  },
  {
    key: 4,
    dealer: "ПЛАНЕТА АВТО",
    connectedUlAmount: "6",
    activeUlAmount: "6",
    orderedLinesAmount: "232345",
    declinedLinesAmount: "11741",
    approvedLinesAmount: "207764",
    statuslessLinesAmount: "12840",
    declineKef: "0,05",
    approveKef: "0,89",
    departedLinesAmount: "345007",
    nalOrderPercent: "0,49",
    srochCSOrderPercent: "0,28",
    skladCSOrderPercent: "0,18",
    orderedCSOrderPercent: "0,06",
    otherOrderPercent: "0,00",
    address: "Братьев Кашириных 143, Челябинск, Челябинская обл, 454128",
  },
  {
    key: 5,
    dealer: "АРКОНТ",
    connectedUlAmount: "12",
    activeUlAmount: "9",
    orderedLinesAmount: "239777",
    declinedLinesAmount: "3572",
    approvedLinesAmount: "216470",
    statuslessLinesAmount: "19735",
    declineKef: "0,01",
    approveKef: "0,90",
    departedLinesAmount: "289401",
    nalOrderPercent: "0,43",
    srochCSOrderPercent: "0,37",
    skladCSOrderPercent: "0,01",
    orderedCSOrderPercent: "0,03",
    otherOrderPercent: "0,16",
    address: "ул. Землячки 25, Волгоград, 400117",
  },
]
