/**
 * MANUFACTURER_OPERATOR
 */

export default (() => {
  return {
    MANUFACTURER_OPERATOR: {
      defaultSelectedId: ["Orders"],
      items: [
        {
          id: "Orders",
          name: "Заказы",
          icon: "shopping-cart",
          path: "/orders",
        },
        {
          id: "SearchDetails",
          name: "Каталог",
          icon: "monitor",
          children: {
            items: [
              {
                id: "SearchDetails",
                name: "Каталог",
                path: "/",
              },
            ],
          },
        },
        {
          id: "Clients",
          name: "Дилеры",
          icon: "team",
          roles: ["Dealer"],
          children: {
            items: [
              {
                id: "Clients",
                name: "Список дилеров",
                path: "/clients",
              },
            ],
          },
        },
        {
          id: "Reports",
          name: "Отчеты",
          icon: "bar-chart",
          path: "/reports",
        },
        {
          id: "News",
          name: "Новости",
          icon: "exclamation-circle",
          path: "/news",
        },
      ],
    },
  }
})()
