import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"

export default class UserService {
  async auth(login, password) {
    let name = login
    return axios.post(`${API_PREFIX}/login`, {
      email: name,
      password,
    })
  }

  async passwordRecovery(login) {
    return axios.post(`${API_PREFIX}/password-recovery`, {
      email: login,
    })
  }

  async setNewUser(userInfo) {
    return axios.post(`${API_PREFIX}/client/user`, userInfo)
  }

  async setNewDealerUser(userInfo) {
    return axios.post(`${API_PREFIX}/dealer/user`, userInfo.user)
  }

  async getCurrentUser() {
    return axios.get(`${API_PREFIX}/me`)
  }

  async getUserDealerOrdersPermissionsData(id) {
    return axios.get(`${API_PREFIX}/users/${id}/orderpermissions`)
  }
  async setUserDealerOrdersPermissionsData(id, data) {
    return axios.post(`${API_PREFIX}/users/${id}/orderpermissions`, data)
  }
}
