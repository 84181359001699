/**
 * MANUFACTURER_ORDER_OPERATOR
 */

export default (() => {
  return {
    MANUFACTURER_ORDER_OPERATOR: {
      defaultSelectedId: ["Orders"],
      items: [
        {
          id: "Orders",
          name: "Заказы",
          icon: "shopping-cart",
          path: "/orders",
        },
        {
          id: "SearchDetails",
          name: "Поиск",
          icon: "monitor",
          path: "/",
        },
        {
          id: "8",
          icon: "exclamation-circle",
          name: "Производители",
          path: "/dealers",
        },
        {
          id: "Clients",
          name: "Дилеры",
          icon: "team",
          roles: ["Dealer"],
          children: {
            items: [
              {
                id: "Clients",
                name: "Список дилеров",
                path: "/clients",
              },
              {
                id: "CheckClientExist",
                name: "Регистрация нового дилера",
                path: "/checkclient",
              },
              // {
              //   id: "Markups",
              //   name: "Стандартные наценки",
              //   path: "/markups",
              // },
            ],
          },
        },
        /*{
          id: "Delivery",
          name: "Доставка",
          icon: "exclamation-circle",
          path: "/delivery",
        },*/
        {
          id: "News",
          name: "Новости",
          icon: "exclamation-circle",
          path: "/news",
        },
      ],
    },
  }
})()
