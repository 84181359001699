export default [
  {
    id: "1",
    username: "Buyer",
    roles: ["Buyer"],
  },
  {
    id: "2",
    username: "Dealer",
    roles: ["Dealer"],
  },
  {
    id: "3",
    username: "Owner",
    roles: ["Owner"],
  },
  {
    id: "4",
    username: "Admin",
    roles: ["Admin"],
  },
]
