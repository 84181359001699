<template>
  <div class="footer">
    <p>
      <!--      © JAGUAR LAND ROVER LIMITED 2022: Registered office: Abbey Road, Whitley,-->
      <!--      Coventry CV3 4LF. Registered in England No: 1672070-->
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.footer {
  text-align: center;
  font-family: $fontf-main;
  font-weight: 300;
  font-size: small;
}
</style>
