/**
 * SUPPLIER_OPERATOR
 */

export default (() => {
  return {
    SUPPLIER_OPERATOR: {
      defaultSelectedId: ["Promotions"],
      items: [
        // {
        //   id: "Promotions",
        //   name: "Акции",
        //   icon: "percentage",
        //   path: "/promotions",
        // },
        {
          id: "Prices",
          name: "Наличие",
          icon: "container",
          path: "/prices",
        },
        {
          id: "News",
          name: "Новости",
          icon: "exclamation-circle",
          path: "/news",
        },
      ],
    },
  }
})()
