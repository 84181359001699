import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"

export default class ProductService {
  async auth(login, password) {
    return axios.post(`${API_PREFIX}/login`, {
      login,
      password,
    })
  }

  async search(per_page, page, code) {
    const options = {
      per_page,
      page,
    }

    if (code) {
      options.code = code
    }

    return axios.get(
      `${API_PREFIX}/product?${new URLSearchParams(options).toString()}`
    )
  }

  async save(data) {
    return axios.put(`${API_PREFIX}/product/${data.id}`, data)
  }
}
