/**
 * SUPPLIER_ADMIN
 */

export default (() => {
  return {
    SUPPLIER_ADMIN: {
      defaultSelectedId: ["Clients"],
      items: [
        {
          id: "Clients",
          icon: "exclamation-circle",
          name: "Дилеры",
          path: "/clients",
        },
        {
          id: "Orders",
          icon: "shopping-cart",
          name: "Заказы",
          path: "/orders",
        },
        {
          id: "SearchDetails",
          name: "Каталог",
          icon: "monitor",
          path: "/",
        },
        {
          id: "Reports",
          name: "Отчеты",
          icon: "bar-chart",
          path: "/reports",
        },
        {
          id: "Prices",
          name: "Наличие",
          icon: "container",
          path: "/prices",
        },
        {
          id: "Chats",
          name: "Чаты",
          icon: "wechat",
          path: "/chats",
        },
        // {
        //   id: "Loyalty",
        //   name: "Программа лояльности",
        //   icon: "percentage",
        //   roles: ["Dealer"],
        //   children: {
        //     items: [
        //       {
        //         id: "LoyaltyLevels",
        //         name: "Градации",
        //         path: "/loyalty-levels",
        //       },
        //     ],
        //   },
        // },
        {
          id: "News",
          name: "Новости",
          icon: "exclamation-circle",
          path: "/news",
        },
      ],
    },
  }
})()
