import { DummyApiService } from "@/services"
const service = new DummyApiService()

export const state = {
  filters: {
    current: {
      isLineShow: "yes",
      isSumShow: "no",
    },
    temp: {
      isLineShow: "yes",
      isSumShow: "no",
    },
    default: {
      isLineShow: "yes",
      isSumShow: "no",
    },
  },
  line: {
    firstTable: {
      columns: [
        {
          title: "ЛИНИИ",
          dataIndex: "lines",
          key: "lines",
        },
        {
          title: "Кол-во подключенных ЮЛ",
          key: "connectedUlAmount",
          dataIndex: "connectedUlAmount",
        },
        {
          title: "Кол-во активных ЮЛ (были заказы за период)",
          dataIndex: "activeUlAmount",
          key: "activeUlAmount",
        },
        {
          title: "Количество заказанных линий, всего",
          dataIndex: "orderedLinesAmount",
          key: "orderedLinesAmount",
        },
        {
          title: "Количество отказанных линий, всего",
          dataIndex: "declinedLinesAmount",
          key: "declinedLinesAmount",
        },
        {
          title: "Количество подтвержденных линий, всего",
          dataIndex: "approvedLinesAmount",
          key: "approvedLinesAmount",
        },
        {
          title: "Количество линий без статуса, всего",
          dataIndex: "statuslessLinesAmount",
          key: "statuslessLinesAmount",
        },
        {
          title: "Коэффициент отказов",
          dataIndex: "declineKef",
          key: "declineKef",
        },
        {
          title: "Коэффициент подтверждений",
          dataIndex: "approveKef",
          key: "approveKef",
        },
        {
          title: "Количество отгруженных линий",
          dataIndex: "departedLinesAmount",
          key: "departedLinesAmount",
        },
        {
          title: "Процент заказов из наличия от общего",
          dataIndex: "nalOrderPercent",
          key: "nalOrderPercent",
        },
        {
          title: "Процент заказов ЦС срочный от общего",
          dataIndex: "srochCSOrderPercent",
          key: "srochCSOrderPercent",
        },
        {
          title: "Процент заказов ЦС складской от общего",
          dataIndex: "skladCSOrderPercent",
          key: "skladCSOrderPercent",
        },
        {
          title: "Процент заказов ЦС под заказ от общего",
          dataIndex: "orderedCSOrderPercent",
          key: "orderedCSOrderPercent",
        },
        {
          title: "Процент заказов прочие склады от общего",
          dataIndex: "otherOrderPercent",
          key: "otherOrderPercent",
        },
        {
          title: "Адрес",
          dataIndex: "address",
          key: "address",
        },
      ],
      data: [],
      hidden: false,
    },
    secondTable: {
      columns: [
        {
          title: "АВТОГРАД ПЛЮС",
          key: "linesData",
          dataIndex: "linesData",
        },
        {
          title: "По всем заказам",
          key: "byAllOrders",
          dataIndex: "byAllOrders",
        },
        {
          title: "Склад Наличия",
          key: "presenceStore",
          dataIndex: "presenceStore",
        },
        {
          title: "ЦС срочный заказ",
          key: "srochCS",
          dataIndex: "srochCS",
        },
        {
          title: "ЦС складской заказ",
          key: "skladCS",
          dataIndex: "skladCS",
        },
        {
          title: "ЦС под заказ",
          key: "orderCS",
          dataIndex: "orderCS",
        },
        {
          title: "Склады других производителей",
          key: "otherDealers",
          dataIndex: "otherDealers",
        },
        {
          title: "Прочие склады",
          key: "other",
          dataIndex: "other",
        },
      ],
      data: [],
      hidden: false,
    },
  },
  sum: {
    firstTable: {
      columns: [
        {
          title: "",
          dataIndex: "dealer",
          key: "dealer",
        },
        {
          title: "Количество подключенных ЮЛ",
          key: "connectedUlAmount",
          dataIndex: "connectedUlAmount",
        },
        {
          title: "Количество активных ЮЛ (Были заказы за период)",
          dataIndex: "activeUlAmount",
          key: "activeUlAmount",
        },
        {
          title: "Сумма заказов, всего",
          dataIndex: "ordersSum",
          key: "ordersAmount",
        },
        {
          title: "Сумма отказанных позиций, всего",
          dataIndex: "declinedSum",
          key: "declinedSum",
        },
        {
          title: "Сумма подтвержд. позиций, всего",
          dataIndex: "approvedSum",
          key: "approvedSum",
        },
        {
          title: "Сумма позиций без статуса, всего",
          dataIndex: "statuslessSum",
          key: "statuslessSum",
        },
        {
          title: "Коэффициент отказов",
          dataIndex: "declineKef",
          key: "declineKef",
        },
        {
          title: "Коэффициент подтверждений",
          dataIndex: "approveKef",
          key: "approveKef",
        },
        {
          title: "Сумма отгруженных позиций",
          dataIndex: "departedSum",
          key: "departedSum",
        },
        {
          title: "Процент заказов из наличия от общего",
          dataIndex: "nalOrderPercent",
          key: "nalOrderPercent",
        },
        {
          title: "Процент заказов ЦС срочный от общего",
          dataIndex: "srochCSOrderPercent",
          key: "srochCSOrderPercent",
        },
        {
          title: "Процент заказов ЦС складской от общего",
          dataIndex: "skladCSOrderPercent",
          key: "skladCSOrderPercent",
        },
        {
          title: "Процент заказов ЦС под заказ от общего",
          dataIndex: "orderedCSOrderPercent",
          key: "orderedCSOrderPercent",
        },
        {
          title: "Процент заказов прочие склады от общего",
          dataIndex: "otherOrderPercent",
          key: "otherOrderPercent",
        },
        {
          title: "Адрес",
          dataIndex: "address",
          key: "address",
          width: 200,
        },
      ],
      data: [],
      hidden: false,
    },
    secondTable: {
      columns: [
        {
          title: "АВТОГРАД ПЛЮС",
          key: "sumData",
          dataIndex: "sumData",
        },
        {
          title: "По всем заказам",
          key: "byAllOrders",
          dataIndex: "byAllOrders",
        },
        {
          title: "Склад Наличия",
          key: "presenceStore",
          dataIndex: "presenceStore",
        },
        {
          title: "ЦС срочный заказ",
          key: "srochCS",
          dataIndex: "srochCS",
        },
        {
          title: "ЦС складской заказ",
          key: "skladCS",
          dataIndex: "skladCS",
        },
        {
          title: "ЦС под заказ",
          key: "orderCS",
          dataIndex: "orderCS",
        },
        {
          title: "Склады других производителей",
          key: "otherDealers",
          dataIndex: "otherDealers",
        },
        {
          title: "Прочие склады",
          key: "other",
          dataIndex: "other",
        },
      ],
      data: [],
      hidden: false,
    },
  },
}

export const mutations = {
  FILTER: state =>
    (state.filters.current = {
      ...state.filters.temp,
    }),
  CLEAR_FILTERS: state => {
    state.filters.current = {
      ...state.filters.default,
    }
    state.filters.temp = {
      ...state.filters.default,
    }
  },
  UPDATE_FILTER_IS_LINE_SHOW: (state, payload) =>
    (state.filters.temp.isLineShow = payload),
  UPDATE_FILTER_IS_SUM_SHOW: (state, payload) =>
    (state.filters.temp.isSumShow = payload),
  SET_LINE_FIRST_TABLE_DATA: (state, payload) =>
    (state.line.firstTable.data = payload),
  SET_LINE_SECOND_TABLE_DATA: (state, payload) =>
    (state.line.secondTable.data = payload),
  TOGGLE_LINE_FIRST_TABLE_VISIBILITY: state =>
    (state.line.firstTable.hidden = !state.line.firstTable.hidden),
  TOGGLE_LINE_SECOND_TABLE_VISIBILITY: state =>
    (state.line.secondTable.hidden = !state.line.secondTable.hidden),
  SET_SUM_FIRST_TABLE_DATA: (state, payload) =>
    (state.sum.firstTable.data = payload),
  SET_SUM_SECOND_TABLE_DATA: (state, payload) =>
    (state.sum.secondTable.data = payload),
  TOGGLE_SUM_FIRST_TABLE_VISIBILITY: state =>
    (state.sum.firstTable.hidden = !state.sum.firstTable.hidden),
  TOGGLE_SUM_SECOND_TABLE_VISIBILITY: state =>
    (state.sum.secondTable.hidden = !state.sum.secondTable.hidden),
}

export const actions = {
  fetchLineFirstTableData: async ({ commit }) => {
    const list = await service.getReportPeriodLinesFirstData()
    commit("SET_LINE_FIRST_TABLE_DATA", list)
  },
  fetchLineSecondTableData: async ({ commit }) => {
    const list = await service.getReportPeriodLinesSecondData()
    commit("SET_LINE_SECOND_TABLE_DATA", list)
  },
  fetchSumFirstTableData: async ({ commit }) => {
    const list = await service.getReportPeriodSumFirstData()
    commit("SET_SUM_FIRST_TABLE_DATA", list)
  },
  fetchSumSecondTableData: async ({ commit }) => {
    const list = await service.getReportPeriodSumSecondData()
    commit("SET_SUM_SECOND_TABLE_DATA", list)
  },
}

export const getters = {
  isLineShow: state => state.filters.current.isLineShow === "yes",
  isTempLineShow: state => state.filters.temp.isLineShow === "yes",
  isSumShow: state => state.filters.current.isSumShow === "yes",
  isTempSumShow: state => state.filters.temp.isSumShow === "yes",

  lineFirstTableColumns: state => state.line.firstTable.columns,
  lineFirstTableData: state => state.line.firstTable.data,
  isHiddenLineFirstTable: state => state.line.firstTable.hidden,
  lineSecondTableColumns: state => state.line.secondTable.columns,
  lineSecondTableData: state => state.line.secondTable.data,
  isHiddenLineSecondTable: state => state.line.secondTable.hidden,

  sumFirstTableColumns: state => state.sum.firstTable.columns,
  sumFirstTableData: state => state.sum.firstTable.data,
  isHiddenSumFirstTable: state => state.sum.firstTable.hidden,
  sumSecondTableColumns: state => state.sum.secondTable.columns,
  sumSecondTableData: state => state.sum.secondTable.data,
  isHiddenSumSecondTable: state => state.sum.secondTable.hidden,
}
