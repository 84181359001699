import axios from "axios"
import { message } from "ant-design-vue"
import { API_PREFIX } from "@/services/api/v1/const"

export default class LoyaltyLevelsService {
  async getLoyaltyLevels() {
    try {
      return await axios.get(`${API_PREFIX}/loyalty-levels`)
    } catch (error) {
      if (error.response) message.error(error.response.data.message)
    }
  }
  async getSingleLoyaltyLevel(payload) {
    try {
      return await axios.get(`${API_PREFIX}/loyalty-levels/${payload.level}`)
    } catch (error) {
      if (error.response) message.error(error.response.data.message)
    }
  }
  async updateSingleLoyaltyLevel(payload) {
    try {
      return await axios.put(`${API_PREFIX}/loyalty-levels/${payload.level}`, {
        ...payload,
      })
    } catch (error) {
      if (error.response) message.error(error.response.data.message)
    }
  }
}
