export default [
  {
    key: 1,
    dealer: "АВТОГРАД ПРЕСТИЖ",
    january: 83,
    february: 97,
    march: 124,
    april: 42,
    may: 12,
    june: 16,
    july: 121,
    august: 89,
    september: 92,
    october: 79,
    november: 75,
  },
  {
    key: 2,
    dealer: "БОВИД АВТО",
    january: 156,
    february: 123,
    march: 212,
    april: 89,
    may: 53,
    june: 78,
    july: 139,
    august: 148,
    september: 175,
    october: 142,
    november: 127,
  },
  {
    key: 3,
    dealer: "АВТОГРАД ПЛЮС",
    january: 175,
    february: 213,
    march: 389,
    april: 178,
    may: 52,
    june: 89,
    july: 198,
    august: 269,
    september: 387,
    october: 420,
    november: 317,
  },
  {
    key: 4,
    dealer: "ПЛАНЕТА АВТО",
    january: 23,
    february: 36,
    march: 47,
    april: 29,
    may: 35,
    june: 30,
    july: 46,
    august: 53,
    september: 49,
    october: 25,
    november: 39,
  },
  {
    key: 5,
    dealer: "АРКОНТ",
    january: 15,
    february: 23,
    march: 36,
    april: 24,
    may: 18,
    june: 39,
    july: 48,
    august: 63,
    september: 78,
    october: 89,
    november: 92,
  },
]
