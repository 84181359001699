import detailsList from "./data/details"
import clientsList from "./data/clients"
import pricesList from "./data/prices"
import usersList from "./data/users"
import dealersList from "./data/dealers"
import promotionsList from "./data/promotions"
import csPricesList from "./data/cs-prices"
import csPricesListPreview from "./data/cs-prices-preview"
import csDirectoriesList from "./data/cs-directories"
import brandsList from "./data/brands"
import reportPeriodLinesFirstTableList from "./data/reportPeriodLinesFirst"
import reportPeriodLinesSecondTableList from "./data/reportPeriodLinesSecond"
import reportPeriodSumFirstTableList from "./data/reportPeriodSumFirst"
import reportPeriodSumSecondTableList from "./data/reportPeriodSumSecond"
import reportDynamicLinesList from "./data/reportDynamicLine"
import reportDynamicSumList from "./data/reportDynamicSum"

import reportPeriodGroupLineMainList from "./data/period-group/line/main"
import reportPeriodGroupLineOtherFirstList from "./data/period-group/line/other/first"
import reportPeriodGroupLineOtherSecondList from "./data/period-group/line/other/second"
import reportPeriodGroupLineOtherThirdList from "./data/period-group/line/other/third"
import reportPeriodGroupLineOtherFourthList from "./data/period-group/line/other/fourth"

import reportPeriodGroupSumMainList from "./data/period-group/sum/main"
import reportPeriodGroupSumOtherFirstList from "./data/period-group/sum/other/first"
import reportPeriodGroupSumOtherSecondList from "./data/period-group/sum/other/second"
import reportPeriodGroupSumOtherThirdList from "./data/period-group/sum/other/third"
import reportPeriodGroupSumOtherFourthList from "./data/period-group/sum/other/fourth"

import reportDynamicGroupLineFirstList from "./data/dynamic-group/line/first"
import reportDynamicGroupLineSecondList from "./data/dynamic-group/line/second"
import reportDynamicGroupLineThirdList from "./data/dynamic-group/line/third"
import reportDynamicGroupLineFourthList from "./data/dynamic-group/line/fourth"

import dealerBuyersReportList from "./data/reportBuyers"
import dealerBuyersList from "./data/dealerBuyers"
import exportsList from "./data/exports"

export default class DummyApiService {
  details = detailsList
  clients = clientsList
  prices = pricesList
  users = usersList
  dealers = dealersList
  promotions = promotionsList
  csPricesPreview = csPricesListPreview
  csPrices = csPricesList
  csDirectories = csDirectoriesList
  brands = brandsList

  reportPeriodLinesFirstTable = reportPeriodLinesFirstTableList
  reportPeriodLinesSecondTable = reportPeriodLinesSecondTableList
  reportPeriodSumFirstTable = reportPeriodSumFirstTableList
  reportPeriodSumSecondTable = reportPeriodSumSecondTableList

  reportDynamicLines = reportDynamicLinesList
  reportDynamicSum = reportDynamicSumList

  reportPeriodGroupMainLineList = reportPeriodGroupLineMainList
  reportPeriodGroupOtherLineFirst = reportPeriodGroupLineOtherFirstList
  reportPeriodGroupOtherLineSecond = reportPeriodGroupLineOtherSecondList
  reportPeriodGroupOtherLineThird = reportPeriodGroupLineOtherThirdList
  reportPeriodGroupOtherLineFourth = reportPeriodGroupLineOtherFourthList

  reportPeriodGroupSumMainList = reportPeriodGroupSumMainList
  reportPeriodGroupOtherSumFirst = reportPeriodGroupSumOtherFirstList
  reportPeriodGroupOtherSumSecond = reportPeriodGroupSumOtherSecondList
  reportPeriodGroupOtherSumThird = reportPeriodGroupSumOtherThirdList
  reportPeriodGroupOtherSumFourth = reportPeriodGroupSumOtherFourthList

  reportDynamicGroupLineFirst = reportDynamicGroupLineFirstList
  reportDynamicGroupLineSecond = reportDynamicGroupLineSecondList
  reportDynamicGroupLineThird = reportDynamicGroupLineThirdList
  reportDynamicGroupLineFourth = reportDynamicGroupLineFourthList

  dealerBuyersReport = dealerBuyersReportList
  dealerBuyers = dealerBuyersList

  exports = exportsList

  /**
   * Получить клиентов.
   * @returns {Promise<unknown>}
   */
  getClients() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.clients)
        }
      }, 300)
    })
  }

  /**
   * Получить список акций.
   * @returns {Promise<unknown>}
   */
  getPromotions() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.promotions)
        }
      }, 300)
    })
  }

  /**
   * Получить детали.
   * @returns {Promise<unknown>}
   */
  getDetails() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.details)
        }
      }, 300)
    })
  }

  /**
   * Получить Прайсы.
   * @returns {Promise<unknown>}
   */
  getPrices() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.prices)
        }
      }, 300)
    })
  }

  /**
   * Получить Дилеров.
   * @returns {Promise<unknown>}
   */
  getDealers() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.dealers)
        }
      }, 300)
    })
  }

  /**
   * Получить Поставщика по его id.
   * @returns {Promise<unknown>}
   */
  getDealerById(id) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.dealers.filter(dealer => dealer.id === id)[0])
        }
      }, 300)
    })
  }

  /**
   * Получение информации о пользователе (временно)
   * @returns {Promise<unknown>}
   */
  getUserInfo(username) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          this.users.forEach(user => {
            if (user.username === username) {
              resolve(user)
            }
          })
        }
      }, 300)
    })
  }

  /**
   * Получение прайс листов ЦС (временно)
   * @returns {Promise<unknown>}
   */
  getCsPrices() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.csPrices)
        }
      }, 300)
    })
  }

  /**
   * Получение справочников ЦС (временно)
   * @returns {Promise<unknown>}
   */
  getCsDirectories() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.csDirectories)
        }
      }, 300)
    })
  }

  /**
   * Предварительный просмотр прайс листа (временно)
   * @returns {Promise<unknown>}
   */
  getBrands() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.brands)
        }
      }, 300)
    })
  }

  /**
   * Получение списке брендов (временно)
   * @returns {string}
   */
  getCsPricesPreview() {
    //return new Promise((resolve, reject) => {
    /*setTimeout(() => {
        const error = false;
        if (error) {
          reject(new Error("Something bad happening"));
        } else {
          resolve(
            this.csPricesPreview.filter(price => price.brandId === brandId)
          );
        }
      }, 300);*/
    //});
  }

  /**
   * Получение данных для "Отчеты динамика - линии" (временно)
   * @returns {Promise<unknown>}
   */
  getReportDynamicLinesData() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.reportDynamicLines)
        }
      }, 300)
    })
  }

  /**
   * Получение данных для "Отчеты динамика - суммы" (временно)
   * @returns {Promise<unknown>}
   */
  getReportDynamicSumData() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.reportDynamicSum)
        }
      }, 300)
    })
  }

  /**
   * Получение данных для первой таблицы "Отчеты период - линии" (временно)
   * @returns {Promise<unknown>}
   */
  getReportPeriodLinesFirstData() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.reportPeriodLinesFirstTable)
        }
      }, 300)
    })
  }

  /**
   * Получение данных для второй таблицы "Отчеты период - линии" (временно)
   * @returns {Promise<unknown>}
   */
  getReportPeriodLinesSecondData() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.reportPeriodLinesSecondTable)
        }
      }, 300)
    })
  }

  /**
   * Получение данных для первой таблицы "Отчеты период - суммы" (временно)
   * @returns {Promise<unknown>}
   */
  getReportPeriodSumFirstData() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.reportPeriodSumFirstTable)
        }
      }, 300)
    })
  }

  /**
   * Получение данных для второй таблицы "Отчеты период - суммы" (временно)
   * @returns {Promise<unknown>}
   */
  getReportPeriodSumSecondData() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.reportPeriodSumSecondTable)
        }
      }, 300)
    })
  }

  /**
   * Получение данных для главной таблицы "Отчеты Период-Группа - линии" (временно)
   * @returns {Promise<unknown>}
   */
  getReportPeriodGroupLineMain() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.reportPeriodGroupMainLineList)
        }
      }, 300)
    })
  }

  /**
   * Получение данных для других таблиц "Отчеты Период-Группа - линии" (временно)
   * @returns {Promise<unknown>}
   */
  getReportPeriodGroupLineOther() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve([
            this.reportPeriodGroupOtherLineFirst,
            this.reportPeriodGroupOtherLineSecond,
            this.reportPeriodGroupOtherLineThird,
            this.reportPeriodGroupOtherLineFourth,
          ])
        }
      }, 300)
    })
  }

  /**
   * Получение данных для других таблиц "Отчеты Период-Группа - суммы" (временно)
   * @returns {Promise<unknown>}
   */
  getReportPeriodGroupSumMain() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.reportPeriodGroupSumMainList)
        }
      }, 300)
    })
  }

  /**
   * Получение данных для других таблиц "Отчеты Период-Группа - суммы" (временно)
   * @returns {Promise<unknown>}
   */
  getReportPeriodGroupSumOther() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve([
            this.reportPeriodGroupOtherSumFirst,
            this.reportPeriodGroupOtherSumSecond,
            this.reportPeriodGroupOtherSumThird,
            this.reportPeriodGroupOtherSumFourth,
          ])
        }
      }, 300)
    })
  }

  /**
   * Получение данных для таблиц "Отчеты Динамика-Группа - линии" (временно)
   * @returns {Promise<unknown>}
   */
  getReportDynamicGroupLineData() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve([
            this.reportDynamicGroupLineFirst,
            this.reportDynamicGroupLineSecond,
            this.reportDynamicGroupLineThird,
            this.reportDynamicGroupLineFourth,
          ])
        }
      }, 300)
    })
  }

  /**
   * Получение данных для таблицы "Дилеры - отчеты по оптовикам" (временно)
   * @returns {Promise<unknown>}
   */
  getReportBuyersData() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.dealerBuyersReport)
        }
      }, 300)
    })
  }

  /**
   * Получение данных для списка оптовиков (временно)
   * @returns {Promise<unknown>}
   */
  getDealerBuyers() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.dealerBuyers)
        }
      }, 300)
    })
  }

  /**
   * Получение данных экспорта (временно)
   * @returns {Promise<unknown>}
   */
  getExports() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const error = false
        if (error) {
          reject(new Error("Something bad happening"))
        } else {
          resolve(this.exports)
        }
      }, 300)
    })
  }

  /**
   * Поиск деталей.
   * @param values
   * @returns {({code: string, address: string, publishAt: string, dealer: string, key: string}|{code: string, address: string, publishAt: string, dealer: string, key: string}|{code: string, address: string, publishAt: string, dealer: string, key: string}|{code: string, address: string, publishAt: string, dealer: string, key: string}|{code: string, address: string, publishAt: string, dealer: string, key: string})[]|*[]}
   */
  searchDetails(values) {
    if (!values) {
      throw new Error("Не переданы значения для поиска деталей")
    }

    if (!values.code && !values.address && !values.dealer) {
      return this.details
    }

    let codeResults = []
    if (values.code) {
      codeResults = this.details.filter(
        item =>
          item.code
            .toLowerCase()
            .trim()
            .indexOf(values.code.toLowerCase().trim()) !== -1
      )
    }
    let addressResults = []
    if (values.address) {
      addressResults = this.details.filter(
        item =>
          item.address
            .toLowerCase()
            .trim()
            .indexOf(values.address.toLowerCase().trim()) !== -1
      )
    }
    let dealerResults = []
    if (values.dealer) {
      dealerResults = this.details.filter(
        item =>
          item.dealer
            .toLowerCase()
            .trim()
            .indexOf(values.dealer.toLowerCase().trim()) !== -1
      )
    }

    let result = [...codeResults, ...dealerResults, ...addressResults]
    if (result.length) {
      console.log("remove duplicates", result)
      result = result.filter((item, idx) => result.indexOf(item) === idx)
    }
    console.log("result of filter", result)
    return result
  }
}
