/**
 * SUPPLIER_ORDER_OPERATOR
 */

export default (() => {
  return {
    SUPPLIER_ORDER_OPERATOR: {
      defaultSelectedId: ["8"],
      items: [
        {
          id: "8",
          icon: "exclamation-circle",
          name: "Дилеры",
          path: "/dealers",
        },
        {
          id: "News",
          name: "Новости",
          icon: "exclamation-circle",
          path: "/news",
        },
      ],
    },
  }
})()
