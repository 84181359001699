import { DummyApiService } from "@/services"
const service = new DummyApiService()

export const state = {
  filters: {
    current: {
      isLineShow: "yes",
      isSumShow: "no",
    },
    temp: {
      isLineShow: "yes",
      isSumShow: "no",
    },
    default: {
      isLineShow: "yes",
      isSumShow: "no",
    },
  },
  line: {
    mainTable: {
      columns: [
        {
          title: "Производитель",
          dataIndex: "dealer",
          key: "dealer",
        },
        {
          title: "Количество подключенных ЮЛ",
          key: "connectedUlAmount",
          dataIndex: "connectedUlAmount",
        },
        {
          title: "Количество активных ЮЛ (Были заказы за период)",
          dataIndex: "activeUlAmount",
          key: "activeUlAmount",
        },
        {
          title: "Количество заказанных линий, всего",
          dataIndex: "orderedLinesAmount",
          key: "orderedLinesAmount",
        },
        {
          title: "Количество отказанных линий, всего",
          dataIndex: "declinedLinesAmount",
          key: "declinedLinesAmount",
        },
        {
          title: "Количество подтвержденных линий, всего",
          dataIndex: "approvedLinesAmount",
          key: "approvedLinesAmount",
        },
        {
          title: "Количество линий без статуса, всего",
          dataIndex: "statuslessLinesAmount",
          key: "statuslessLinesAmount",
        },
        {
          title: "Коэффициент отказов",
          dataIndex: "declineKef",
          key: "declineKef",
        },
        {
          title: "Коэффициент подтверждений",
          dataIndex: "approveKef",
          key: "approveKef",
        },
        {
          title: "Количество отгруженных линий",
          dataIndex: "departedLinesAmount",
          key: "departedLinesAmount",
        },
        {
          title: "Процент заказов из наличия от общего",
          dataIndex: "nalOrderPercent",
          key: "nalOrderPercent",
        },
        {
          title: "Процент заказов ЦС срочный от общего",
          dataIndex: "srochCSOrderPercent",
          key: "srochCSOrderPercent",
        },
        {
          title: "Процент заказов ЦС складской от общего",
          dataIndex: "skladCSOrderPercent",
          key: "skladCSOrderPercent",
        },
        {
          title: "Процент заказов ЦС под заказ от общего",
          dataIndex: "orderedCSOrderPercent",
          key: "orderedCSOrderPercent",
        },
        {
          title: "Процент заказов прочие склады от общего",
          dataIndex: "otherOrderPercent",
          key: "otherOrderPercent",
        },
        {
          title: "Адрес",
          dataIndex: "address",
          key: "address",
          width: 200,
        },
      ],
      data: [],
    },
    otherTables: {
      list: [
        {
          columns: [
            {
              key: "dealerName",
              dataIndex: "dealerName",
            },
            {
              title: "Количество заказанных линий, всего",
              key: "orderedLinesAmount",
              dataIndex: "orderedLinesAmount",
            },
            {
              title: "Количество заказанных линий из наличия",
              key: "orderedLinesPresence",
              dataIndex: "orderedLinesPresence",
            },
            {
              title: "Количество заказанных линий ЦС срочный",
              key: "orderedLinesSroch",
              dataIndex: "orderedLinesSroch",
            },
            {
              title: "Количество заказанных линий ЦС складской",
              key: "orderedLinesSklad",
              dataIndex: "orderedLinesSklad",
            },
            {
              title: "Количество заказанных линий под заказ",
              key: "orderedLinesOrder",
              dataIndex: "orderedLinesOrder",
            },
            {
              title: "Количество заказанных линий склады других производителей",
              key: "orderedLinesOtherDealers",
              dataIndex: "orderedLinesOtherDealers",
            },
            {
              title: "Количество заказанных линий прочие склады",
              key: "orderedLinesOther",
              dataIndex: "orderedLinesOther",
            },
          ],
          data: [],
        },
        {
          columns: [
            {
              key: "dealerName",
              dataIndex: "dealerName",
            },
            {
              title: "Количество отказанных линий, всего",
              key: "declainedLinesAmount",
              dataIndex: "declainedLinesAmount",
            },
            {
              title: "Количество отказанных линий из наличия",
              key: "declainedLinesPresence",
              dataIndex: "declainedLinesPresence",
            },
            {
              title: "Количество отказанных линий ЦС срочный",
              key: "declainedLinesSroch",
              dataIndex: "declainedLinesSroch",
            },
            {
              title: "Количество отказанных линий ЦС складской",
              key: "declainedLinesSklad",
              dataIndex: "declainedLinesSklad",
            },
            {
              title: "Количество отказанных линий под заказ",
              key: "declainedLinesOrder",
              dataIndex: "declainedLinesOrder",
            },
            {
              title: "Количество отказанных линий склады других производителей",
              key: "declainedLinesOtherDealers",
              dataIndex: "declainedLinesOtherDealers",
            },
            {
              title: "Количество отказанных линий прочие склады",
              key: "declainedLinesOther",
              dataIndex: "declainedLinesOther",
            },
          ],
          data: [],
        },
        {
          columns: [
            {
              key: "dealerName",
              dataIndex: "dealerName",
            },
            {
              title: "Количество подтвержденных линий, всего",
              key: "approvedLinesAmount",
              dataIndex: "approvedLinesAmount",
            },
            {
              title: "Количество подтвержденных линий из наличия",
              key: "approvedLinesPresence",
              dataIndex: "approvedLinesPresence",
            },
            {
              title: "Количество подтвержденных линий ЦС срочный",
              key: "approvedLinesSroch",
              dataIndex: "approvedLinesSroch",
            },
            {
              title: "Количество подтвержденных линий ЦС складской",
              key: "approvedLinesSklad",
              dataIndex: "approvedLinesSklad",
            },
            {
              title: "Количество подтвержденных линий под заказ",
              key: "approvedLinesOrder",
              dataIndex: "approvedLinesOrder",
            },
            {
              title:
                "Количество подтвержденных линий склады других производителей",
              key: "approvedLinesOtherDealers",
              dataIndex: "approvedLinesOtherDealers",
            },
            {
              title: "Количество подтвержденных линий прочие склады",
              key: "approvedLinesOther",
              dataIndex: "approvedLinesOther",
            },
          ],
          data: [],
        },
        {
          columns: [
            {
              key: "dealerName",
              dataIndex: "dealerName",
            },
            {
              title: "Количество линий без статуса, всего",
              key: "statuslessLinesAmount",
              dataIndex: "statuslessLinesAmount",
            },
            {
              title: "Количество линий без статуса из наличия",
              key: "statuslessLinesPresence",
              dataIndex: "statuslessLinesPresence",
            },
            {
              title: "Количество линий без статуса ЦС срочный",
              key: "statuslessLinesSroch",
              dataIndex: "statuslessLinesSroch",
            },
            {
              title: "Количество линий без статуса ЦС складской",
              key: "statuslessLinesSklad",
              dataIndex: "statuslessLinesSklad",
            },
            {
              title: "Количество линий без статуса под заказ",
              key: "statuslessLinesOrder",
              dataIndex: "statuslessLinesOrder",
            },
            {
              title:
                "Количество линий без статуса склады других производителей",
              key: "statuslessLinesOtherDealers",
              dataIndex: "statuslessLinesOtherDealers",
            },
            {
              title: "Количество линий без статуса прочие склады",
              key: "statuslessLinesOther",
              dataIndex: "statuslessLinesOther",
            },
          ],
          data: [],
        },
      ],
      hidden: false,
    },
  },
  sum: {
    mainTable: {
      columns: [
        {
          title: "Производитель",
          dataIndex: "dealer",
          key: "dealer",
        },
        {
          title: "Количество подключенных ЮЛ",
          key: "connectedUlAmount",
          dataIndex: "connectedUlAmount",
        },
        {
          title: "Количество активных ЮЛ (Были заказы за период)",
          dataIndex: "activeUlAmount",
          key: "activeUlAmount",
        },
        {
          title: "Количество заказанных линий, всего",
          dataIndex: "orderedLinesAmount",
          key: "orderedLinesAmount",
        },
        {
          title: "Количество отказанных линий, всего",
          dataIndex: "declinedLinesAmount",
          key: "declinedLinesAmount",
        },
        {
          title: "Количество подтвержденных линий, всего",
          dataIndex: "approvedLinesAmount",
          key: "approvedLinesAmount",
        },
        {
          title: "Количество линий без статуса, всего",
          dataIndex: "statuslessLinesAmount",
          key: "statuslessLinesAmount",
        },
        {
          title: "Коэффициент отказов",
          dataIndex: "declineKef",
          key: "declineKef",
        },
        {
          title: "Коэффициент подтверждений",
          dataIndex: "approveKef",
          key: "approveKef",
        },
        {
          title: "Количество отгруженных линий",
          dataIndex: "departedLinesAmount",
          key: "departedLinesAmount",
        },
        {
          title: "Процент заказов из наличия от общего",
          dataIndex: "nalOrderPercent",
          key: "nalOrderPercent",
        },
        {
          title: "Процент заказов ЦС срочный от общего",
          dataIndex: "srochCSOrderPercent",
          key: "srochCSOrderPercent",
        },
        {
          title: "Процент заказов ЦС складской от общего",
          dataIndex: "skladCSOrderPercent",
          key: "skladCSOrderPercent",
        },
        {
          title: "Процент заказов ЦС под заказ от общего",
          dataIndex: "orderedCSOrderPercent",
          key: "orderedCSOrderPercent",
        },
        {
          title: "Процент заказов прочие склады от общего",
          dataIndex: "otherOrderPercent",
          key: "otherOrderPercent",
        },
        {
          title: "Адрес",
          dataIndex: "address",
          key: "address",
          width: 200,
        },
      ],
      data: [],
    },
    otherTables: {
      list: [
        {
          columns: [
            {
              key: "dealerName",
              dataIndex: "dealerName",
            },
            {
              title: "Количество заказанных линий, всего",
              key: "orderedLinesAmount",
              dataIndex: "orderedLinesAmount",
            },
            {
              title: "Количество заказанных линий из наличия",
              key: "orderedLinesPresence",
              dataIndex: "orderedLinesPresence",
            },
            {
              title: "Количество заказанных линий ЦС срочный",
              key: "orderedLinesSroch",
              dataIndex: "orderedLinesSroch",
            },
            {
              title: "Количество заказанных линий ЦС складской",
              key: "orderedLinesSklad",
              dataIndex: "orderedLinesSklad",
            },
            {
              title: "Количество заказанных линий под заказ",
              key: "orderedLinesOrder",
              dataIndex: "orderedLinesOrder",
            },
            {
              title: "Количество заказанных линий склады других производителей",
              key: "orderedLinesOtherDealers",
              dataIndex: "orderedLinesOtherDealers",
            },
            {
              title: "Количество заказанных линий прочие склады",
              key: "orderedLinesOther",
              dataIndex: "orderedLinesOther",
            },
          ],
          data: [],
        },
        {
          columns: [
            {
              key: "dealerName",
              dataIndex: "dealerName",
            },
            {
              title: "Количество отказанных линий, всего",
              key: "declainedLinesAmount",
              dataIndex: "declainedLinesAmount",
            },
            {
              title: "Количество отказанных линий из наличия",
              key: "declainedLinesPresence",
              dataIndex: "declainedLinesPresence",
            },
            {
              title: "Количество отказанных линий ЦС срочный",
              key: "declainedLinesSroch",
              dataIndex: "declainedLinesSroch",
            },
            {
              title: "Количество отказанных линий ЦС складской",
              key: "declainedLinesSklad",
              dataIndex: "declainedLinesSklad",
            },
            {
              title: "Количество отказанных линий под заказ",
              key: "declainedLinesOrder",
              dataIndex: "declainedLinesOrder",
            },
            {
              title: "Количество отказанных линий склады других производителей",
              key: "declainedLinesOtherDealers",
              dataIndex: "declainedLinesOtherDealers",
            },
            {
              title: "Количество отказанных линий прочие склады",
              key: "declainedLinesOther",
              dataIndex: "declainedLinesOther",
            },
          ],
          data: [],
        },
        {
          columns: [
            {
              key: "dealerName",
              dataIndex: "dealerName",
            },
            {
              title: "Сумма подтвержденных заказов, всего",
              key: "approvedSumAmount",
              dataIndex: "approvedSumAmount",
            },
            {
              title: "Сумма подтвержденных заказов из наличия",
              key: "approvedSumPresence",
              dataIndex: "approvedSumPresence",
            },
            {
              title: "Сумма подтвержденных заказов ЦС срочный",
              key: "approvedSumSroch",
              dataIndex: "approvedSumSroch",
            },
            {
              title: "Сумма подтвержденных заказов ЦС складской",
              key: "approvedSumSklad",
              dataIndex: "approvedSumSklad",
            },
            {
              title: "Сумма подтвержденных заказов ЦС под заказ",
              key: "approvedSumOrder",
              dataIndex: "approvedSumOrder",
            },
            {
              title: "Сумма подтвержденных заказов склады др. производителей",
              key: "approvedSumOtherDealers",
              dataIndex: "approvedSumOtherDealers",
            },
            {
              title: "Сумма подтвержденных заказов прочие склады",
              key: "approvedSumOther",
              dataIndex: "approvedSumOther",
            },
          ],
          data: [],
        },
        {
          columns: [
            {
              key: "dealerName",
              dataIndex: "dealerName",
            },
            {
              title: "Сумма заказов без статуса, всего",
              key: "statuslessSumAmount",
              dataIndex: "statuslessSumAmount",
            },
            {
              title: "Сумма заказов без статуса из наличия",
              key: "statuslessSumPresence",
              dataIndex: "statuslessSumPresence",
            },
            {
              title: "Сумма заказов без статуса ЦС срочный",
              key: "statuslessSumSroch",
              dataIndex: "statuslessSumSroch",
            },
            {
              title: "Сумма заказов без статуса ЦС складской",
              key: "statuslessSumSklad",
              dataIndex: "statuslessSumSklad",
            },
            {
              title: "Сумма заказов без статуса ЦС под заказ",
              key: "statuslessSumOrder",
              dataIndex: "statuslessSumOrder",
            },
            {
              title: "Сумма заказов без статуса склады др. производителей",
              key: "statuslessSumOtherDealers",
              dataIndex: "statuslessSumOtherDealers",
            },
            {
              title: "Сумма заказов без статуса прочие склады",
              key: "statuslessSumOther",
              dataIndex: "statuslessSumOther",
            },
          ],
          data: [],
        },
      ],
      hidden: false,
    },
  },
}

export const mutations = {
  FILTER: state =>
    (state.filters.current = {
      ...state.filters.temp,
    }),
  CLEAR_FILTERS: state => {
    state.filters.current = {
      ...state.filters.default,
    }
    state.filters.temp = {
      ...state.filters.default,
    }
  },
  UPDATE_FILTER_IS_LINE_SHOW: (state, payload) =>
    (state.filters.temp.isLineShow = payload),
  UPDATE_FILTER_IS_SUM_SHOW: (state, payload) =>
    (state.filters.temp.isSumShow = payload),
  TOGGLE_LINE_OTHER_TABLES_VISIBILITY: state =>
    (state.line.otherTables.hidden = !state.line.otherTables.hidden),
  TOGGLE_SUM_OTHER_TABLES_VISIBILITY: state =>
    (state.sum.otherTables.hidden = !state.sum.otherTables.hidden),
  SET_LINE_MAIN_DATA: (state, payload) => (state.line.mainTable.data = payload),
  SET_SUM_MAIN_DATA: (state, payload) => (state.sum.mainTable.data = payload),
  SET_LINE_OTHER_DATA: (state, payload) => {
    for (let i = 0; i < payload.length; i++) {
      state.line.otherTables.list[i].data = payload[i]
    }
  },
  SET_SUM_OTHER_DATA: (state, payload) => {
    for (let i = 0; i < payload.length; i++) {
      state.sum.otherTables.list[i].data = payload[i]
    }
  },
}

export const actions = {
  fetchLineMainData: async ({ commit }) => {
    const list = await service.getReportPeriodGroupLineMain()
    commit("SET_LINE_MAIN_DATA", list)
  },
  fetchLineOtherData: async ({ commit }) => {
    const list = await service.getReportPeriodGroupLineOther()
    commit("SET_LINE_OTHER_DATA", list)
  },
  fetchSumMainData: async ({ commit }) => {
    const list = await service.getReportPeriodGroupSumMain()
    commit("SET_SUM_MAIN_DATA", list)
  },
  fetchSumOtherData: async ({ commit }) => {
    const list = await service.getReportPeriodGroupSumOther()
    commit("SET_SUM_OTHER_DATA", list)
  },
}

export const getters = {
  isLineShow: state => state.filters.current.isLineShow === "yes",
  isTempLineShow: state => state.filters.temp.isLineShow === "yes",
  isSumShow: state => state.filters.current.isSumShow === "yes",
  isTempSumShow: state => state.filters.temp.isSumShow === "yes",

  lineIsShowOtherTables: state => !state.line.otherTables.hidden,
  lineMainData: state => state.line.mainTable.data,
  lineMainColumns: state => state.line.mainTable.columns,
  lineOtherTables: state => state.line.otherTables.list,

  sumIsShowOtherTables: state => !state.sum.otherTables.hidden,
  sumMainData: state => state.sum.mainTable.data,
  sumMainColumns: state => state.sum.mainTable.columns,
  sumOtherTables: state => state.sum.otherTables.list,
}
