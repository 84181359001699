<template>
  <a-layout-sider
    class="slider"
    v-model="collapsed"
    :collapsible="false"
    width="250"
    :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }"
  >
    <div class="slider-logo-wrap">
      <!--      <div class="slider-logo-image"></div>-->
      <svg
        version="1.2"
        baseProfile="tiny-ps"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 80 18"
        width="80"
        height="18"
        preserveAspectRatio="xMidYMid"
        class="slider-logo-image"
      >
        <path
          id="Layer"
          d="M46.64 15.92c0 .15.04.23.13.23.07 0 .13-.03.2-.07L71.75.63c.44-.28.83-.43 1.43-.43h5.45c.84 0 1.4.54 1.4 1.35v9.97c0 1.2-.27 1.9-1.4 2.55l-6.61 3.8c-.08.06-.16.08-.22.08-.08 0-.15-.05-.15-.27V6.03c0-.13-.04-.23-.14-.23-.07 0-.12.03-.19.07L53.2 17.16c-.51.32-.92.41-1.39.41H39.79c-.83 0-1.4-.54-1.4-1.34V1.86c0-.1-.05-.19-.13-.19-.07 0-.13.02-.2.06L26.11 8.62c-.12.07-.15.14-.15.19 0 .05.02.09.11.17l8.53 8.18c.11.11.19.2.19.28 0 .09-.12.14-.27.14H26.8c-.6 0-1.07-.09-1.4-.4l-5.18-4.97c-.06-.06-.1-.08-.15-.08a.45.45 0 00-.17.05l-8.65 5c-.52.3-.88.39-1.4.39H1.88c-.84 0-1.4-.54-1.4-1.34V6.44c0-1.22.27-1.9 1.4-2.55L8.53.06c.07-.04.12-.05.18-.05.09 0 .15.1.15.31v13.1c0 .13.04.2.14.2.05 0 .12-.04.19-.08L31.65.59c.53-.31.86-.4 1.48-.4h12.09c.84 0 1.4.54 1.4 1.34l.02 14.39z"
          fill="currentColor"
        ></path>
      </svg>
    </div>

    <a-divider class="user__divider" />

    <a-menu
      class="search-menu__container"
      mode="inline"
      :inlineCollapsed="collapsed"
      theme="dark"
      :selectedKeys="selectedKeys"
    >
      <template v-for="{ name, icon, path, children, id } in currentMenu">
        <template v-if="children">
          <a-sub-menu :key="id">
            <span slot="title">
              <a-icon :type="icon" />
              <span>{{ name }}</span>
            </span>
            <a-menu-item
              v-for="subMenuItem in children.items"
              :key="subMenuItem.id"
              @click="onMenuItemClick(subMenuItem.path)"
            >
              {{ subMenuItem.name }}
            </a-menu-item>
          </a-sub-menu>
        </template>
        <template v-else>
          <a-menu-item :key="id" @click="onMenuItemClick(path)">
            <a-icon :type="icon" />
            <span v-if="id === 'Basket'">
              {{ name }}
              <a-badge
                :count="basketCount"
                :number-style="{ marginLeft: '0px' }"
              />
            </span>
            <span v-else-if="id === 'Chats'">
              {{ name }}
              <a-badge :count="user.msg_new_cnt" />
            </span>
            <span v-else>{{ name }}</span>
          </a-menu-item>
        </template>
      </template>
    </a-menu>
    <div class="user">
      <a-divider class="user__divider" />
      <div class="user__content">
        <!--        <div class="diller-info" v-if="isBuyer"> isDealer isOwner -->
        <!--          <a-popover placement="bottom">-->
        <!--            <template slot="title">-->
        <!--              <div class="main">-->
        <!--                <div class="element title">Ваш производитель:</div>-->
        <!--                <div class="element name">ТЕМП-АВТО</div>-->
        <!--                <div class="element person">Андрей Сергеев</div>-->
        <!--              </div>-->
        <!--            </template>-->
        <!--            <div class="user__info">-->
        <!--              <a-avatar class="user__avatar" :size="36" icon="user" />-->
        <!--              <h4 class="user__name">{{ userName }}</h4>-->
        <!--            </div>-->
        <!--          </a-popover>-->
        <!--        </div>-->

        <div class="diller-info">
          <a-popover placement="bottom">
            <template slot="title">
              <div class="main">
                <div class="element name">CoDex Kia</div>
              </div>
            </template>
            <div class="user-info" v-if="user">
              <a-avatar class="user-avatar" :size="36" icon="user" />
              <div class="user-data">
                <span>{{ user.email }}</span>
                <span>{{ role }}</span>
                <span>{{ companyName }}</span>
              </div>
            </div>
          </a-popover>
        </div>

        <div class="user__logout">
          <a-tooltip placement="topLeft" title="Выход">
            <a-button
              @click="onLogout"
              type="link"
              shape="circle"
              icon="logout"
              size="large"
            />
          </a-tooltip>
        </div>
      </div>
    </div>
  </a-layout-sider>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

// Menu
import ADMIN from "../store/enum/menu/ADMIN"
import SUPPLIER_ADMIN from "../store/enum/menu/SUPPLIER_ADMIN"
import SUPPLIER_OPERATOR from "../store/enum/menu/SUPPLIER_OPERATOR"
import SUPPLIER_ANALYST from "../store/enum/menu/SUPPLIER_ANALYST"
import SUPPLIER_ORDER_OPERATOR from "../store/enum/menu/SUPPLIER_ORDER_OPERATOR"
import MANUFACTURER_ADMIN from "../store/enum/menu/MANUFACTURER_ADMIN"
import MANUFACTURER_OPERATOR from "../store/enum/menu/MANUFACTURER_OPERATOR"
import MANUFACTURER_ANALYST from "../store/enum/menu/MANUFACTURER_ANALYST"
import MANUFACTURER_ORDER_OPERATOR from "../store/enum/menu/MANUFACTURER_ORDER_OPERATOR"
import DEALER_ADMIN from "../store/enum/menu/DEALER_ADMIN"
import DEALER_OPERATOR from "../store/enum/menu/DEALER_OPERATOR"
import DEALER_ANALYST from "../store/enum/menu/DEALER_ANALYST"
import DEALER_ORDER_OPERATOR from "../store/enum/menu/DEALER_ORDER_OPERATOR"
import DEALER_OPERATOR_LOYALTY from "../store/enum/menu/DEALER_OPERATOR_LOYALTY"

export default {
  name: "Slider",
  // watch: {
  //   $route() {
  //     console.log("default route path", this.$route.path)
  //     if (this.$route.path === "/") {
  //       this.defaultSelectedId = ["SearchDetails"]
  //     }
  //   },
  // },
  data() {
    return {
      defaultSelectedId: ["SearchDetails"],
      collapsed: false,
      menu: {
        roles: {
          ...ADMIN,
          ...SUPPLIER_ADMIN,
          ...SUPPLIER_OPERATOR,
          ...SUPPLIER_ANALYST,
          ...SUPPLIER_ORDER_OPERATOR,
          ...MANUFACTURER_ADMIN,
          ...MANUFACTURER_OPERATOR,
          ...MANUFACTURER_ANALYST,
          ...MANUFACTURER_ORDER_OPERATOR,
          ...DEALER_ADMIN,
          ...DEALER_OPERATOR,
          ...DEALER_ANALYST,
          ...DEALER_ORDER_OPERATOR,
          ...DEALER_OPERATOR_LOYALTY,
        },
      },
    }
  },
  mounted() {
    this.checkBasket()
  },
  computed: {
    ...mapGetters({
      currentRoles: "auth/roles",
      rolesNames: "auth/rolesNames",
      isLoggedIn: "auth/isLoggedIn",
      basketSet: "basket/basketSet",
      basketCount: "basket/count",
      isClient: "auth/isBuyer",
      isBuyer: "auth/isBuyer",
      isBuyerForDealers: "auth/isBuyerForDealers",
      isDealer: "auth/isDealer",
      isOwner: "auth/isOwner",
      isAdmin: "auth/isAdmin",
      role: "auth/role",
      user: "auth/user",
    }),
    currentMenu() {
      const menu = this.menu.roles[this.currentRoles[0]]?.items
      if (this.isBuyerForDealers) {
        for (const item of menu) {
          if (item.id === "Basket") {
            item.path = "basketProvider"
            break
          }
        }
      }
      return menu
    },
    selectedKeys() {
      return this.$route.name.split("-")
    },
    userName() {
      return this.isDealer
        ? "Dealer"
        : this.isOwner
        ? "Owner"
        : this.isBuyer
        ? "Buyer"
        : this.isAdmin
        ? "Admin"
        : "Client"
    },
    companyName() {
      return this.isBuyer
        ? this.user.client?.name
        : this.isDealer
        ? this.user.dealer?.name
        : this.isOwner
        ? this.user.client?.name
        : ""
    },
  },
  watch: {
    isClient() {
      this.checkBasket()
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      getProducts: "basket/getProducts",
    }),
    onLogout() {
      this.logout()
      document.head.querySelectorAll("#quiz-widget").forEach(script => {script.parentNode.removeChild(script)})
      document.getElementById("quiz-start").style.display = "none"
      this.$router.push("/login")
    },
    onMenuItemClick(path) {
      if (path !== this.$route.path) {
        this.$router.push(path)
      }
    },
    canWatch(requiredRole) {
      return this.currentRoles.includes(this.rolesNames[requiredRole])
    },
    checkBasket() {
      if (!this.basketSet && this.isClient) {
        this.getProducts({ reserved: true })
      }
    },
  },
}
</script>

<style lang="scss">
@import "../assets/sass/variables";

/* TODO: убрать important */
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: transparent !important;
}
.submenu {
  padding-left: 30px !important;
}
.search-menu {
  &__collapse-button {
    margin-top: 10px;
    margin-left: 4px;
    margin-bottom: 10px;

    background-color: #1980ff;
  }
}
.slider {
  position: relative;

  .user {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    color: white;
    padding: 10px 0;

    &__content {
      padding: 0 10px;
    }

    &__info {
      display: flex;
      align-items: center;
    }

    &__avatar {
      color: gray;
      background-color: white;
    }

    &__name {
      margin: 0 20px;
      color: white;
    }

    &__divider {
      margin: 10px 0;
      opacity: 0.2;
    }

    &__logout {
      position: absolute;
      right: 5px;
      top: 30px;
    }
  }

  &.ant-layout-sider {
    background: $color-brand-grey-dark;
  }

  .ant-menu-dark {
    background: $color-brand-grey-dark;
  }
}

.diller-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  color: white;

  line-height: normal;

  .main {
    display: flex;
    flex-direction: column;

    margin: 0 15px;

    .element {
      padding-top: 2px;
    }

    .title,
    .name {
      font-weight: 900;
    }

    .title {
      font-size: 15px;
    }

    .name {
      font-size: 18px;
    }
  }

  .avatar {
    width: 58px;
    height: 58px;

    margin: 0 5px;
  }

  .info {
    margin-left: 15px;

    font-weight: 500;

    .element {
      display: block;
      padding-top: 2px;
    }
  }

  .user-info {
    display: flex;
    flex-direction: row;
    font-size: 10px;

    .user-data {
      padding: 0 5px;
      display: flex;
      flex-direction: column;
    }
  }
}

.status-owner {
  font-size: 12px;
  margin-left: 20px;
  strong {
    text-transform: uppercase;
    font-size: 10px;
  }
}

.slider-logo-wrap {
  //background: white;
  width: 100%;
  height: 70px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  .slider-logo-image {
    background-image: url("../assets/images/JLR_Logo_Lockup_Horiz_180mm_RGB.svg");
    width: 60%;
    height: 60%;
    background-size: contain;
    background-repeat: no-repeat;
    color: white;
  }
}
</style>
