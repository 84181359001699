import ReportService from "@/services/api/v1/ReportService"
const service = new ReportService()

export const state = {
  filters: {
    current: {
      isLineShow: "yes",
      isSumShow: "no",
      client_id: -1,
      company_id: -1,
      status_id: -1,
      brand: "",
      period: [null, null],
    },
    temp: {
      isLineShow: "yes",
      isSumShow: "no",
      client_id: -1,
      company_id: -1,
      status_id: -1,
      brand: "",
      period: [null, null],
    },
    default: {
      isLineShow: "yes",
      isSumShow: "no",
      client_id: -1,
      company_id: -1,
      status_id: -1,
      brand: "",
      period: [null, null],
    },
  },
  line: {
    columns: [
      {
        title: "Бренд",
        dataIndex: "brand_name",
        key: "brand_name",
      },
      {
        title: "Номер заказа",
        dataIndex: "order_uuid",
        key: "order_uuid",
      },
      {
        title: "Статус",
        dataIndex: "status_name",
        key: "status_name",
      },
      {
        title: "Подстатус",
        dataIndex: "part_status_name",
        key: "part_status_name",
      },
      {
        title: "Наименование товара",
        dataIndex: "product_name",
        key: "product_name",
      },
      {
        title: "Каталожный номер",
        dataIndex: "product_code",
        key: "product_code",
      },
      {
        title: "Штуки",
        dataIndex: "count",
        key: "count",
      },
      {
        title: "Объем, литров",
        dataIndex: "total_volume",
        key: "total_volume",
      },
      {
        title: "Сумма",
        dataIndex: "total_sum",
        scopedSlots: { customRender: "priceWithNDS" },
        key: "total_sum",
      },
      {
        title: "Стоимость, за литр рублей с НДС",
        dataIndex: "price_stock",
        scopedSlots: { customRender: "priceWithNDS" },
        key: "price_stock",
      },
      {
        title: "Дата создания заказа",
        dataIndex: "created_at",
        sorter : true,
        scopedSlots: { customRender: "date" },
        key: "created_at",
      },
      {
        title: "Наличие",
        key: "availability",
        dataIndex: "availability",
      },
      {
        title: "Комментарий",
        dataIndex: "last_comment",
        width: 160,
      },
    ],
    columnsForAdmin: [
      {
        title: "RS код дилера",
        dataIndex: "dealer_code",
        key: "dealer_code",
      },
      {
        title: "Номер заказа",
        dataIndex: "order_uuid",
        key: "order_uuid",
      },
      {
        title: "Наименование дилера",
        dataIndex: "dealer_name",
        key: "dealer_name",
      },
      {
        title: "Поставщик",
        key: "supplier_name",
        dataIndex: "supplier_name",
      },
      {
        title: "Бренд",
        dataIndex: "brand_name",
        key: "brand_name",
      },
      {
        title: "Статус",
        dataIndex: "status_name",
        key: "status_name",
      },
      {
        title: "Подстатус",
        dataIndex: "part_status_name",
        key: "part_status_name",
      },
      {
        title: "Наименование товара",
        dataIndex: "product_name",
        key: "product_name",
      },
      {
        title: "Каталожный номер",
        dataIndex: "product_code",
        key: "product_code",
      },
      {
        title: "Штуки",
        dataIndex: "count",
        key: "count",
      },
      {
        title: "Объем, литров",
        dataIndex: "total_volume",
        key: "total_volume",
      },
      {
        title: "Сумма",
        dataIndex: "total_sum",
        scopedSlots: { customRender: "priceWithNDS" },
        key: "total_sum",
      },
      {
        title: "Стоимость, за литр рублей с НДС",
        dataIndex: "price_stock",
        scopedSlots: { customRender: "priceWithNDS" },
        key: "price_stock",
      },
      {
        title: "Дата создания заказа",
        dataIndex: "created_at",
        sorter : true,
        scopedSlots: { customRender: "date" },
        key: "created_at",
      },
      {
        title: "Наличие",
        key: "availability",
        dataIndex: "availability",
      },
      {
        title: "Комментарий",
        dataIndex: "last_comment",
        width: 160,
      },
    ],
    columnsForOwner: [
      {
        title: "RS код дилера",
        dataIndex: "dealer_code",
        key: "dealer_code",
      },
      {
        title: "Номер заказа",
        dataIndex: "order_uuid",
        key: "order_uuid",
      },
      {
        title: "Наименование дилера",
        dataIndex: "dealer_name",
        key: "dealer_name",
      },
      {
        title: "Бренд",
        dataIndex: "brand_name",
        key: "brand_name",
      },
      {
        title: "Статус",
        dataIndex: "status_name",
        key: "status_name",
      },
      {
        title: "Подстатус",
        dataIndex: "part_status_name",
        key: "part_status_name",
      },
      {
        title: "Наименование товара",
        dataIndex: "product_name",
        key: "product_name",
      },
      {
        title: "Каталожный номер",
        dataIndex: "product_code",
        key: "product_code",
      },
      {
        title: "Штуки",
        dataIndex: "count",
        key: "count",
      },
      {
        title: "Объем, литров",
        dataIndex: "total_volume",
        key: "total_volume",
      },
      {
        title: "Сумма",
        dataIndex: "total_sum",
        scopedSlots: { customRender: "priceWithNDS" },
        key: "total_sum",
      },
      {
        title: "Стоимость, за литр рублей с НДС",
        dataIndex: "price_stock",
        scopedSlots: { customRender: "priceWithNDS" },
        key: "price_stock",
      },
      {
        title: "Дата создания заказа",
        dataIndex: "created_at",
        sorter : true,
        scopedSlots: { customRender: "date" },
        key: "created_at",
      },
      {
        title: "Наличие",
        key: "availability",
        dataIndex: "availability",
      },
      {
        title: "Комментарий",
        dataIndex: "last_comment",
        width: 160,
      },
    ],
    data: [],
  },
  sum: {
    columns: [
      {
        title: "СУММЫ",
        dataIndex: "lines",
        key: "lines",
      },
      {
        title: "Количество подключенных ЮЛ",
        key: "connectedUlAmount",
        dataIndex: "connectedUlAmount",
      },
      {
        title: "Количество активных ЮЛ (Были заказы за период)",
        dataIndex: "activeUlAmount",
        key: "activeUlAmount",
      },
      {
        title: "Сумма заказов, всего",
        dataIndex: "ordersSum",
        key: "ordersAmount",
      },
      {
        title: "Сумма отказанных позиций, всего",
        dataIndex: "declinedSum",
        key: "declinedSum",
      },
      {
        title: "Сумма подтвержд. позиций, всего",
        dataIndex: "approvedSum",
        key: "approvedSum",
      },
      {
        title: "Сумма позиций без статуса, всего",
        dataIndex: "statuslessSum",
        key: "statuslessSum",
      },
      {
        title: "Коэффициент отказов",
        dataIndex: "declineKef",
        key: "declineKef",
      },
      {
        title: "Коэффициент подтверждений",
        dataIndex: "approveKef",
        key: "approveKef",
      },
      {
        title: "Сумма отгруженных позиций",
        dataIndex: "departedSum",
        key: "departedSum",
      },
      {
        title: "Процент заказов из наличия от общего",
        dataIndex: "nalOrderPercent",
        key: "nalOrderPercent",
      },
      {
        title: "Процент заказов ЦС срочный от общего",
        dataIndex: "srochCSOrderPercent",
        key: "srochCSOrderPercent",
      },
      {
        title: "Процент заказов ЦС складской от общего",
        dataIndex: "skladCSOrderPercent",
        key: "skladCSOrderPercent",
      },
      {
        title: "Процент заказов ЦС под заказ от общего",
        dataIndex: "orderedCSOrderPercent",
        key: "orderedCSOrderPercent",
      },
      {
        title: "Процент заказов прочие склады от общего",
        dataIndex: "otherOrderPercent",
        key: "otherOrderPercent",
      },
    ],
    data: [],
  },
  paginationOptions: {
    pageSize: 10,
    totalCount: 0,
    currentPage: 1,
  },
  sortOptions: {
    sortField: 'created_at',
    sortOrder: 'asc'
  },
}

export const mutations = {
  FILTER: state =>
    (state.filters.current = {
      ...state.filters.temp,
    }),
  CLEAR_FILTERS: state => {
    state.filters.current = {
      ...state.filters.default,
    }
    state.filters.temp = {
      ...state.filters.default,
    }
    state.paginationOptions.currentPage = 1
  },
  UPDATE_FILTER_IS_LINE_SHOW: (state, payload) =>
    (state.filters.temp.isLineShow = payload),
  UPDATE_FILTER_IS_SUM_SHOW: (state, payload) =>
    (state.filters.temp.isSumShow = payload),
  UPDATE_FILTER_CLIENT_ID: (state, payload) =>
    (state.filters.temp.client_id = payload),
  UPDATE_FILTER_COMPANY_ID: (state, payload) =>
    (state.filters.temp.company_id = payload),
  UPDATE_FILTER_STATUS_ID: (state, payload) =>
    (state.filters.temp.status_id = payload),
  UPDATE_FILTER_BRAND: (state, payload) => (state.filters.temp.brand = payload),
  UPDATE_FILTER_PERIOD: (state, payload) =>
    (state.filters.temp.period = payload),

  SET_LINE_DATA: (state, payload) => (state.line.data = payload),
  SET_LINE_TOTAL_COUNT: (state, payload) =>
    (state.paginationOptions.totalCount = payload),
  SET_CURRENT_PAGE: (state, payload) =>
      (state.paginationOptions.currentPage = payload),
  SET_SORT_OPTIONS: (state, payload) =>
      (
          state.sortOptions.sortField = payload.field,
          state.sortOptions.sortOrder = payload.order
      ),
  SET_SUM_DATA: (state, payload) => (state.sum.data = payload),
}

export const actions = {
  fetchLineData: async ({ commit, getters }) => {
    const sortOptions = getters.sortOptions
    const paginationOptions = getters.paginationOptions
    const filters = { ...getters.currentFilter }

    const resp = await service.getOrdersAll({
      sortField : sortOptions.sortField,
      sortOrder : sortOptions.sortOrder,
      page: paginationOptions.currentPage,
      per_page: paginationOptions.pageSize,
      filters,
    })

    commit("SET_LINE_DATA", resp.data.data)
    commit("SET_LINE_TOTAL_COUNT", resp.data.meta.total)
  },
  // fetchSumData: async ({ commit }) => {
  //   const list = await service.getReportDynamicSumData()
  //   commit("SET_SUM_DATA", list)
  // },
}

export const getters = {
  isLineShow: state => state.filters.current.isLineShow === "yes",
  isTempLineShow: state => state.filters.temp.isLineShow === "yes",
  isSumShow: state => state.filters.current.isSumShow === "yes",
  isTempSumShow: state => state.filters.temp.isSumShow === "yes",

  clientId: state => state.filters.temp.client_id,
  companyId: state => state.filters.temp.company_id,
  statusId: state => state.filters.temp.status_id,
  brand: state => state.filters.temp.brand,
  period: state => state.filters.temp.period,
  currentFilter: state => state.filters.current,

  lineColumns: state => state.line.columns,
  lineColumnsForAdmin: state => state.line.columnsForAdmin,
  lineColumnsForOwner: state => state.line.columnsForOwner,
  lineData: state => state.line.data,
  paginationOptions: state => state.paginationOptions,
  sortOptions: state => state.sortOptions,

  sumColumns: state => state.sum.columns,
  sumData: state => state.sum.data,
}
