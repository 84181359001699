import Vue from "vue"
import VueRouter from "vue-router"

const loadView = path => {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */
      `../views/${path}.vue`
    )
}

import store from "../store/store"
import EditPromotion from "@/components/promotions/promotion-edit"
Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: { layout: "login", auth: false },
    component: loadView("Login"),
  },
  {
    path: "/clients",
    name: "Clients",
    meta: { layout: "main", auth: true, title: "Дилеры" },
    component: loadView("Clients"),
  },
  {
    path: "/dealers",
    name: "Dealer",
    meta: { layout: "main", auth: true, title: "Дилеры" },
    component: loadView("Dealers"),
  },
  {
    path: "/dealer/documents",
    name: "DealerDocuments",
    meta: { layout: "main", auth: true, title: "Документы" },
    component: loadView("DealerDocuments"),
  },
  {
    path: "/dealer/documents/:id",
    name: "ClientInfo",
    meta: { layout: "main", auth: true },
    component: loadView("ClientInfo"),
  },
  {
    path: "/buyer/documents",
    name: "BuyerDocuments",
    meta: { layout: "main", auth: true, title: "Документы" },
    component: loadView("BuyerDocuments"),
  },
  {
    path: "/dealer/:id",
    name: "DealerInfo",
    meta: { layout: "main", auth: true, title: "Данные дилера" },
    component: loadView("DealerInfo"),
  },
  {
    path: "/orders",
    name: "Orders",
    meta: { layout: "main", auth: true, title: "Заказы" },
    component: loadView("Orders"),
  },
  {
    path: "/order/:id",
    name: "Order",
    meta: { layout: "main", auth: true },
    component: loadView("Order"),
  },
  {
    path: "/ownerDetailOrder",
    name: "OwnerDetailOrder",
    meta: { layout: "main", auth: true },
    component: loadView("OwnerDetailOrder"),
  },
  {
    path: "/products/:id",
    name: "Product",
    meta: { layout: "main", auth: true },
    component: loadView("ProductView"),
  },
  {
    path: "/localprices",
    name: "LocalPrices",
    meta: { layout: "main", auth: true, title: "Прайс-листы Поставщиков" },
    component: loadView("LocalPrices"),
  },
  {
    path: "/clientprices",
    name: "ClientPrices",
    meta: { layout: "main", auth: true, title: "Клиентские прайс-листы" },
    component: loadView("ClientPrices"),
  },
  {
    path: "/localprices/:id",
    name: "LocalPricesSettings",
    meta: {
      layout: "main",
      auth: true,
      title: "Настройка прайс-листа Поставщика",
    },
    component: loadView("LocalPricesSettings"),
  },
  {
    path: "/promotions",
    name: "Promotions",
    meta: { layout: "main", auth: true, title: "Документы" },
    component: loadView("Promotions"),
  },
  {
    path: "/promotions/edit/:id",
    name: "EditPromotion",
    meta: {
      layout: "main",
      auth: true,
      title: "Редактирование акции",
      //allowedRoles: ["SUPPLIER_ADMIN", "MANUFACTURER_ADMIN"],
      //rolesForbiddenRedirect: "/403",
    },
    component: EditPromotion,
    props: true,
  },
  {
    path: "/promotions/create",
    name: "CreatePromotion",
    meta: {
      layout: "main",
      auth: true,
      title: "Создание акции",
      //allowedRoles: ["SUPPLIER_ADMIN", "MANUFACTURER_ADMIN"],
      //rolesForbiddenRedirect: "/403",
    },
    component: EditPromotion,
    props: { create: true },
  },
  // {
  //   path: "/reports",
  //   name: "Reports",
  //   meta: { layout: "main", auth: true, title: "Отчеты" },
  //   component: loadView("Reports"),
  // },
  {
    path: "/reports",
    name: "Reports",
    meta: { layout: "main", auth: true, title: "Отчеты" },
    component: loadView("ReportsDeal"),
  },
  {
    path: "/dealer/:id/buyers/reports",
    name: "DealerBuyersReports",
    meta: { layout: "main", auth: true },
    component: loadView("DealerBuyersReports"),
  },
  {
    path: "/dealer/:id/buyers/list",
    name: "DealerBuyersList",
    meta: { layout: "main", auth: true },
    component: loadView("DealerBuyers"),
  },
  {
    path: "/reports/period",
    name: "ReportsPeriod",
    meta: { layout: "main", auth: true },
    component: loadView("ReportsPeriod"),
  },
  {
    path: "/reports/dynamic",
    name: "ReportsDynamic",
    meta: { layout: "main", auth: true },
    component: loadView("ReportsDynamic"),
  },
  {
    path: "/reports/period-group",
    name: "ReportsPeriodGroup",
    meta: { layout: "main", auth: true },
    component: loadView("ReportsPeriodGroup"),
  },
  {
    path: "/reports/dynamic-group",
    name: "ReportsDynamicGroup",
    meta: { layout: "main", auth: true },
    component: loadView("ReportsDynamicGroup"),
  },
  {
    path: "/",
    name: "SearchDetails",
    meta: {
      layout: "main",
      auth: true,
      title: "Каталог",
      allowedRoles: [
        "ADMIN",
        "SUPPLIER_ADMIN",
        "MANUFACTURER_ADMIN",
        "MANUFACTURER_ORDER_OPERATOR",
        "DEALER_ADMIN",
        "DEALER_ORDER_OPERATOR",
      ],
      rolesForbiddenRedirect: "/403",
    },
    component: loadView("SearchDetails"),
  },
  {
    path: "/clients/:id",
    name: "ClientInfo",
    meta: { layout: "main", auth: true, title: "Информация о дилере" },
    component: loadView("ClientInfo"),
  },
  {
    path: "/client-info",
    name: "ClientInfoCard",
    meta: { layout: "main", auth: true, title: "Информация дилера" },
    component: loadView("ClientInfo"),
  },
  {
    path: "/basket",
    name: "Basket",
    meta: { layout: "main", auth: true, title: "Корзина" },
    component: loadView("Basket"),
  },
  {
    path: "/basketProvider",
    name: "Basket provider",
    meta: { layout: "main", auth: true, title: "Корзина" },
    component: loadView("BasketProvider"),
  },
  {
    path: "/providers",
    name: "Providers",
    meta: { layout: "main", auth: true, title: "Поставщики" },
    component: loadView("Providers"),
  },
  {
    path: "/provider/:id",
    name: "ProviderInfo",
    meta: { layout: "main", auth: true, title: "Информация о поставщике" },
    component: loadView("ProviderInfo"),
  },
  {
    path: "/markups",
    name: "Markups",
    meta: { layout: "main", auth: true, title: "Группы опта" },
    component: loadView("Markups"),
  },
  {
    path: "/loyalty-levels",
    name: "LoyaltyLevels",
    meta: { layout: "main", auth: true, title: "Градации" },
    component: loadView("LoyaltyLevels"),
  },
  {
    path: "/createclient",
    name: "CreateNewClient",
    meta: { layout: "main", auth: true, title: "Создание дилера" },
    component: loadView("CreateNewClient"),
  },
  {
    path: "/createprovider",
    name: "CreateNewProvider",
    meta: { layout: "main", auth: true, title: "Создание поставщика" },
    component: loadView("CreateNewProvider"),
  },
  {
    path: "/createuser",
    name: "CreateNewUser",
    meta: { layout: "main", auth: true, title: "Создание пользователя" },
    component: loadView("CreateNewUser"),
  },
  {
    path: "/client/createuser",
    name: "CreateNewUser",
    meta: { layout: "main", auth: true, title: "Создание пользователя" },
    component: loadView("CreateNewClientUser"),
  },
  {
    path: "/checkclient",
    name: "CheckClientExist",
    meta: { layout: "main", auth: true, title: "Проверка дилера" },
    component: loadView("CheckClientExist"),
  },
  {
    path: "/createdealer",
    name: "CreateNewDealer",
    meta: { layout: "main", auth: true, title: "Создание Дилера" },
    component: loadView("CreateNewDealer"),
  },
  {
    path: "/pricescs",
    name: "PricesCS",
    meta: { layout: "main", auth: true },
    component: loadView("PricesCS"),
  },
  {
    path: "/pricesdelivery",
    name: "PricesDelivery",
    meta: { layout: "main", auth: true },
    component: loadView("PricesDelivery"),
  },
  {
    path: "/directoriescs",
    name: "DirectoriesCS",
    meta: { layout: "main", auth: true },
    component: loadView("DirectoriesCS"),
  },
  {
    path: "/directories",
    name: "Directories",
    meta: { layout: "main", auth: true, title: "Локальный справочник" },
    component: loadView("Directories"),
  },
  {
    path: "/allowedbrands",
    name: "AllowedBrandsList",
    meta: { layout: "main", auth: true },
    component: loadView("AllowedBrandsList"),
  },
  {
    path: "/orderregistration",
    name: "OrderRegistration",
    meta: { layout: "main", auth: true, title: "Оформление заказа" },
    component: loadView("OrderRegistration"),
  },
  {
    path: "/delivery",
    name: "Delivery",
    meta: { layout: "main", auth: true, title: "Доставка" },
    component: loadView("Delivery"),
  },
  {
    path: "/export",
    name: "Export",
    meta: { layout: "main", auth: true, title: "Экспорт" },
    component: loadView("Export"),
  },
  {
    path: "/prices",
    name: "Prices",
    meta: { layout: "main", auth: true, title: "Наличие" },
    component: loadView("Prices"),
  },
  {
    path: "/pricesInfo",
    name: "Prices info",
    meta: { layout: "main", auth: true },
    component: loadView("PricesInfo"),
  },
  {
    path: "/buyersdealer",
    name: "BuyersDealer",
    meta: { layout: "main", auth: true },
    component: loadView("BuyersDealer"),
  },
  {
    path: "/chats",
    name: "Chats",
    meta: { layout: "main", auth: true, title: "Чаты" },
    component: loadView("Chats"),
  },
  {
    path: "/chat/:id",
    name: "Chat",
    meta: { layout: "main", auth: true },
    component: loadView("Chat"),
  },
  {
    path: "/news",
    name: "News",
    meta: { layout: "main", auth: true, title: "Новости" },
    component: loadView("News"),
  },
  {
    path: "/news/create",
    name: "NewsCreate",
    meta: { layout: "main", auth: true, title: "Добавить новость" },
    component: loadView("NewsCreate"),
  },
  {
    path: "/news/:id",
    name: "NewsView",
    meta: { layout: "main", auth: true, title: "Просмотр новости" },
    component: loadView("NewsView"),
  },
  {
    path: "/news/edit/:id",
    name: "NewsEdit",
    meta: { layout: "main", auth: true, title: "Редактирование новости" },
    component: loadView("NewsEdit"),
  },
  {
    path: "/403",
    name: "403",
    component: {
      template: "<p>Forbidden</p>",
    },
  },
  {
    path: "/404",
    name: "404",
    component: {
      template: "<p>Page Not Found</p>",
    },
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    const isLogged = store.getters["auth/isLoggedIn"]
    if (!isLogged) {
      next("/login")
      return
    }

    // const userRoles = store.getters["auth/rolesNamesList"]
    // const allowedRoles = to.meta.allowedRoles
    // if (
    //   allowedRoles &&
    //   !userRoles.some(userRole => allowedRoles.includes(userRole))
    // ) {
    //   next(to.meta.rolesForbiddenRedirect)
    // }

    next()
  } else {
    next()
  }
})

export default router
