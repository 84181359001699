import ProductService from "@/services/api/v1/ProductService"
import SupplierProductService from "@/services/api/v1/SupplierProductService"
import * as auth from "@/store/modules/auth"
const service = new ProductService()
const supplier_service = new SupplierProductService()

const DEFAULT_FILTERS = () => ({
  code: null,
})

export const state = {
  columns: [
    {
      title: "Поставщик",
      dataIndex: "supplier_name",
      scopedSlots: { customRender: "supplier" },
      selected: !auth.getters.isBuyer,
      width: 190,
    },
    {
      title: "Бренд",
      dataIndex: "brand",
      key: "brand",
      scopedSlots: { customRender: "brand" },
      width: 80,
    },
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      width: 300,
    },
    {
      title: "Каталожный номер",
      dataIndex: "code",
      key: "code",
      scopedSlots: { customRender: "codeKat" },
      width: 120,
    },
    {
      title: "Объем, литров",
      dataIndex: "count",
      key: "count",
      width: 85,
    },
    {
      title: "Стоимость, за литр рублей с НДС",
      dataIndex: "price_stock",
      scopedSlots: { customRender: "priceWithNDS" },
      width: 120,
    },
    {
      title: "Стоимость за тару, рублей с НДС",
      dataIndex: "price",
      scopedSlots: { customRender: "priceWithNDS" },
      width: 120,
    },
    {
      title: "Наличие",
      dataIndex: "availability",
      width: 90,
    },
    {
      title: "В транзите",
      dataIndex: "in_transit",
      width: 100,
    },
    {
      title: "Дата поступления товара В транзите",
      dataIndex: "date_receipt_transit",
      width: 115,
    },
    {
      title: "Дата обновления информации товара по наличию и в транзите",
      dataIndex: "updated_at",
      width: 115,
    },
    // {
    //   title: "Спецификации и допуски",
    //   key: "specifications",
    //   className: "column-action",
    //   dataIndex: "specification",
    // },
    // {
    //   title: "Обновление",
    //   key: "updated_at",
    //   scopedSlots: { customRender: "updated_at" },
    // },
    // {
    //   title: "Ожид. срок",
    //   dataIndex: "delivery_period",
    //   key: "delivery_period",
    //   scopedSlots: { customRender: "coming" },
    //   sorter: (a, b) => a.coming - b.coming,
    // },
    {
      title: "Код ДЦ",
      dataIndex: "client_id",
      scopedSlots: { customRender: "clientCode" },
      selected: false,
      width: 140,
    },
    {
      title: "Кол-во",
      dataIndex: "bcount",
      //fixed: "right",
      scopedSlots: { customRender: "bcount" },
      width: 80,
    },
    {
      title: "Заказ",
      key: "order",
      // fixed: "right",
      // className: "column-action",
      scopedSlots: { customRender: "order" },
      width: 130,
    },
  ],
  details: [],
  districts: [],
  paginationOptions: {
    pageSize: 10,
    totalCount: 0,
    currentPage: 1,
  },
  filters: DEFAULT_FILTERS(),
  previousFilters: DEFAULT_FILTERS(),
}

export const mutations = {
  SET_COLUMN_PROP(state, { index, key, value }) {
    state.columns[index][key] = value
  },
  SET_DETAILS(state, payload) {
    state.details = payload
  },
  CLEAR_DETAILS_AND_FILTERS(state) {
    state.details = []
    state.filters.code = null
    state.paginationOptions.currentPage = 1
  },
  SET_CURRENT_PAGE(state, payload) {
    state.paginationOptions.currentPage = payload
  },
  SET_DETAILS_TOTAL_COUNT(state, payload) {
    state.paginationOptions.totalCount = payload
  },
  SET_CODE_FILTER(state, payload) {
    state.filters.code = payload
  },
  SET_PRODUCT_COUNT(state, { count, index }) {
    state.details[index].count = count
  },
}

export const actions = {
  async fetchDetails({ commit, getters }, savePage = true) {
    const paginationOptions = getters.paginationOptions
    const filters = getters.filters

    let currentPage = paginationOptions.currentPage
    if (!savePage) {
      commit("SET_CURRENT_PAGE", 1)
      currentPage = 1
    }

    const response = await supplier_service.search(
      paginationOptions.pageSize,
      currentPage,
      filters.code
    )

    commit("SET_DETAILS_TOTAL_COUNT", response.data.meta.total)

    const addCountProp = items => {
      return items.map(item => ({
        ...item,
        count: item.count,
      }))
    }

    const formatItems = items => {
      return addCountProp(items)
    }

    commit("SET_DETAILS", formatItems(response.data.data))
  },
  setDetails({ commit }, payload) {
    commit("SET_DETAILS", payload)
  },
  async saveProduct(_, product) {
    await service.save(product)
    return true
  },
}

export const getters = {
  columns(state) {
    return state.columns
  },
  details(state) {
    return state.details.map(detail => ({
      ...detail,
      availableCount: Math.floor(Math.random() * (25 + 1)),
      deliveryPeriod: Math.floor(Math.random() * (10 - 3 + 1) + 3),
    }))
  },
  paginationOptions(state) {
    return state.paginationOptions
  },
  filters(state) {
    return state.filters
  },
}
