/**
 * DEALER_OPERATOR
 */

export default (() => {
  return {
    DEALER_OPERATOR: {
      defaultSelectedId: ["Promotions"],
      items: [
        {
          id: "Promotions",
          name: "Документы",
          icon: "bar-chart",
          path: "/promotions",
        },
        {
          id: "News",
          name: "Новости",
          icon: "exclamation-circle",
          path: "/news",
        },
      ],
    },
  }
})()
