import { DummyApiService } from "@/services"
const service = new DummyApiService()

export const state = {
  columns: [
    {
      title: "Бренд",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Наименование файла",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Дата выгрузки",
      dataIndex: "downloadedAt",
      key: "downloadedAt",
    },
    {
      title: "Способ загрузки",
      dataIndex: "uploadMethod",
      key: "uploadMethod",
    },
  ],
  data: [],
  hidden: false,
}

export const mutations = {
  SET_DATA: (state, payload) => (state.data = payload),
  TOGGLE_TABLE_VISIBILITY: state => (state.hidden = !state.hidden),
}

export const actions = {
  fetchData: async ({ commit }) => {
    const list = await service.getExports()
    commit("SET_DATA", list)
  },
}

export const getters = {
  columns: state => state.columns,
  data: state => state.data,
  isHiddenTable: state => state.hidden,
}
