/**
 * DEALER_ORDER_OPERATOR
 */

export default (() => {
  return {
    DEALER_ORDER_OPERATOR: {
      defaultSelectedId: ["SearchDetails"],
      items: [
        {
          id: "SearchDetails",
          name: "Поиск",
          icon: "monitor",
          path: "/",
        },
        {
          id: "Basket",
          name: "Корзина",
          icon: "shopping-cart",
          path: "/basket",
        },
        {
          id: "Orders",
          name: "Заказы",
          icon: "shopping-cart",
          path: "/orders",
        },
        {
          id: "News",
          name: "Новости",
          icon: "exclamation-circle",
          path: "/news",
        },
        /*{
          id: "BuyerDocuments",
          name: "Документы",
          icon: "file-text",
          path: "/buyer/documents",
        },*/
      ],
    },
  }
})()
