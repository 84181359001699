//import { DummyApiService } from "@/services"
//const service = new DummyApiService()

import DealerService from "@/services/api/v1/DealerService"
const service = new DealerService()

export const state = {
  columns: [
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Сайт",
      dataIndex: "site",
      key: "site",
    },
    {
      title: "Юридическое лицо",
      dataIndex: "legalEntity",
      key: "legalEntity",
    },
    {
      title: "ИНН",
      dataIndex: "inn",
      key: "inn",
    },
    {
      title: "Дата подключения",
      dataIndex: "connectedAt",
      key: "connectedAt",
    },
    {
      title: "Сумма заказов за последний месяц, руб.",
      dataIndex: "ordersSum",
      key: "ordersSum",
    },
    {
      title: "Сумма отгрузки за послед. месяц",
      dataIndex: "departedSum",
      key: "departedSum",
    },
  ],
  data: [],
}

export const mutations = {
  SET_DATA(state, payload) {
    state.data = payload
  },
}

export const actions = {
  fetchData: async ({ commit }) => {
    let current_url = document.location.href
    let splits = current_url.split("/")
    let dealer_id = splits[splits.length - 3]
    const response = await service.getDealerBuyers(dealer_id)
    commit("SET_DATA", response.data.items)
  },
}

export const getters = {
  columns: state => state.columns,
  data: state => state.data,
}
