import { API_PREFIX } from "@/services/api/v1/const"
import axios from "axios"
import { message } from "ant-design-vue"

export default class OrderService {
  getOrders(page, per_page) {
    const params = {
      page: page,
      per_page: per_page,
    }

    return axios.get(`${API_PREFIX}/order`, {
      params,
    })
  }
  getOrder(id) {
    return axios.get(`${API_PREFIX}/order/${id}`)
  }

  getOrderStatuses() {
    return axios.get(`${API_PREFIX}/statuses`)
  }

  getCurrentOrderStatuses(orderId) {
    try {
      return axios.get(`${API_PREFIX}/order/${orderId}/statuses`, {})
    } catch (error) {
      message.error(error.message)
    }
  }

  saveOrderComment(orderUuid, formData) {
    try {
      return axios.post(`${API_PREFIX}/order/${orderUuid}/comment`, formData)
    } catch (error) {
      message.error(error.message)
    }
  }

  getOrderComments(orderUuid) {
    try {
      return axios.get(`${API_PREFIX}/order/${orderUuid}/comments`)
    } catch (error) {
      message.error(error.message)
    }
  }

  //Deprecated
  createOrder(order) {
    try {
      return axios.post(`${API_PREFIX}/order`, order)
    } catch (error) {
      message.error(error.message)
    }
  }
  confirmOrder(order) {
    try {
      return axios.post(`${API_PREFIX}/order/${order.id}/confirm`, order)
    } catch (error) {
      message.error(error.message)
    }
  }
  declineOrder(order, comment) {
    try {
      return axios.post(`${API_PREFIX}/order/${order.id}/decline`, { comment })
    } catch (error) {
      message.error(error.message)
    }
  }

  async verifyBasket(payload) {
    return await axios.post(
      `${API_PREFIX}/order/${payload.orderUuid}/basket-verify`,
      { products: payload.products }
    )
  }
}
