import Vue from "vue"
import App from "@/App"
import router from "@/router"
import store from "@/store/store"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import Antd from "ant-design-vue"
import "ant-design-vue/dist/antd.less"

import "./helpers/axios/interceptors"

Vue.use(Antd)

import Vuelidate from "vuelidate"
Vue.use(Vuelidate)

import VueMask from "v-mask"
Vue.use(VueMask)
Vue.component("ValidationObserver", ValidationObserver)
Vue.component("ValidationProvider", ValidationProvider)
Vue.config.productionTip = false

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    return value
  }
  let formatter = new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
  })
  return formatter.format(value)
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
