export default [
  {
    key: 1,
    sumData: "Сумма заказов",
    byAllOrders: "942194",
    presenceStore: "435780",
    srochCS: "265014",
    skladCS: "135780",
    orderCS: "35800",
    otherDealers: "12850",
    other: "56970",
  },
  {
    key: 2,
    sumData: "Сумма отказанных позиций",
    byAllOrders: "103143",
    presenceStore: "49109",
    srochCS: "32424",
    skladCS: "2790",
    orderCS: "0",
    otherDealers: "7350",
    other: "11470",
  },
  {
    key: 3,
    sumData: "Сумма подтвержд. позиций",
    byAllOrders: "792440",
    presenceStore: "378060",
    srochCS: "212890",
    skladCS: "114690",
    orderCS: "35800",
    otherDealers: "5500",
    other: "45500",
  },
  {
    key: 4,
    sumData: "Сумма позиций без статуса",
    byAllOrders: "46611",
    presenceStore: "8611",
    srochCS: "19700",
    skladCS: "18300",
    orderCS: "0",
    otherDealers: "0",
    other: "0",
  },
  {
    key: 5,
    sumData: "Сумма отгруженных позиций",
    byAllOrders: "1013685",
    presenceStore: "458920",
    srochCS: "230100",
    skladCS: "165810",
    orderCS: "52940",
    otherDealers: "43215",
    other: "62700",
  },
  {
    key: 6,
    sumData: "Коэфф. Отказов",
    byAllOrders: "0,11",
    presenceStore: "0,11",
    srochCS: "0,12",
    skladCS: "0,02",
    orderCS: "0,00",
    otherDealers: "0,57",
    other: "0,20",
  },
  {
    key: 7,
    sumData: "Коэффициент Подтверждения",
    byAllOrders: "0,84",
    presenceStore: "0,87",
    srochCS: "0,80",
    skladCS: "0,84",
    orderCS: "1,00",
    otherDealers: "0,43",
    other: "0,80",
  },
]
