<template>
  <MainLayout v-if="layout === 'main-layout'" id="app" />
  <EmptyLayout v-else />
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import MainLayout from "@/layouts/MainLayout"
import EmptyLayout from "@/layouts/EmptyLayout"

export default {
  components: {
    MainLayout,
    EmptyLayout,
  },
  computed: {
    ...mapGetters({
      isAuth: "auth/isLoggedIn",
      user: "auth/user",
    }),
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout"
    },
  },
  mounted() {
    if (this.isAuth) {
      this.fetchUser()
      const script = document.createElement('script');
      script.id="quiz-widget"
      script.src = "https://litenps.smartconsulting.pro/quizjs.min.js?email="+this.user.email+"&id=2";
      document.head.appendChild(script);
    }
  },
  methods: mapActions({
    fetchUser: "auth/getUser",
  }),
}
</script>
<style lang="scss">
//TODO: Перенести стили в отдельные файлы, настроить импорт шрифтов

//@import "./src/assets/sass/fonts/LandRoverOT4/_stylesheet";

//@font-face {
//  font-family: "Land Rover OT4";
//  src: url("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Light.eot");
//  src: local("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Light"),
//    url("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Light.eot?#iefix")
//      format("embedded-opentype"),
//    url("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Light.woff2")
//      format("woff2"),
//    url("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Light.woff") format("woff"),
//    url("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Light.ttf")
//      format("truetype");
//  font-weight: 300;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: "Land Rover OT4";
//  src: url("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Medium.eot");
//  src: local("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Medium"),
//    url("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Medium.eot?#iefix")
//      format("embedded-opentype"),
//    url("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Medium.woff2")
//      format("woff2"),
//    url("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Medium.woff")
//      format("woff"),
//    url("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Medium.ttf")
//      format("truetype");
//  font-weight: 500;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: "Land Rover OT4";
//  src: url("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Bold.eot");
//  src: local("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Bold"),
//    url("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Bold.eot?#iefix")
//      format("embedded-opentype"),
//    url("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Bold.woff2")
//      format("woff2"),
//    url("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Bold.woff") format("woff"),
//    url("assets/sass/fonts/LandRoverOT4/LandRoverOT4-Bold.ttf")
//      format("truetype");
//  font-weight: bold;
//  font-style: normal;
//}
</style>
