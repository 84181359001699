import axios from "axios"
import { message } from "ant-design-vue"
import { API_PREFIX } from "@/services/api/v1/const"

export default class BasketService {
  async getBasketSet(reserved) {
    let query_params =
      typeof reserved === "undefined" ? {} : { reserved: reserved }
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/basket/set`, { params: query_params })
        .then(response => resolve(response))
        .catch(error => {
          if (error.response.status === 404) {
            resolve({
              data: {
                products: [],
              },
            })
            return
          }

          message.error(error.response.data.message)
          reject(error.response.data.message)
        })
    })
  }
  async getBasketUuid(basketUuid) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/basket/show/${basketUuid}`)
        .then(response => resolve(response))
        .catch(error => {
          if (error.response.status === 404) {
            resolve({
              data: {
                products: [],
              },
            })
            return
          }

          message.error(error.response.data.message)
          reject(error.response.data.message)
        })
    })
  }

  async addProduct(payload) {
    try {
      return await axios.put(`${API_PREFIX}/basket/product/add`, {
        catalog_product_id: payload.catalog_product_id,
        count: payload.count,
        supplier_id: payload.supplier_id,
        client_id: payload.client_id,
      })
    } catch (error) {
      message.error(error.message)
    }
  }

  async updateProduct(payload) {
    try {
      return await axios.put(`${API_PREFIX}/basket/product/update`, {
        basket_product_id: payload.id,
        count: payload.count,
      })
    } catch (error) {
      message.error(error.message)
    }
  }

  async updateProductStatus(payload) {
    try {
      return await axios.put(`${API_PREFIX}/basket/product/update`, payload)
    } catch (error) {
      message.error(error.message)
    }
  }

  async deleteProduct(payload) {
    try {
      return await axios.put(`${API_PREFIX}/basket/product/delete`, {
        basket_product_id: payload,
      })
    } catch (error) {
      message.error(error.message)
    }
  }

  async updateBasket(payload) {
    try {
      return await axios.patch(`${API_PREFIX}/basket/` + payload.uuid, payload)
    } catch (error) {
      message.error(error.message)
    }
  }

  async deleteFromBasket(payload) {
    try {
      await axios.delete(
        `${API_PREFIX}/basket/${payload.uuid}/product/` + payload.productId
      )
    } catch (error) {
      message.error(error.message)
    }
  }

  async verifyBasket(params) {
    if (params === undefined) {
      params = {}
    }
    return await axios.post(`${API_PREFIX}/basket/verify`, params)
  }
}
