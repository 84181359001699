export default [
  {
    key: "1",
    brand: "CHRYSLER",
    code: "68035012AD",
    description: "Диск тормозной передний 350mm Grand Cherokee WK2",
    presence: "2",
    update: "3 часа назад",
    coming: "В наличии",
    storage: "Спецпрайс Темп-Авто",
    price: 12500,
    order: "1",
  },
  {
    key: "2",
    brand: "CHRYSLER",
    code: "68035012AD",
    description: "ТОРМОЗНОЙ ДИСК",
    presence: "4",
    update: "Вчера",
    coming: "9-10 дней",
    storage: "Склад Поставщика другого региона",
    price: 14540,
    order: "1",
  },
  {
    key: "3",
    brand: "CHRYSLER",
    code: "68035012AD",
    description: "Диск тормозной передний",
    presence: "10",
    update: "2 часа назад",
    coming: "7-8 дней",
    storage: "ЦС складской заказ",
    price: 16210,
    order: "1",
  },
  {
    key: "4",
    brand: "CHRYSLER",
    code: "68035012AD",
    description: "Диск тормозной Пд Grand Cherokee",
    presence: "2",
    update: "3 часа назад",
    coming: "В наличии",
    storage: "Наличие Темп-авто",
    price: 16950,
    order: "1",
  },
  {
    key: "5",
    brand: "CHRYSLER",
    code: "68035012AD",
    description: "Диск тормозной передний",
    presence: "10",
    update: "2 часа назад",
    coming: "2-3 дня",
    storage: "ЦС срочный заказ",
    price: 17180,
    order: "1",
  },
  {
    key: "6",
    brand: "CHRYSLER",
    code: "68052386AA",
    description: "Колодки задние Street Performance Ceramic",
    presence: "1",
    update: "Вчера",
    coming: "9-10 дней",
    storage: "Склад Поставщика другого региона",
    price: 6668,
    order: "1",
  },
  {
    key: "7",
    brand: "CHRYSLER",
    code: "68052386AA",
    description: "К-Т ЗАДНИХ ТОРМОЗНЫХ КОЛОДОК WK",
    presence: "1",
    update: "3 дня назад",
    coming: "5-6 дней",
    storage: "Склад Поставщика другого региона",
    price: 7000,
    order: "1",
  },
  {
    key: "8",
    brand: "CHRYSLER",
    code: "68052386AA",
    description: "КОЛОДКИ ТОРМОЗНЫЕ ЗАДНИЕ",
    presence: "13",
    update: "2 часа назад",
    coming: "7-8 дней",
    storage: "ЦС Складской заказ",
    price: 9058,
    order: "1",
  },
  {
    key: "9",
    brand: "CHRYSLER",
    code: "68052386AA",
    description: "Колодки тормозные задние Jeep Grand Cherokee WK2",
    presence: "2",
    update: "3 часа назад",
    coming: "В наличии",
    storage: "Наличие Темп-Авто",
    price: 9300,
    order: "1",
  },
  {
    key: "10",
    brand: "CHRYSLER",
    code: "68052386AA",
    description: "КОЛОДКИ ДИСКОВЫЕ",
    presence: "1",
    update: "3 дня назад",
    coming: "10-11 дней",
    storage: "Склад Поставщика другого региона",
    price: 9950,
    order: "1",
  },
  {
    key: "11",
    brand: "CHRYSLER",
    code: "68052386AA",
    description: "КОЛОДКИ ТОРМОЗНЫЕ ЗАДНИЕ",
    presence: "13",
    update: "2 часа назад",
    coming: "2-3 дня",
    storage: "ЦС срочный заказ",
    price: 10450,
    order: "1",
  },
  {
    key: "12",
    brand: "CHRYSLER",
    code: "68257030AA",
    description: "ФИЛЬТР ВОЗДУШНЫЙ",
    presence: "2",
    update: "Вчера",
    coming: "9-10 дней",
    storage: "Склад Поставщика другого региона",
    price: 1950,
    order: "1",
  },
  {
    key: "13",
    brand: "CHRYSLER",
    code: "68257030AA",
    description: "Воздушный фильтр",
    presence: "1",
    update: "3 часа назад",
    coming: "В наличии",
    storage: "Наличие Темп-Авто",
    price: 2320,
    order: "1",
  },
  {
    key: "14",
    brand: "CHRYSLER",
    code: "68257030AA",
    description: "Воздушный фильтр Jeep Wrangler JL 2.0",
    presence: "1",
    update: "3 дня назад",
    coming: "5-6 дней",
    storage: "Склад Поставщика другого региона",
    price: 2380,
    order: "1",
  },
  {
    key: "15",
    brand: "CHRYSLER",
    code: "68257030AA",
    description: "Воздушный фильтр",
    presence: "45",
    update: "2 часа назад",
    coming: "7-8 дней",
    storage: "ЦС складской заказ",
    price: 2537,
    order: "1",
  },
  {
    key: "16",
    brand: "CHRYSLER",
    code: "68257030AA",
    description: "Воздушный фильтр",
    presence: "45",
    update: "2 часа назад",
    coming: "7-8 дней",
    storage: "ЦС срочный заказ",
    price: 2720,
    order: "1",
  },
  {
    key: "17",
    brand: "CHRYSLER",
    code: "68257030AA",
    description: "Воздушный фильтр Wrangler JL 2.0",
    presence: "1",
    update: "3 дня назад",
    coming: "10-11 дней",
    storage: "Склад Поставщика другого региона",
    price: 3125,
    order: "1",
  },
  {
    key: "18",
    brand: "CHRYSLER",
    code: "68140324AA",
    description: "Фара противотуманная",
    presence: "2",
    update: "2 часа назад",
    coming: "7-8 дней",
    storage: "ЦС складской заказ",
    price: 6654,
    order: "1",
  },
  {
    key: "19",
    brand: "CHRYSLER",
    code: "68140324AA",
    description: "ФАРА ПРОТИВОТУМАННАЯ",
    presence: "1",
    update: "3 дня назад",
    coming: "5-6 дней",
    storage: "Склад Поставщика другого региона",
    price: 7030,
    order: "1",
  },
  {
    key: "19",
    brand: "CHRYSLER",
    code: "68140324AA",
    description: "Фара противотуманная",
    presence: "2",
    update: "2 часа назад",
    coming: "2-3 дня",
    storage: "ЦС срочный заказ",
    price: 7380,
    order: "1",
  },
]
