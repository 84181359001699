import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"
import { message } from "ant-design-vue"

export default class NewsService {
  getNewsList(page, per_page, cabinet) {
    const params = {
      page: page,
      per_page: per_page,
      cabinet,
    }
    return axios.get(
      `${API_PREFIX}/news?${new URLSearchParams(params).toString()}`
    )
  }

  getNews(id) {
    return axios.get(`${API_PREFIX}/news/${id}`)
  }

  createNews(news) {
    return axios.post(`${API_PREFIX}/news`, news).catch(error => {
      message.error(error.response.data.message)
    })
  }
  updateNews(id, news) {
    return axios.post(`${API_PREFIX}/news/${id}`, news).catch(error => {
      message.error(error.response.data.message)
    })
  }
  deleteNews(id) {
    return axios.delete(`${API_PREFIX}/news/${id}`).catch(error => {
      message.error(error.response.data.message)
    })
  }
}
