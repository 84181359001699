export default [
  {
    id: 1,
    key: 1,
    lines: "Автоград Плюс",
    connectedUlAmount: "",
    activeUlAmount: "",
    orderedLinesAmount: "",
    declinedLinesAmount: "",
    approvedLinesAmount: "",
    statuslessLinesAmount: "",
    declineKef: "",
    approveKef: "",
    departedLinesAmount: "",
    nalOrderPercent: "",
    srochCSOrderPercent: "",
    skladCSOrderPercent: "",
    orderedCSOrderPercent: "",
    otherOrderPercent: "",
  },
  {
    id: 2,
    key: 2,
    lines: "янв.20",
    connectedUlAmount: 21,
    activeUlAmount: 14,
    orderedLinesAmount: 175,
    declinedLinesAmount: 19,
    approvedLinesAmount: 156,
    statuslessLinesAmount: 0,
    declineKef: 0.11,
    approveKef: 0.89,
    departedLinesAmount: 152,
    nalOrderPercent: 0.57,
    srochCSOrderPercent: 0.27,
    skladCSOrderPercent: 0.11,
    orderedCSOrderPercent: 0.03,
    otherOrderPercent: 0.02,
  },
  {
    id: 3,
    key: 3,
    lines: "фев.20",
    connectedUlAmount: 23,
    activeUlAmount: 15,
    orderedLinesAmount: 213,
    declinedLinesAmount: 17,
    approvedLinesAmount: 196,
    statuslessLinesAmount: 0,
    declineKef: 0.08,
    approveKef: 0.92,
    departedLinesAmount: 197,
    nalOrderPercent: 0.63,
    srochCSOrderPercent: 0.26,
    skladCSOrderPercent: 0.04,
    orderedCSOrderPercent: 0.02,
    otherOrderPercent: 0.05,
  },
  {
    id: 4,
    key: 4,
    lines: "мар.20",
    connectedUlAmount: 22,
    activeUlAmount: 19,
    orderedLinesAmount: 389,
    declinedLinesAmount: 39,
    approvedLinesAmount: 350,
    statuslessLinesAmount: 0,
    declineKef: 0.1,
    approveKef: 0.9,
    departedLinesAmount: 256,
    nalOrderPercent: 0.72,
    srochCSOrderPercent: 0.19,
    skladCSOrderPercent: 0.01,
    orderedCSOrderPercent: 0.05,
    otherOrderPercent: 0.03,
  },
  {
    id: 5,
    key: 5,
    lines: "апр.20",
    connectedUlAmount: 22,
    activeUlAmount: 13,
    orderedLinesAmount: 178,
    declinedLinesAmount: 24,
    approvedLinesAmount: 154,
    statuslessLinesAmount: 0,
    declineKef: 0.13,
    approveKef: 0.87,
    departedLinesAmount: 213,
    nalOrderPercent: 0.89,
    srochCSOrderPercent: 0.05,
    skladCSOrderPercent: 0.05,
    orderedCSOrderPercent: 0.04,
    otherOrderPercent: 0.01,
  },
  {
    id: 6,
    key: 6,
    lines: "май.20",
    connectedUlAmount: 22,
    activeUlAmount: 10,
    orderedLinesAmount: 52,
    declinedLinesAmount: 6,
    approvedLinesAmount: 46,
    statuslessLinesAmount: 0,
    declineKef: 0.12,
    approveKef: 0.88,
    departedLinesAmount: 70,
    nalOrderPercent: 0.9,
    srochCSOrderPercent: 0.09,
    skladCSOrderPercent: 0.01,
    orderedCSOrderPercent: 0,
    otherOrderPercent: 0,
  },
  {
    id: 7,
    key: 7,
    lines: "июн.20",
    connectedUlAmount: 23,
    activeUlAmount: 10,
    orderedLinesAmount: 89,
    declinedLinesAmount: 9,
    approvedLinesAmount: 80,
    statuslessLinesAmount: 0,
    declineKef: 0.1,
    approveKef: 0.9,
    departedLinesAmount: 68,
    nalOrderPercent: 0.87,
    srochCSOrderPercent: 0.21,
    skladCSOrderPercent: 0.11,
    orderedCSOrderPercent: 0,
    otherOrderPercent: 0.01,
  },
  {
    id: 8,
    key: 8,
    lines: "июл.20",
    connectedUlAmount: 27,
    activeUlAmount: 12,
    orderedLinesAmount: 198,
    declinedLinesAmount: 22,
    approvedLinesAmount: 176,
    statuslessLinesAmount: 0,
    declineKef: 0.11,
    approveKef: 0.89,
    departedLinesAmount: 157,
    nalOrderPercent: 0.72,
    srochCSOrderPercent: 0.19,
    skladCSOrderPercent: 0.03,
    orderedCSOrderPercent: 0.05,
    otherOrderPercent: 0.01,
  },
  {
    id: 9,
    key: 9,
    lines: "авг.20",
    connectedUlAmount: 28,
    activeUlAmount: 13,
    orderedLinesAmount: 269,
    declinedLinesAmount: 17,
    approvedLinesAmount: 252,
    statuslessLinesAmount: 0,
    declineKef: 0.06,
    approveKef: 0.94,
    departedLinesAmount: 239,
    nalOrderPercent: 0.71,
    srochCSOrderPercent: 0.15,
    skladCSOrderPercent: 0.09,
    orderedCSOrderPercent: 0.02,
    otherOrderPercent: 0.03,
  },
  {
    id: 10,
    key: 10,
    lines: "сен.20",
    connectedUlAmount: 29,
    activeUlAmount: 19,
    orderedLinesAmount: 387,
    declinedLinesAmount: 19,
    approvedLinesAmount: 368,
    statuslessLinesAmount: 0,
    declineKef: 0.05,
    approveKef: 0.95,
    departedLinesAmount: 350,
    nalOrderPercent: 0.82,
    srochCSOrderPercent: 0.09,
    skladCSOrderPercent: 0.02,
    orderedCSOrderPercent: 0.03,
    otherOrderPercent: 0.04,
  },
  {
    id: 11,
    key: 11,
    lines: "окт.20",
    connectedUlAmount: 31,
    activeUlAmount: 17,
    orderedLinesAmount: 420,
    declinedLinesAmount: 25,
    approvedLinesAmount: 395,
    statuslessLinesAmount: 0,
    declineKef: 0.06,
    approveKef: 0.94,
    departedLinesAmount: 456,
    nalOrderPercent: 0.73,
    srochCSOrderPercent: 0.17,
    skladCSOrderPercent: 0.03,
    orderedCSOrderPercent: 0.06,
    otherOrderPercent: 0.01,
  },
  {
    id: 12,
    key: 12,
    lines: "ноя.20",
    connectedUlAmount: 31,
    activeUlAmount: 21,
    orderedLinesAmount: 317,
    declinedLinesAmount: 26,
    approvedLinesAmount: 275,
    statuslessLinesAmount: 16,
    declineKef: 0.08,
    approveKef: 0.87,
    departedLinesAmount: 213,
    nalOrderPercent: 0.67,
    srochCSOrderPercent: 0.11,
    skladCSOrderPercent: 0.15,
    orderedCSOrderPercent: 0.03,
    otherOrderPercent: 0.03,
  },
]
