import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"

export default class SupplierService {
  async getSuppliers() {
    return axios.get(`${API_PREFIX}/supplier`)
  }
  async search(name, page = 1, per_page = 10) {
    const options = {}

    if (name) {
      options["name"] = name
    }

    options["page"] = page
    options["per_page"] = per_page

    return axios.get(
      `${API_PREFIX}/supplier?${new URLSearchParams(options).toString()}`
    )
  }

  async createSupplier(data) {
    return axios.post(`${API_PREFIX}/supplier`, data)
  }

  async updateSupplier(data) {
    return axios.put(`${API_PREFIX}/supplier/${data.id}`, data)
  }

  async getSupplier(id) {
    return axios.get(`${API_PREFIX}/supplier/${id}`)
  }

  async deleteSupplier(id) {
    return axios.delete(`${API_PREFIX}/supplier/${id}`)
  }
}
