/**
 * MANUFACTURER_ADMIN
 */

export default (() => {
  return {
    MANUFACTURER_ADMIN: {
      defaultSelectedId: ["Orders"],
      items: [
        {
          id: "Orders",
          name: "Заказы",
          icon: "shopping-cart",
          path: "/orders",
        },
        {
          id: "SearchDetails",
          name: "Каталог",
          icon: "monitor",
          children: {
            items: [
              {
                id: "SearchDetails",
                name: "Каталог",
                path: "/",
              },
              {
                id: "Basket",
                name: "Корзина",
                icon: "shopping-cart",
                path: "/basketProvider",
              },
            ],
          },
        },
        // {
        //   id: "SearchDetails",
        //   name: "Поиск запчастей",
        //   icon: "monitor",
        //   path: "/",
        // },
        {
          id: "Clients",
          name: "Дилеры",
          icon: "team",
          roles: ["Dealer"],
          children: {
            items: [
              {
                id: "Clients",
                name: "Список дилеров",
                path: "/clients",
              },
              {
                id: "CheckClientExist",
                name: "Регистрация нового дилера",
                path: "/checkclient",
              },
            ],
          },
        },
        {
          id: "Provider",
          name: "Поставщики",
          icon: "team",
          roles: ["Dealer"],
          children: {
            items: [
              {
                id: "Providers",
                name: "Список поставщиков",
                path: "/providers",
              },
              {
                id: "CreateNewProvider",
                name: "Регистрация нового поставщика",
                path: "/createprovider",
              },
            ],
          },
        },
        {
          id: "Reports",
          name: "Отчеты",
          icon: "bar-chart",
          path: "/reports",
        },
        {
          id: "4",
          name: "Прайсы",
          icon: "container",
          children: {
            items: [
              {
                id: "LocalPrices",
                name: "Прайс-листы поставщиков",
                path: "/localprices",
              },
              // {
              //   id: "ClientPrices",
              //   name: "Клиентские прайс-листы",
              //   path: "/clientprices",
              // },
              // {
              //   id: "PricesCS",
              //   name: "Прайс-листы ЦС",
              //   path: "/pricescs",
              // },
              // {
              //   id: "Markups",
              //   name: "Группы опта",
              //   path: "/markups",
              // },
              // {
              //   id: "PricesDelivery",
              //   name: "Рассылка прайс-листов",
              //   path: "/pricesdelivery",
              // },
              // {
              //   id: "DirectoriesCS",
              //   name: "Справочники ЦС",
              //   path: "/directoriescs",
              // },
              /*{
                id: "Directories",
                name: "Локальный справочник",
                path: "/directories",
              },*/
              // {
              //   id: "AllowedBrandsList",
              //   name: "Список разрешенных брендов",
              //   path: "/allowedbrands",
              // },
            ],
          },
        },
        // {
        //   id: "Promotions",
        //   name: "Документы",
        //   icon: "percentage",
        //   path: "/promotions",
        // },
        /*{
          id: "DealerDocuments",
          name: "Документы",
          icon: "file-text",
          path: "/dealer/documents",
        },*/
        /*{
          id: "Delivery",
          name: "Доставка",
          icon: "exclamation-circle",
          path: "/delivery",
        },*/
        {
          id: "CreateUser",
          name: "Пользователи системы",
          icon: "team",
          path: "/createuser",
        },
        {
          id: "Chats",
          name: "Чаты",
          icon: "wechat",
          path: "/chats",
        },
        {
          id: "News",
          name: "Новости",
          icon: "exclamation-circle",
          path: "/news",
        },
      ],
    },
  }
})()
