/**
 * DEALER_ADMIN
 */

export default (() => {
  return {
    DEALER_ADMIN: {
      defaultSelectedId: ["SearchDetails"],
      items: [
        {
          id: "Orders",
          name: "Заказы",
          icon: "shopping-cart",
          path: "/orders",
        },
        {
          id: "SearchDetails",
          name: "Каталог",
          icon: "monitor",
          path: "/",
        },
        {
          id: "Basket",
          name: "Корзина",
          icon: "shopping-cart",
          path: "/basket",
        },
        {
          id: "Reports",
          name: "Отчеты",
          icon: "bar-chart",
          path: "/reports",
        },
        // {
        //   id: "Promotions",
        //   name: "Документы",
        //   icon: "bar-chart",
        //   path: "/promotions",
        // },
        {
          id: "Sysusers",
          name: "Пользователи системы",
          icon: "team",
          path: "/client/createuser",
        },
        {
          id: "ClientInfoCard",
          name: "Информация дилера",
          icon: "profile",
          path: "/client-info",
        },
        {
          id: "Chats",
          name: "Чаты",
          icon: "wechat",
          path: "/chats",
        },
        {
          id: "News",
          name: "Новости",
          icon: "exclamation-circle",
          path: "/news",
        },
        /*{
          id: "BuyerDocuments",
          name: "Документы",
          icon: "file-text",
          path: "/buyer/documents",
        },*/
      ],
    },
  }
})()
