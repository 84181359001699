export default [
  {
    key: 1,
    lines: "Автоград Плюс",
    connectedUlAmount: "31",
    activeUlAmount: "21",
    orderedLinesAmount: "317",
    declinedLinesAmount: "26",
    approvedLinesAmount: "275",
    statuslessLinesAmount: "16",
    declineKef: "0,08",
    approveKef: "0,87",
    departedLinesAmount: "213",
    nalOrderPercent: "0,67",
    srochCSOrderPercent: "0,11",
    skladCSOrderPercent: "0,15",
    orderedCSOrderPercent: "0,03",
    otherOrderPercent: "0,03",
    address: "Ленинский проспект, 156, Воронеж, Воронежская обл., 394042",
  },
]
