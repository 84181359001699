export default [
  {
    id: 1,
    key: 1,
    lines: "Автоград Плюс",
    connectedUlAmount: "",
    activeUlAmount: "",
    ordersSum: "",
    declinedSum: "",
    approvedSum: "",
    statuslessSum: "",
    declineKef: "",
    approveKef: "",
    departedSum: "",
    nalOrderPercent: "",
    srochCSOrderPercent: "",
    skladCSOrderPercent: "",
    orderedCSOrderPercent: "",
    otherOrderPercent: "",
  },
  {
    id: 2,
    key: 2,
    lines: "янв.20",
    connectedUlAmount: 21,
    activeUlAmount: 14,
    ordersSum: 569892,
    declinedSum: 43121,
    approvedSum: 526771,
    statuslessSum: 0,
    declineKef: 0.08,
    approveKef: 0.92,
    departedSum: 513680,
    nalOrderPercent: 0.67,
    srochCSOrderPercent: 0.2,
    skladCSOrderPercent: 0.08,
    orderedCSOrderPercent: 0.03,
    otherOrderPercent: 0.02,
  },
  {
    id: 3,
    key: 3,
    lines: "фев.20",
    connectedUlAmount: 23,
    activeUlAmount: 15,
    ordersSum: 724990,
    declinedSum: 63450,
    approvedSum: 661540,
    statuslessSum: 0,
    declineKef: 0.09,
    approveKef: 0.91,
    departedSum: 689045,
    nalOrderPercent: 0.7,
    srochCSOrderPercent: 0.2,
    skladCSOrderPercent: 0.04,
    orderedCSOrderPercent: 0.02,
    otherOrderPercent: 0.04,
  },
  {
    id: 4,
    key: 4,
    lines: "мар.20",
    connectedUlAmount: 22,
    activeUlAmount: 19,
    ordersSum: 869742,
    declinedSum: 78965,
    approvedSum: 790777,
    statuslessSum: 0,
    declineKef: 0.09,
    approveKef: 0.91,
    departedSum: 880520,
    nalOrderPercent: 0.77,
    srochCSOrderPercent: 0.13,
    skladCSOrderPercent: 0.02,
    orderedCSOrderPercent: 0.05,
    otherOrderPercent: 0.03,
  },
  {
    id: 5,
    key: 5,
    lines: "апр.20",
    connectedUlAmount: 22,
    activeUlAmount: 13,
    ordersSum: 458900,
    declinedSum: 23650,
    approvedSum: 435250,
    statuslessSum: 0,
    declineKef: 0.05,
    approveKef: 0.95,
    departedSum: 513600,
    nalOrderPercent: 0.86,
    srochCSOrderPercent: 0.05,
    skladCSOrderPercent: 0.07,
    orderedCSOrderPercent: 0.01,
    otherOrderPercent: 0.01,
  },
  {
    id: 6,
    key: 6,
    lines: "май.20",
    connectedUlAmount: 22,
    activeUlAmount: 10,
    ordersSum: 220412,
    declinedSum: 19700,
    approvedSum: 200712,
    statuslessSum: 0,
    declineKef: 0.09,
    approveKef: 0.91,
    departedSum: 180490,
    nalOrderPercent: 0.83,
    srochCSOrderPercent: 0.09,
    skladCSOrderPercent: 0.08,
    orderedCSOrderPercent: 0,
    otherOrderPercent: 0,
  },
  {
    id: 7,
    key: 7,
    lines: "июн.20",
    connectedUlAmount: 23,
    activeUlAmount: 10,
    ordersSum: 348501,
    declinedSum: 41288,
    approvedSum: 307213,
    statuslessSum: 0,
    declineKef: 0.12,
    approveKef: 0.88,
    departedSum: 335900,
    nalOrderPercent: 0.69,
    srochCSOrderPercent: 0.21,
    skladCSOrderPercent: 0.09,
    orderedCSOrderPercent: 0,
    otherOrderPercent: 0.01,
  },
  {
    id: 8,
    key: 8,
    lines: "июл.20",
    connectedUlAmount: 27,
    activeUlAmount: 12,
    ordersSum: 642180,
    declinedSum: 45960,
    approvedSum: 596220,
    statuslessSum: 0,
    declineKef: 0.07,
    approveKef: 0.93,
    departedSum: 620740,
    nalOrderPercent: 0.67,
    srochCSOrderPercent: 0.19,
    skladCSOrderPercent: 0.08,
    orderedCSOrderPercent: 0.05,
    otherOrderPercent: 0.01,
  },
  {
    id: 9,
    key: 9,
    lines: "авг.20",
    connectedUlAmount: 28,
    activeUlAmount: 13,
    ordersSum: 745120,
    declinedSum: 96231,
    approvedSum: 648889,
    statuslessSum: 0,
    declineKef: 0.13,
    approveKef: 0.87,
    departedSum: 760870,
    nalOrderPercent: 0.74,
    srochCSOrderPercent: 0.11,
    skladCSOrderPercent: 0.1,
    orderedCSOrderPercent: 0.02,
    otherOrderPercent: 0.03,
  },
  {
    id: 10,
    key: 10,
    lines: "сен.20",
    connectedUlAmount: 29,
    activeUlAmount: 19,
    ordersSum: 879450,
    declinedSum: 67845,
    approvedSum: 811605,
    statuslessSum: 0,
    declineKef: 0.08,
    approveKef: 0.92,
    departedSum: 850479,
    nalOrderPercent: 0.73,
    srochCSOrderPercent: 0.12,
    skladCSOrderPercent: 0.1,
    orderedCSOrderPercent: 0.03,
    otherOrderPercent: 0.02,
  },
  {
    id: 11,
    key: 11,
    lines: "окт.20",
    connectedUlAmount: 31,
    activeUlAmount: 17,
    ordersSum: 1145810,
    declinedSum: 94678,
    approvedSum: 1051132,
    statuslessSum: 0,
    declineKef: 0.08,
    approveKef: 0.92,
    departedSum: 1080902,
    nalOrderPercent: 0.62,
    srochCSOrderPercent: 0.23,
    skladCSOrderPercent: 0.09,
    orderedCSOrderPercent: 0.04,
    otherOrderPercent: 0.02,
  },
  {
    id: 12,
    key: 12,
    lines: "ноя.20",
    connectedUlAmount: 31,
    activeUlAmount: 21,
    ordersSum: 942194,
    declinedSum: 103143,
    approvedSum: 792440,
    statuslessSum: 46611,
    declineKef: 0.11,
    approveKef: 0.84,
    departedSum: 1013685,
    nalOrderPercent: 0.46,
    srochCSOrderPercent: 0.28,
    skladCSOrderPercent: 0.14,
    orderedCSOrderPercent: 0.04,
    otherOrderPercent: 0.07,
  },
]
