/**
 * MANUFACTURER_ANALYST
 */

export default (() => {
  return {
    MANUFACTURER_ANALYST: {
      defaultSelectedId: ["Reports"],
      items: [
        {
          id: "Reports",
          name: "Отчеты",
          icon: "bar-chart",
          path: "/reports",
        },
        {
          id: "News",
          name: "Новости",
          icon: "exclamation-circle",
          path: "/news",
        },
      ],
    },
  }
})()
