export default [
  {
    key: 1,
    dealerName: "АВТОГРАД ПРЕСТИЖ",
    orderedLinesAmount: "75",
    orderedLinesPresence: "35",
    orderedLinesSroch: "24",
    orderedLinesSklad: "9",
    orderedLinesOrder: "2",
    orderedLinesOtherDealers: "3",
    orderedLinesOther: "2",
  },
  {
    key: 2,
    dealerName: "БОВИД АВТО",
    orderedLinesAmount: "127",
    orderedLinesPresence: "63",
    orderedLinesSroch: "12",
    orderedLinesSklad: "35",
    orderedLinesOrder: "4",
    orderedLinesOtherDealers: "7",
    orderedLinesOther: "7",
  },
  {
    key: 3,
    dealerName: "АВТОГРАД ПЛЮС",
    orderedLinesAmount: "317",
    orderedLinesPresence: "213",
    orderedLinesSroch: "36",
    orderedLinesSklad: "48",
    orderedLinesOrder: "9",
    orderedLinesOtherDealers: "2",
    orderedLinesOther: "9",
  },
  {
    key: 4,
    dealerName: "ПЛАНЕТА АВТО",
    orderedLinesAmount: "39",
    orderedLinesPresence: "15",
    orderedLinesSroch: "12",
    orderedLinesSklad: "11",
    orderedLinesOrder: "11",
    orderedLinesOtherDealers: "0",
    orderedLinesOther: "0",
  },
  {
    key: 5,
    dealerName: "АРКОНТ",
    orderedLinesAmount: "92",
    orderedLinesPresence: "42",
    orderedLinesSroch: "32",
    orderedLinesSklad: "3",
    orderedLinesOrder: "3",
    orderedLinesOtherDealers: "1",
    orderedLinesOther: "11",
  },
]
