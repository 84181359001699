import * as paymentService from "@/services/api/v1/PaymentService"

export const state = {
  paymentGroups: [],
  paymentGroupsLatest: {},
  paymentGroupsMatrix: [],
}

export const getters = {
  paymentGroups: state => state.paymentGroups,
  paymentGroupsLatest: state => state.paymentGroupsLatest,
  paymentGroupsMatrix: state => state.paymentGroupsMatrix,
}

export const mutations = {
  SET_PAYMENT_GROUPS(state, payload) {
    state.paymentGroups = payload
  },

  SET_PAYMENT_GROUPS_LATEST(state, payload) {
    state.paymentGroupsLatest = payload
  },

  SET_PAYMENT_GROUPS_MATRIX(state, payload) {
    state.paymentGroupsMatrix = payload
  },
}

export const actions = {
  async getPaymentGroups({ commit }) {
    const response = await paymentService.getPaymentGroups()

    if (!response?.data) return

    commit("SET_PAYMENT_GROUPS", response.data.data)
    return response.data.data
  },
  async getPaymentGroupsLatest({ commit }) {
    const response = await paymentService.getPaymentGroupsLatest()

    if (!response?.data) return

    commit("SET_PAYMENT_GROUPS_LATEST", response.data.data)
    return response.data.data
  },
  async getPaymentGroupsMatrix({ commit }) {
    const response = await paymentService.getPaymentGroupsMatrix()

    if (!response?.data) return

    commit("SET_PAYMENT_GROUPS_MATRIX", response.data.data)
    return response.data.data
  },
  async createPaymentGroup(_, data) {
    const response = await paymentService.createPaymentGroup({ data: data })
    if (!response?.data) return
    return response.data.data
  },
  async updatePaymentGroup(_, data) {
    const response = await paymentService.updatePaymentGroup(data)
    if (!response?.data) return
    return response.data.data
  },

  async delPaymentGroup(_, data) {
    const response = await paymentService.delPaymentGroup(data)
    if (!response?.data) return
    return response.data.data
  },

  clearPrices({ commit }) {
    commit("SET_PAYMENT_GROUPS", [])
  },
}
