export default [
  {
    key: 1,
    dealer: "АВТОГРАД ПЛЮС",
    connectedUlAmount: "31",
    activeUlAmount: "21",
    ordersSum: "942194",
    declinedSum: "103143",
    approvedSum: "792440",
    statuslessSum: "46611",
    declineKef: "0,11",
    approveKef: "0,84",
    departedSum: "1013685",
    nalOrderPercent: "0,46",
    srochCSOrderPercent: "0,28",
    skladCSOrderPercent: "0,14",
    orderedCSOrderPercent: "0,04",
    otherOrderPercent: "0,07",
    address: "Ленинский проспект, 156, Воронеж, Воронежская обл., 394042",
  },
]
