import BasketService from "@/services/api/v1/BasketService"
const service = new BasketService()

export const state = {
  basketSet: null,
  basket: null,
  loading: false,
  products: null,
  checkedProducts: null,
  deliveryAddresses: null,
  clientContacts: null,
}
export const getters = {
  basketSet: state => state.basketSet,
  deliveryAddresses: state =>
    state.basketSet
      ? state.basketSet[0].client.delivery_addresses.map(el => {
          return {
            label: el,
            value: el,
          }
        })
      : [],
  clientContacts: state =>
    state.basketSet ? state.basketSet[0].client.contacts : [],
  countOfBaskets: state => state.basketSet && state.basketSet.length,
  count: state =>
    state.products &&
    state.products.reduce((sum, value) => sum + value.count, 0),
  total: state =>
    state.products &&
    state.products.reduce(
      (sum, value) =>
        sum + value.count * value.calculatedPrice * value.availableCount,
      0
    ),
  loading: state => state.loading,
  products: state => state.products,
  totalWeight: state => {
    let weight = 0
    if (state.basket && state.basket.products) {
      for (const product of state.basket.products) {
        if (product.productRef.attributes.weight) {
          weight += parseFloat(product.productRef.attributes.weight.value)
        }
      }
    }
    return weight
  },
  totalDimensions: state => {
    let dim = 0,
      w = 0,
      h = 0,
      l = 0

    if (state.basket && state.basket.products) {
      for (const product of state.basket.products) {
        w = product.productRef.attributes.width
          ? parseFloat(product.productRef.attributes.width.value)
          : 0
        h = product.productRef.attributes.height
          ? parseFloat(product.productRef.attributes.height.value)
          : 0
        l = product.productRef.attributes.length
          ? parseFloat(product.productRef.attributes.length.value)
          : 0
        dim += w * l * h
      }
    }
    return dim
  },
  maxLen: state => {
    let dim = 0,
      w = 0,
      h = 0,
      l = 0,
      tmp = 0

    if (state.basket && state.basket.products) {
      for (const product of state.basket.products) {
        w = product.productRef.attributes.width
          ? product.productRef.attributes.width.value
          : 0
        h = product.productRef.attributes.height
          ? product.productRef.attributes.height.value
          : 0
        l = product.productRef.attributes.length
          ? product.productRef.attributes.length.value
          : 0
        tmp = Math.max(w, l, h)
        if (dim < tmp) dim = tmp
      }
    }
    return dim
  },
  checkedProducts: state => state.checkedProducts,
}

export const mutations = {
  SET_BASKET_SET(state, payload) {
    state.basketSet = payload
  },
  SET_BASKET(state, payload) {
    state.basket = payload
  },
  CLEAR_BASKET(state) {
    state.basket = null
    state.products = null
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_PRODUCT_FIELD(state, { index, key, value }) {
    state.products[index][key] = value
  },
  SET_PRODUCTS(state, payload) {
    state.products = payload
  },
  SET_CHECKED_PRODUCTS(state, payload) {
    state.checkedProducts = payload
  },
}

export const actions = {
  async getBasketSet({ commit }) {
    const response = await service.getBasketSet()
    commit("SET_BASKET_SET", response.data)
  },

  async getProducts({ commit }, { reserved }) {
    commit("SET_LOADING", true)
    const basketSet = await service.getBasketSet(reserved)
    commit("SET_BASKET_SET", basketSet.data)
    let products = []
    if (basketSet) {
      for (const basket of basketSet.data) {
        for (const product of basket.products) {
          const p = Object.assign({}, product, {
            check: false,
            supplier_name: basket.supplier_name,
            dealer_code: basket.client.code,
          })
          products.push(p)
        }
      }
    }
    commit("SET_PRODUCTS", products)
    commit("SET_LOADING", false)
  },

  async addProduct({ commit }, { product, count, client_id }) {
    console.log("pp", product, count)
    commit("SET_LOADING", true)
    const response = await service.addProduct({ ...product, count, client_id })
    commit("SET_PRODUCTS", response.data.data.products)
    commit("SET_LOADING", false)
    return true
  },
  async updateProduct(_, payload) {
    console.log("pp", payload)
    await service.updateProduct(payload)
  },
  async updateProductStatus(_, payload) {
    await service.updateProductStatus(payload)
  },
  async updateBasket(_, payload) {
    await service.updateBasket(payload)
  },
  async deleteFromBasket(_, payload) {
    await service.deleteProduct(payload)
    return _.dispatch("getProducts", {})
  },
  setCheckedProducts({ getters, commit }) {
    const checkedProducts = []
    for (const i in getters.products) {
      if (getters.products[i].check) {
        checkedProducts.push(getters.products[i])
      }
    }
    commit("SET_CHECKED_PRODUCTS", checkedProducts)
  },
  async verifyBasket(_, params) {
    if (params === undefined) {
      params = {}
    }
    return await service.verifyBasket(params)
  },
}
