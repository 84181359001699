export default [
  {
    id: 1,
    key: 1,
    brandId: 1,
    article: "0241540",
    actualArticle: "LR004933",
    availability: 17,
    minSeatsAmount: 1,
    name: "ШАРОВОЙ ШАРНИР",
    retailPrice: 24.38,
    stockOrder: 17.47,
    expressOrder: 19.97,
  },
  {
    id: 2,
    key: 2,
    brandId: 1,
    article: "0494095",
    actualArticle: "ERC5655",
    availability: 120,
    minSeatsAmount: 10,
    name: "SUPERSEDED PART",
    retailPrice: 425.4,
    stockOrder: 268.32,
    expressOrder: 309.5,
  },
  {
    id: 3,
    key: 3,
    brandId: 1,
    article: "1003736",
    actualArticle: "",
    availability: 6,
    minSeatsAmount: 1,
    name: "ДИСТАН",
    retailPrice: 57.63,
    stockOrder: 38.59,
    expressOrder: 44.93,
  },
  {
    id: 4,
    key: 4,
    brandId: 1,
    article: "1005519",
    actualArticle: "FB108161",
    availability: 150,
    minSeatsAmount: 10,
    name: "ШПИЛЬКА",
    retailPrice: 104.5,
    stockOrder: 71.14,
    expressOrder: 82.37,
  },
  {
    id: 5,
    key: 5,
    brandId: 1,
    article: "1005532",
    actualArticle: "",
    availability: 4,
    minSeatsAmount: 1,
    name: "ШПИЛЬКА",
    retailPrice: 466.64,
    stockOrder: 316.99,
    expressOrder: 365.66,
  },
  {
    id: 6,
    key: 6,
    brandId: 1,
    article: "1005593",
    actualArticle: "1013938",
    availability: 25,
    minSeatsAmount: 5,
    name: "ПРОКЛАДКА",
    retailPrice: 1016.09,
    stockOrder: 691.39,
    expressOrder: 794.98,
  },
  {
    id: 7,
    key: 7,
    brandId: 1,
    article: "1007604",
    actualArticle: "4147433",
    availability: 30,
    minSeatsAmount: 10,
    name: "БОЛТ С ШЕСТИГРАННОЙ ГОЛОВКОЙ",
    retailPrice: 32.45,
    stockOrder: 22.46,
    expressOrder: 24.96,
  },
  {
    id: 8,
    key: 8,
    brandId: 1,
    article: "1013938",
    actualArticle: "",
    availability: 70,
    minSeatsAmount: 5,
    name: "ПРОБКА СЛИВА МАСЛА",
    retailPrice: 549.26,
    stockOrder: 400.61,
    expressOrder: 460.51,
  },
  {
    id: 9,
    key: 9,
    brandId: 1,
    article: "1018219",
    actualArticle: "",
    availability: 150,
    minSeatsAmount: 5,
    name: "БОЛТ",
    retailPrice: 6.97,
    stockOrder: 4.99,
    expressOrder: 4.99,
  },
  {
    id: 10,
    key: 10,
    brandId: 1,
    article: "10211",
    actualArticle: "",
    availability: 450,
    minSeatsAmount: 10,
    name: "ЛАМПА",
    retailPrice: 275.57,
    stockOrder: 160.99,
    expressOrder: 184.7,
  },
  {
    id: 11,
    key: 11,
    brandId: 1,
    article: "102162",
    actualArticle: "",
    availability: 350,
    minSeatsAmount: 10,
    name: "ШЕСТИГРАННАЯ ГАЙКА",
    retailPrice: 120.88,
    stockOrder: 82.37,
    expressOrder: 94.85,
  },
  {
    id: 12,
    key: 12,
    brandId: 1,
    article: "1204446",
    actualArticle: "",
    availability: 3,
    minSeatsAmount: 1,
    name: "ПЕРЕХОДНИК",
    retailPrice: 215.35,
    stockOrder: 136.03,
    expressOrder: 156,
  },
  {
    id: 13,
    key: 13,
    brandId: 1,
    article: "1025098",
    actualArticle: "",
    availability: 2,
    minSeatsAmount: 1,
    name: "ГАЗОРАСПРЕДЕЛИТЕЛЬНАЯ СИСТЕМА",
    retailPrice: 2293.36,
    stockOrder: 1560,
    expressOrder: 1794.62,
  },
  {
    id: 14,
    key: 14,
    brandId: 1,
    article: "1025109",
    actualArticle: "",
    availability: 1,
    minSeatsAmount: 1,
    name: "УПОРНАЯ ПЛАСТИНА",
    retailPrice: 496.11,
    stockOrder: 361.92,
    expressOrder: 415.58,
  },
  {
    id: 15,
    key: 15,
    brandId: 1,
    article: "1025228",
    actualArticle: "",
    availability: 4,
    minSeatsAmount: 1,
    name: "ПРОКЛАДКА МАСЛЯНОГО КАРТЕРА",
    retailPrice: 1069.32,
    stockOrder: 780,
    expressOrder: 896.06,
  },
  {
    id: 16,
    key: 16,
    brandId: 1,
    article: "1025293",
    actualArticle: "",
    availability: 1,
    minSeatsAmount: 1,
    name: "ЦЕПЬ",
    retailPrice: 985.38,
    stockOrder: 670.18,
    expressOrder: 771.26,
  },
  {
    id: 17,
    key: 17,
    brandId: 1,
    article: "1025334",
    actualArticle: "",
    availability: 6,
    minSeatsAmount: 1,
    name: "ГЕГУЛИРОВАЧНЫЙ ВИНТ",
    retailPrice: 161.95,
    stockOrder: 109.82,
    expressOrder: 127.3,
  },

  {
    id: 18,
    key: 18,
    brandId: 2,
    article: "AJ 810069",
    actualArticle: "AJ 810525",
    availability: 1,
    minSeatsAmount: 1,
    name: "SUMP-OIL",
    retailPrice: 26776.41,
    stockOrder: 19518.72,
    expressOrder: 22446.53,
  },
  {
    id: 19,
    key: 19,
    brandId: 2,
    article: "AJ 810074",
    actualArticle: "",
    availability: 1,
    minSeatsAmount: 1,
    name: "КОЛЛЕКТОР-ВПУСКНОЙ",
    retailPrice: 70111.28,
    stockOrder: 44294.02,
    expressOrder: 50938.37,
  },
  {
    id: 20,
    key: 20,
    brandId: 2,
    article: "AJ 810106",
    actualArticle: "",
    availability: 1,
    minSeatsAmount: 1,
    name: "КЛАПАН-ВЫПУСКНОЙ",
    retailPrice: 1575.46,
    stockOrder: 1072.03,
    expressOrder: 1233.02,
  },
  {
    id: 21,
    key: 21,
    brandId: 2,
    article: "AJ 810203",
    actualArticle: "",
    availability: 1,
    minSeatsAmount: 1,
    name: "ТРУБКА-РЕЦИРК.ОТРАБ.ГАЗОВ",
    retailPrice: 8452.14,
    stockOrder: 5340.19,
    expressOrder: 6141.41,
  },
  {
    id: 22,
    key: 22,
    brandId: 2,
    article: "AJ 810207",
    actualArticle: "",
    availability: 17,
    minSeatsAmount: 1,
    name: "ШПИЛЬКА",
    retailPrice: 156.44,
    stockOrder: 106.08,
    expressOrder: 122.3,
  },
  {
    id: 23,
    key: 23,
    brandId: 2,
    article: "AJ 810214",
    actualArticle: "AJ 811793",
    availability: 1,
    minSeatsAmount: 1,
    name: "ТРУБКА-ОТВОДЯЩАЯ",
    retailPrice: 9314.15,
    stockOrder: 5901.79,
    expressOrder: 6768.62,
  },
  {
    id: 24,
    key: 24,
    brandId: 2,
    article: "AJ 810221",
    actualArticle: "",
    availability: 120,
    minSeatsAmount: 1,
    name: "БОЛТ",
    retailPrice: 85.78,
    stockOrder: 58.66,
    expressOrder: 67.39,
  },
  {
    id: 25,
    key: 25,
    brandId: 2,
    article: "AJ 810263",
    actualArticle: "",
    availability: 1,
    minSeatsAmount: 1,
    name: "ТРУБКА-МАСЛ.ГОРЛОВИНА",
    retailPrice: 1522.55,
    stockOrder: 962.23,
    expressOrder: 1105.73,
  },
  {
    id: 26,
    key: 26,
    brandId: 2,
    article: "AJ 810271",
    actualArticle: "",
    availability: 1,
    minSeatsAmount: 1,
    name: "ТРУБКА-ПАРОВАЯ",
    retailPrice: 8848.97,
    stockOrder: 6450.91,
    expressOrder: 7148.11,
  },
  {
    id: 27,
    key: 27,
    brandId: 2,
    article: "AJ 810281",
    actualArticle: "",
    availability: 35,
    minSeatsAmount: 1,
    name: "PLUG",
    retailPrice: 284.07,
    stockOrder: 193.44,
    expressOrder: 222.14,
  },
  {
    id: 28,
    key: 28,
    brandId: 2,
    article: "AJ 810289",
    actualArticle: "AJ 810715",
    availability: 1,
    minSeatsAmount: 1,
    name: "HEAD-OIL LIFTER",
    retailPrice: 17437.36,
    stockOrder: 12710.88,
    expressOrder: 14617.82,
  },
  {
    id: 28,
    key: 28,
    brandId: 2,
    article: "AJ 810290",
    actualArticle: "",
    availability: 41,
    minSeatsAmount: 1,
    name: "ВИНТ",
    retailPrice: 312.78,
    stockOrder: 213.41,
    expressOrder: 244.61,
  },
  {
    id: 29,
    key: 29,
    brandId: 2,
    article: "AJ 810343",
    actualArticle: "AJR 811259",
    availability: 2,
    minSeatsAmount: 1,
    name: "PIPE-OUTLET",
    retailPrice: 18835.87,
    stockOrder: 11899.68,
    expressOrder: 13685.57,
  },
  {
    id: 30,
    key: 30,
    brandId: 2,
    article: "AJ 810399",
    actualArticle: "",
    availability: 3,
    minSeatsAmount: 1,
    name: "КРОНШТЕЙН-УГЛОВОЙ",
    retailPrice: 5695.37,
    stockOrder: 3597.98,
    expressOrder: 4138.37,
  },
  {
    id: 31,
    key: 31,
    brandId: 2,
    article: "AJ 810400",
    actualArticle: "AJR 812160",
    availability: 3,
    minSeatsAmount: 1,
    name: "ROD-CONNECTING",
    retailPrice: 12429.76,
    stockOrder: 9060.48,
    expressOrder: 10419.55,
  },
]
