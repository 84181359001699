import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"

export default class SupplierProductService {
  async auth(login, password) {
    return axios.post(`${API_PREFIX}/login`, {
      login,
      password,
    })
  }

  async search(per_page, page, code) {
    const options = {
      per_page,
      page,
    }

    if (code) {
      options.code = code
    }

    return axios.get(
      `${API_PREFIX}/supplier/product?${new URLSearchParams(
        options
      ).toString()}`
    )
  }
}
