import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"
import { message } from "ant-design-vue"

export default class ChatService {
  getChats(page, per_page) {
    const params = {
      page: page,
      per_page: per_page,
    }
    return axios.get(
      `${API_PREFIX}/chat?${new URLSearchParams(params).toString()}`
    )
  }

  getChat(id) {
    return axios.get(`${API_PREFIX}/chat/${id}`)
  }

  createChat(chat) {
    return axios.post(`${API_PREFIX}/chat`, chat).catch(error => {
      message.error(error.response.data.message)
    })
  }

  createMessage(id, chatMessage) {
    return axios
      .post(`${API_PREFIX}/chat/${id}/message`, chatMessage)
      .catch(error => {
        message.error(error.response.data)
      })
  }
}
