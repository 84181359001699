import { DummyApiService } from "@/services"
const service = new DummyApiService()

export const state = {
  list: [],
  columns: [
    {
      title: "Бренд",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Название файла",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Дата загрузки",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
    },
    {
      title: "",
      scopedSlots: { customRender: "download" },
    },
  ],
  emptyColumns: [
    {
      title: "",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
    },
    {
      title: "",
      scopedSlots: { customRender: "download" },
    },
  ],
  uploadForm: {
    currentBrand: 1,
    file: null,
  },
}

export const mutations = {
  SET_CS_DIRECTORIES: (state, payload) => (state.list = payload),
  CHOOSE_BRAND: (state, payload) => (state.uploadForm.currentBrand = payload),
}

export const actions = {
  fetchCsDirectories: async ({ commit }) => {
    const list = await service.getCsDirectories()
    commit("SET_CS_DIRECTORIES", list)
  },
}

export const getters = {
  list: state => state.list,
  columns: state => state.columns,
  emptyColumns: state => state.emptyColumns,
  currentBrand: state => state.uploadForm.currentBrand,
}
